import queryString, { ParsedQuery } from 'query-string';

const stringify = (obj: object): string => Object.keys(obj).length === 0 ? '' : `?${queryString.stringify(obj)}`;

const get = (): ParsedQuery => queryString.parse(location.search)

const remove = (key: string): string => {
    const queryStringWithoutKey = (({ [key]: undefined, ...rest }) => rest)(get());
    return stringify(queryStringWithoutKey);
}

const removeAndUpdateUrl = (key: string): string => {
    const newQueryString = remove(key);

    window.history.replaceState(
        null,
        '',
        `${window.location.pathname}${newQueryString}`
    );

    return newQueryString;
}

export default {
    get,
    remove,
    removeAndUpdateUrl
}