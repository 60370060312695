import { computed, observable, action } from "mobx";
import {
    BackendPropertiesToUpdate,
    AppKeySideBar,
    SaveButtonStateEnum,
    SaveButtonStateType,
    AppKeyDetails
} from "./types";
import PropertyModel from "../models/PropertyModel";
import mapBackendDetailsToAppKeyDetails from './mapBackendDetailsToAppKeyDetails';
import { SnackbarStore } from '../SnackbarStore';
import { ConfirmationPopupMessageStore } from '../ConfirmationPopupMessageStore';
import { UserStore } from '../UserStore';
import { BackofficeClientStore } from '../BackofficeClientStore';

class AppDataStore {
    @observable public initialized = false;
    @observable public appKeyDetails?: AppKeyDetails;
    @observable public selectedAppKey: string = "";
    @observable.shallow private appKeys: AppKeySideBar[] = [];
    @observable public isSavingState = false;
    backofficeClientStore: BackofficeClientStore;
    snackbarStore: SnackbarStore;
    confirmationPopupMessageStore: ConfirmationPopupMessageStore;
    userStore: UserStore;

    constructor(backofficeClientStore: BackofficeClientStore, snackbarStore: SnackbarStore, confirmationPopupMessageStore: ConfirmationPopupMessageStore, userStore: UserStore) {
        this.backofficeClientStore = backofficeClientStore;
        this.snackbarStore = snackbarStore;
        this.confirmationPopupMessageStore = confirmationPopupMessageStore;
        this.userStore = userStore;
    }

    private buildChangedPropertyObject = (): BackendPropertiesToUpdate => {
        if (!this.appKeyDetails) return {};

        let changedPropertiesObj = {};

        for (const property of this.changedProperties) {
            const changedAppKeyItem = this.appKeyDetails[property] as PropertyModel<any>;
            if (!changedAppKeyItem.backendKey) continue;

            changedPropertiesObj[changedAppKeyItem.backendKey] = changedAppKeyItem.getUpdatePath();
        }

        return changedPropertiesObj;
    };

    private updateLocalState = () => {
        if (!this.appKeyDetails) return;

        for (const property of this.changedProperties) {
            const changedAppKeyItem = this.appKeyDetails[property] as PropertyModel<any>;
            changedAppKeyItem.purgeValue();
        }
    };
    @action
    selectAppKey = async (appKey: string) => {
        const canExit = await this.canExitEditForm();
        if (!canExit) return;

        await this.innerSelectAppKey(appKey);
    };

    @action
    tryResetAppKey = async (): Promise<boolean> => {
        if (!await this.canExitEditForm()) return false;

        this.selectedAppKey = '';
        this.appKeyDetails = undefined;
        return true;
    };

    @action
    private canExitEditForm = async (): Promise<boolean> => {
        return this.changedProperties.length === 0 || await this.confirmationPopupMessageStore.showPopup();
    }

    @action
    private innerSelectAppKey = async (appKey: string) => {
        if (!this.appKeys.some(item => item.appKey === appKey)) return;

        this.selectedAppKey = appKey;
        this.appKeyDetails = undefined;
        const backendDataEntity = await this.backofficeClientStore.fetchAppKeyDetails(
            appKey
        )

        if (backendDataEntity?.appKey !== this.selectedAppKey) return;

        this.appKeyDetails = mapBackendDetailsToAppKeyDetails(backendDataEntity);
    }

    @action
    saveData = async () => {
        if (this.isSavingState) return;

        this.isSavingState = true;
        const changedPropertiesObj = this.buildChangedPropertyObject();

        try {
            await this.backofficeClientStore.updateAppKeyDetails(
                this.selectedAppKey,
                changedPropertiesObj
            );
        } catch {
            this.isSavingState = false;
            this.snackbarStore.showMessage('Changes were reverted since some properties could not be saved.', 'Try again', this.saveData);
            return;
        }

        this.updateLocalState();

        this.isSavingState = false;
    };

    @action
    initialize = async (autoSelectAppKey: boolean = true, forceRefresh: boolean = false) => {
        if (this.initialized) return;

        const userDetails = forceRefresh ? await this.userStore.getAndRefreshUserDetails() :
            (this.userStore.userDetails || await this.userStore.getAndRefreshUserDetails());

        if (!userDetails) {
            throw 'Could not initialize AppDataStore: User details not found';
        }

        this.appKeys = userDetails.appKeys.sort((a, b) => a.name > b.name ? 1 : (b.name > a.name ? -1 : 0)) || [];

        if (autoSelectAppKey && this.appKeys.length > 0) {
            this.appKeys.length > 0 && this.innerSelectAppKey(this.appKeys[0]?.appKey);
        }

        this.initialized = true;
    };

    @action
    refreshData = async (selectedAppKey?: string) => {
        this.initialized = false;
        this.appKeys = [];
        this.selectedAppKey = '';
        this.appKeyDetails = undefined;

        if (!!selectedAppKey) {
            await this.initialize(false, true);
            this.innerSelectAppKey(selectedAppKey);
            return;
        }

        await this.initialize();
    }

    @computed
    get sideBarAppkeys(): AppKeySideBar[] {
        if (!this.appKeys) return [];

        return (
            this.appKeys.map<AppKeySideBar>(item => ({
                name: item.name || "-",
                appKey: item.appKey || "-",
                hostType: item.hostType,
                environment: item.environment
            }))
        );
    }

    @computed
    get changedProperties(): string[] {
        return Object.keys(this.appKeyDetails || {}).filter(key => this.appKeyDetails![key].state === 'changed').map(key => key);
    }

    @computed
    get invalidProperties(): string[] {
        return Object.keys(this.appKeyDetails || {}).filter(key => this.appKeyDetails![key].state === 'invalid').map(key => key);
    }

    @computed
    get saveButtonState(): SaveButtonStateType {
        if (this.isSavingState) return SaveButtonStateEnum.SAVING;

        if (this.invalidProperties.length) return SaveButtonStateEnum.DISABLE;

        if (this.changedProperties.length) return SaveButtonStateEnum.ACTIVE;

        return SaveButtonStateEnum.DISABLE;
    }

    getUserGroups = async () => {
        return await this.backofficeClientStore.getUserGroups();
    }
}

export default AppDataStore;
