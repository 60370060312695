import { Colors, Sizes } from '../theme';

export const colors: Colors = {
    purple: '#8223d2',
    lightgreen: '#d2fa46',
    lightgray: '#e6e6eb',
    lightgrayEditArea: '#F0F0F5',
    gray: '#6c747b',
    appBlack: '#333f48',
    white: '#ffffff',
    warning: '#FF847A',
    borderGray: '#D5D6DA'
};

export const sizes: Sizes = {
    headerHeight: '80px'
};
