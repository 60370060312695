import React, { FunctionComponent, useMemo } from 'react';
import { TweekProvider as Provider } from 'react-tweek';
import { createTweekClient } from 'tweek-client';
import { TweekRepository } from 'tweek-local-cache';

const TWEEK_URL = 'https://tweek.mysoluto.com';

const TweekProvider: FunctionComponent = ({ children }) => {
    const tweekClient = useMemo(() => createTweekClient({
        baseServiceUrl: TWEEK_URL
    }), [])

    const tweekRepo = useMemo(() => new TweekRepository({
        client: tweekClient
    }), [tweekClient])

    return (
        <Provider value={tweekRepo}>
            {children}
        </Provider>
    )
}

export default TweekProvider;