type BreakPoints<T = string> = {
    desktop?: T;
    mobile?: T;
};

export const BREAK_POINTS_DIMENSIONS_VALUES_IN_PX: BreakPoints<number> = {
    desktop: 1024,
    mobile: 900
};

const BREAK_POINTS: BreakPoints = Object.entries(BREAK_POINTS_DIMENSIONS_VALUES_IN_PX).reduce((prev, [key, value]) => {
    prev[key] = `(min-width: ${value}px)`;
    return prev;
}, {});

export default BREAK_POINTS;
