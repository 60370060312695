import React, { FunctionComponent, useState, useCallback, useEffect } from 'react';
import { PropertyTitle, GridColumnOne, GridColumnTwo, PropertySubtitle, ValueText, GridColumnThree, GridColumn, PropertyGrid } from './common-styles';
import useAppDataStore from '../../hooks/useAppDataStore';

import { GridSectionSeperator, BlankOverlay } from '../BasicElements';
import { Checkbox, UrlInput, Lock, TextInput } from '../AppInputs';
import { observer } from 'mobx-react-lite';
import { styled } from '../../common-styles';
import InputErrorMessageColumn from '../InputErrorMessageColumn';
import useSnackbarStore from '../../hooks/useSnackbarStore';
import { useAnalytics } from 'react-shisell';

import useAnalyticsReportProxy from '../../hooks/useAnalyticsReportProxy';

const ANONYMOUS_LOGIN_ENABLED_TEXT = '*Anonymous Login Enabled.';

const LockContainer = styled.div`
    margin-left: auto;
    position: relative;
    z-index: 30;
`;

const AnonymousLoginText = styled.span`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.gray};
`;

const SectionKeysContainer = styled(PropertyGrid)`
    grid-column-start: 1;
    grid-column-end: 4;
    position: relative;
`;

const AuthenticationSection: FunctionComponent = () => {
    const { appKeyDetails } = useAppDataStore();
    const { smsLoginEnabled, tokenUniqueClaim, audience, tokenIssuer, anonymousLoginEnabled, publicKeyProviderUrl, appKey, displayName } = appKeyDetails!;
    const { showMessage: showSnackbarMessage } = useSnackbarStore();
    const analytics = useAnalytics();
    const analyticsReportOpenUrlProxy = useAnalyticsReportProxy('OpenURL');
    const analyticsReportResetInputProxy = useAnalyticsReportProxy('RevertChanges');
    const [isJwtSectionLocked, setIsJwtSectionLocked] = useState(true);

    const toggleJwtSectionLock = useCallback(() => {
        if (smsLoginEnabled.value) {
            showSnackbarMessage('Other authentication flows are enabled, disable them to unlock.', '', () => { });
            return;
        }

        analytics.dispatcher
            .withExtra('ActionId', isJwtSectionLocked ? 'Unlock' : 'Lock')
            .withExtra('AppKey', appKey?.value)
            .withExtra('FriendlyName', displayName?.value)
            .dispatch('Click');

        setIsJwtSectionLocked(!isJwtSectionLocked);
    }, [isJwtSectionLocked, setIsJwtSectionLocked, smsLoginEnabled, appKey, displayName, analytics]);

    useEffect(() => {
        if (!smsLoginEnabled.value) return;
        setIsJwtSectionLocked(true);
    }, [smsLoginEnabled.value])

    if (!appKeyDetails) return null;

    return (
        <>
            <SectionKeysContainer>
                {isJwtSectionLocked && <BlankOverlay />}
                <GridColumnOne>
                    <PropertyTitle>Authentication</PropertyTitle>
                </GridColumnOne>
                {/* ------JWT Token------ */}
                <GridColumnTwo grayOut={isJwtSectionLocked}>
                    <PropertySubtitle>Type</PropertySubtitle>
                </GridColumnTwo>
                <GridColumnThree>
                    <ValueText grayOut={isJwtSectionLocked}>JWT Token</ValueText>
                    <LockContainer>
                        <Lock isOpen={!isJwtSectionLocked} onClick={toggleJwtSectionLock} />
                    </LockContainer>
                </GridColumnThree>
                {/* ------TokenUniqueClaim------ */}
                <GridColumnTwo grayOut={isJwtSectionLocked}>
                    <PropertySubtitle>TokenUniqueClaim</PropertySubtitle>
                </GridColumnTwo>
                <GridColumnThree grayOut={isJwtSectionLocked}>
                    <TextInput
                        value={tokenUniqueClaim.value}
                        state={tokenUniqueClaim.state}
                        onChange={tokenUniqueClaim.setValue}
                        onReset={analyticsReportResetInputProxy(tokenUniqueClaim.reset)}
                    />
                </GridColumnThree>
                <InputErrorMessageColumn show={tokenUniqueClaim.state === 'invalid'} columnStart={3} columnEnd={4} />
                {/* ------Audience------ */}
                <GridColumnTwo grayOut={isJwtSectionLocked}>
                    <PropertySubtitle>Audience</PropertySubtitle>
                </GridColumnTwo>
                <GridColumnThree grayOut={isJwtSectionLocked}>
                    <TextInput
                        value={audience.value}
                        state={audience.state}
                        onChange={audience.setValue}
                        onReset={analyticsReportResetInputProxy(audience.reset)}
                    />
                </GridColumnThree>
                {/* ------TokenIssuer------ */}
                <GridColumnTwo grayOut={isJwtSectionLocked}>
                    <PropertySubtitle>TokenIssuer</PropertySubtitle>
                </GridColumnTwo>
                <GridColumnThree grayOut={isJwtSectionLocked}>
                    <TextInput
                        value={tokenIssuer.value}
                        state={tokenIssuer.state}
                        onChange={tokenIssuer.setValue}
                        onReset={analyticsReportResetInputProxy(tokenIssuer.reset)}
                    />
                </GridColumnThree>
                <InputErrorMessageColumn show={tokenIssuer.state === 'invalid'} columnStart={3} columnEnd={4} />
                {/* ------Pub. Key Provider URL------ */}
                <GridColumnTwo grayOut={isJwtSectionLocked}>
                    <PropertySubtitle>Pub. Key Provider URL</PropertySubtitle>
                </GridColumnTwo>
                <GridColumnThree grayOut={isJwtSectionLocked}>
                    <UrlInput
                        value={publicKeyProviderUrl.value}
                        state={publicKeyProviderUrl.state}
                        onChange={publicKeyProviderUrl.setValue}
                        onReset={analyticsReportResetInputProxy(publicKeyProviderUrl.reset)}
                        onNavigationClick={analyticsReportOpenUrlProxy()}
                    />
                </GridColumnThree>
            </SectionKeysContainer>
            {/* ------SPACE------ */}
            <GridColumnTwo />
            {/* ------Enable SMS log-in flow------ */}
            <GridColumn start={2} end={4} data-cy="enable-sms-login">
                <Checkbox text="Enable SMS log-in flow" onClick={smsLoginEnabled.toggleValue} checked={smsLoginEnabled.value} />
            </GridColumn>
            {anonymousLoginEnabled.value && (
                <GridColumnTwo>
                    <AnonymousLoginText>{ANONYMOUS_LOGIN_ENABLED_TEXT}</AnonymousLoginText>
                </GridColumnTwo>
            )}
            {/* ------END------ */}
            <GridSectionSeperator />
        </>
    )
}

export default observer(AuthenticationSection);