import React from 'react';
import { styled, css } from '../../../common-styles';

const Container = styled.div<{ showTopBorder?: boolean }>`
    display: flex;
    align-items: center;
    position: relative;

    height: 80px;
    background-color: rgba(51, 51, 51, 0.04);
    padding: 0 16px;

    ${({ theme, showTopBorder }) => showTopBorder && css`
        > div {
            border-top: 1px solid ${theme.colors.borderGray};
        }
    `}
`;

const ContentContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    
    > div {
        width: 100%;
    }
`;

type AppKeyBlockProps = {
    showTopBorder?: boolean;
}

const AppKeyBlock: React.FunctionComponent<AppKeyBlockProps> = ({ children, showTopBorder }) => (
    <Container showTopBorder={showTopBorder}>
        <ContentContainer>
            <div>{children}</div>
        </ContentContainer>
    </Container>
);

export default AppKeyBlock;