import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { styled } from '../../../common-styles';
import { InputProps } from '../types';
import Input from '../base/Input';
import { requireResource } from '../../../utils';
const externalUrlIcon = requireResource('url-icon.svg');

const Container = styled.div`
    width: 100%;
    display: inline-flex;
    align-items: center;
`;

const Link = styled.a <{ isActive: boolean }>`
    display: flex;
    align-items: center;
    opacity: ${props => (props.isActive ? 1 : 0.5)};
`;

const getValidUrl = (url: string) => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
    }

    return newUrl;
}

type UrlInputsProps = {
    onNavigationClick?: VoidFunction;
} & InputProps;

const UrlInput: FunctionComponent<UrlInputsProps> = (props) => {
    const { onNavigationClick, value } = props;
    const externalLink = useMemo(() => getValidUrl(value || ''), [value]);

    const handleLinkNavigationClick = useCallback(() => {
        onNavigationClick?.();
    }, [onNavigationClick]);

    return (
        <Container data-cy='url-input-container'>
            <Input { ...props} childrenDirection="right">
                <Link isActive={value != undefined && value.length > 0 && props.state != 'invalid'} target="_blank" href={externalLink} onClick={handleLinkNavigationClick}>
                    <img src={externalUrlIcon} alt="external link" />
                </Link>
            </Input>
        </Container>
    )
}


export default UrlInput;