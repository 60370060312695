import React, { FunctionComponent, useState, useCallback } from 'react';
import { styled } from '../../../../../common-styles';
import CustomSelectionButton from './CustomSelectionButton';
import CustomSelectionForm from './CustomSelectionForm';

const Container = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
`;

type CustomSelectionProps = {
    onCustomValueSelection?: (value: string) => void;
    customValueAddText: string;
    customValueSubmitText: string;
}

const CustomSelection: FunctionComponent<CustomSelectionProps> = ({ onCustomValueSelection, customValueAddText, customValueSubmitText }) => {
    const [shouldShowForm, setShouldShowForm] = useState(false);

    const handleSelectionButtonClick = useCallback(() => {
        setShouldShowForm(true);
    }, [setShouldShowForm]);

    const handleFormCancellationClick = useCallback(() => {
        setShouldShowForm(false);
    }, [setShouldShowForm]);

    const handleFormValueConfirmation = useCallback((value: string) => {
        onCustomValueSelection?.(value);
        setShouldShowForm(false);
    }, [setShouldShowForm, onCustomValueSelection]);

    return (
        <Container>
            {shouldShowForm ? (
                <CustomSelectionForm
                    onCancel={handleFormCancellationClick}
                    onConfirmValue={handleFormValueConfirmation}
                    submitText={customValueSubmitText}
                />
            ) : (
                    <CustomSelectionButton
                        onClick={handleSelectionButtonClick}
                        text={customValueAddText}
                    />
                )}
        </Container>
    )
}

export default CustomSelection;