import React, { FunctionComponent, useMemo } from 'react';
import { styled } from '../common-styles';
import { GridColumn } from './sections/common-styles';

const DEFAULT_ERROR_MESSAGE = '☝This value seems invalid. Give it another try..';

type InputErrorMessageProps = {
    message?: string;
    show?: boolean;
    columnStart: number;
    columnEnd: number;
}

const Container = styled(GridColumn)`
    width: 100%;
    margin: -15px auto 7px;
`;

const StyledErrorText = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.warning};
    font-weight: 400;
    width: 100%;
    text-align: center;
`;

const InputErrorMessageColumn: FunctionComponent<InputErrorMessageProps> = ({ columnStart, columnEnd, show, message }) => {
    const errorMessage = useMemo(() => message || DEFAULT_ERROR_MESSAGE, [message]);

    if (!show) return null;

    return (
        <Container start={columnStart} end={columnEnd}>
            <StyledErrorText>{errorMessage}</StyledErrorText>
        </Container>
    )
}

export default InputErrorMessageColumn;