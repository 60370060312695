import { action, observable, computed, reaction } from 'mobx';
import { AppDataStore } from '../AppDataStore';
import { CreateAppKeyFormProperties } from './types';
import { StringPropertyModel, UrlPropertyModel } from '../models';
import PropertyModel from '../models/PropertyModel';
import { BackofficeClientStore } from '../BackofficeClientStore';
import {AvailableQueue} from '../BackofficeClientStore/BackofficeClientStore'
import { ConfirmationPopupMessageStore } from '../ConfirmationPopupMessageStore';

class CreateAppKeyStore {
    @observable isSubmitting: boolean = false;
    @observable isFormActive: boolean = false;
    @observable shouldShowFormReview: boolean = false;
    @observable formData: CreateAppKeyFormProperties;

    private appDataStore: AppDataStore;
    private backofficeClientStore: BackofficeClientStore;
    confirmationPopupMessageStore: ConfirmationPopupMessageStore;

    constructor(appDataStore: AppDataStore, backofficeClientStore: BackofficeClientStore, confirmationPopupMessageStore: ConfirmationPopupMessageStore) {
        this.appDataStore = appDataStore;
        this.backofficeClientStore = backofficeClientStore;
        this.formData = this.getFormDefaultValue();
        this.confirmationPopupMessageStore = confirmationPopupMessageStore;

        reaction(() => this.isFormActive, () => {
            if (!this.isFormActive) {
                this.resetForm();
            }
        })
    }

    @action
    getAvailableQueues = async (): Promise<AvailableQueue[]> => (
        await this.backofficeClientStore.getAvailableQueues()
    )

    @action
    activateForm = async () => {
        const isResetAppKey = await this.appDataStore.tryResetAppKey();
        if (isResetAppKey) {
            this.isFormActive = true;
        }
    }

    @action
    disableForm = () => {
        this.isFormActive = false;
    }

    @action
    showFormReview = () => {
        this.shouldShowFormReview = true;
    }

    @action
    hideFormReview = () => {
        this.shouldShowFormReview = false;
    }

    @action
    resetForm = () => {
        this.formData = this.getFormDefaultValue();
    }

    private resetAll = () => {
        this.isSubmitting = false;
        this.isFormActive = false;
        this.shouldShowFormReview = false;
        this.resetForm();
    }

    @action
    submitForm = async (): Promise<string | undefined> => {
        if (!this.isFormValid || this.isSubmitting) return;
        this.isSubmitting = true;

        const { appKeyName, expertise, hostType, groupId, partnerName, environment, newGroupName, hostUrl } = this.formData;

        try {
            const { appKey } = await this.backofficeClientStore.createAppKey({
                appKeyName: appKeyName.value,
                expertise: expertise.value,
                groupId: groupId.value,
                hostType: hostType.value,
                partnerName: partnerName.value,
                poolName: partnerName.value,
                environment: environment.value,
                newGroupName: newGroupName.value,
                hostUrl: hostUrl.value
            })

            this.resetAll();
            this.appDataStore.refreshData(appKey);

            return appKey;
        } catch {
            this.isSubmitting = false;
        }

        return undefined;
    }

    private getFormDefaultValue = (): CreateAppKeyFormProperties => ({
        appKeyName: new StringPropertyModel('', '', {
            mandatory: true,
            maxLength: 100
        }),
        hostType: new PropertyModel<'web' | 'mobile'>({
            value: 'web'
        }),
        environment: new PropertyModel<'production' | 'development'>({
            value: 'production'
        }),
        hostUrl: new UrlPropertyModel('', '', {
            mandatory: false
        }),
        expertise: new StringPropertyModel('', 'general-conversation', {
            mandatory: true
        }),
        partnerName: new StringPropertyModel('', '', {
            mandatory: true
        }),
        groupId: new PropertyModel<number | undefined>({
            value: undefined
        }),
        newGroupName: new StringPropertyModel('', '')
    })

    @computed
    get isFormValid(): boolean {
        if (this.invalidProperties.length > 0) return false;
        if (typeof this.formData.groupId.value === 'undefined' && !this.formData.newGroupName.value) return false;

        return true;
    }

    @computed
    private get invalidProperties(): string[] {
        return Object.keys(this.formData).filter(key => this.formData[key].state === 'invalid').map(key => key);
    }

    @computed
    get sideMenuItemTitle(): string {
        if (this.isFormActive && !!this.formData.appKeyName.value) {
            return this.formData.appKeyName.value;
        }

        if (this.isFormActive) {
            return 'Name this AppKey';
        }

        return 'Create new AppKey';
    }

    @computed
    get isFormChanged(): boolean {
        return Object.keys(this.formData).filter(key => this.formData[key].state === 'changed').length > 0;
    }

    @action
    public canExitCreateAppKeyForm = async (): Promise<boolean> => {
        return !this.isFormChanged || await this.confirmationPopupMessageStore.showPopup();
    }
}

export default CreateAppKeyStore;