import React, { useState, useCallback, useMemo, useEffect, FunctionComponent } from 'react';
import { styled, css } from '../../common-styles';
import UsersSection from './UsersSection';
import AppKeysSection from './AppKeysSection';
import NameInput from '../AppInputs/inputs/NameInput';
import { HighlightedButton, FadeInContainer } from '../BasicElements';
import { CreateNewGroupHeader } from './CollapsibleGroup/Header/CreateNewGroupHeader';
import useUserManagementStore from '../../hooks/useUserManagementStore';
import InputErrorMessage from '../InputErrorMessage';
import { observer } from 'mobx-react-lite';
import { GroupManagementStore, GroupManagementStoreProvider } from './GroupManagementStore';
import useBackofficeClientStore from '../../hooks/useBackofficeClientStore';
import useGroupManagementStore from './GroupManagementStore/useGroupManagementStore';

const GroupContent = styled.div`
    padding: 0 0 15px 0px;
    display: flex;
    margin-top: 30px;
`;

const StyledNameInput = styled(NameInput)`
    width: 330px;
    font-size: 24px;
`;

const ContentHeader = styled.div`
    display: flex;
`;

const ButtonsContainer = styled.div`
    display: flex;
    padding-left: 420px;
`;

const CreateGroupButton = styled(HighlightedButton) <{ isValid: boolean }>`
    &:not(:last-child) {
        margin-right: 5px;
    }
    
    ${({ isValid }) => !isValid && css`
        opacity: 0.3;
        cursor: default;
    `}
`;

const Container = styled.div<{ isOpen: boolean }>`
    position: relative;
    border-bottom: 1px solid #d5d6da;
`;

const ContentContainer = styled.div`
    padding-left: 20px;
    width: 100%;
    top: 0;
`;

const StyledHeader = styled(CreateNewGroupHeader)`
    cursor: pointer;
`;

const Overlay = styled(FadeInContainer)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 100;
`;

const ErrorMessageContainer = styled.div`
    width: 305px;
    margin-top: 5px;
`;

const GroupCreator: FunctionComponent = observer(() => {
    const userManagementStore = useUserManagementStore();
    const groupManagementStore = useGroupManagementStore();

    const groupName = useMemo(() => groupManagementStore.formData.newGroupName.value || '', [groupManagementStore.formData.newGroupName.value]);
    const [isOpen, setIsOpen] = useState(false);
    const clearAndClose = useCallback(() => setIsOpen(false), [setIsOpen]);
    const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        groupManagementStore.formData.newGroupName.setValue(event.target.value);
    }, [groupManagementStore.formData.newGroupName.setValue])

    const handleHeaderClick = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen])

    const handleSubmitClick = useCallback(async () => {
        if (!groupManagementStore.isFormValid || groupManagementStore.isSubmitting) return;

        const { success } = await groupManagementStore.submit();

        if (!success) return;

        userManagementStore.refresh();
    }, [groupManagementStore.isFormValid, groupManagementStore.submit, groupManagementStore.isSubmitting, userManagementStore.refresh]);

    useEffect(() => {
        if (isOpen) return;

        groupManagementStore.reset();
    }, [isOpen, groupManagementStore.reset]);

    return (
        <Container isOpen={isOpen} data-cy={`management-group-create${isOpen ? '-open' : ''}`}>
            {groupManagementStore.isSubmitting && <Overlay />}
            {!isOpen && (
                <StyledHeader
                    groupName='Create new group'
                    onClick={handleHeaderClick}
                />
            )}
            <ContentContainer>
                {isOpen && (
                    <>
                        <ContentHeader>
                            <StyledNameInput
                                onNameChange={handleNameChange}
                                placeHolder='Name this group'
                                value={groupName}
                            />
                            <ButtonsContainer>
                                <CreateGroupButton isValid={groupManagementStore.isFormValid} onClick={handleSubmitClick}>{groupManagementStore.isSubmitting ? 'Submitting...' : 'Create group'}</CreateGroupButton>
                                <HighlightedButton
                                    isHollow={true}
                                    onClick={clearAndClose}
                                >
                                    Cancel
                        </HighlightedButton>
                            </ButtonsContainer>
                        </ContentHeader>
                        <ErrorMessageContainer>
                            <InputErrorMessage show={groupManagementStore.doesNewGroupNameAlreadyExist} message={'☝This group name already exists'} />
                        </ErrorMessageContainer>
                        <GroupContent>
                            <UsersSection />
                            <AppKeysSection />
                        </GroupContent>
                    </>
                )}
            </ContentContainer>
        </Container>
    );
});


const GroupCreatorWrapper: FunctionComponent = () => {
    const userManagementStore = useUserManagementStore();
    const backofficeClientStore = useBackofficeClientStore();
    const groupManagementStore = useMemo(() => new GroupManagementStore(backofficeClientStore, userManagementStore), [userManagementStore, backofficeClientStore]);

    return (
        <GroupManagementStoreProvider value={groupManagementStore}>
            <GroupCreator />
        </GroupManagementStoreProvider>
    )
}

export default observer(GroupCreatorWrapper);