import React, { FunctionComponent } from 'react';
import { SvgIconProps } from '.';
import { styled } from '../common-styles';

const Container = styled.div`
    display: flex;
    box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.03);
    border-radius: 1px 1px 10px 10px;
`;

const SdkTextBoxMock: FunctionComponent<SvgIconProps> = ({ fillColor }) => (
    <Container>
        <svg width="380" height="50" viewBox="0 0 380 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.895432 0.895431 0 2 0H378C379.105 0 380 0.895431 380 2V30C380 41.0457 371.046 50 360 50H20C8.9543 50 0 41.0457 0 30V2Z" fill="white" />
            <path d="M361 25L345 17L346 23L358 25L346 27L345 33L361 25Z" fill={fillColor} />
            <path d="M345 17L345.447 16.1056C345.111 15.9374 344.708 15.9722 344.406 16.1956C344.103 16.4191 343.952 16.7934 344.014 17.1644L345 17ZM361 25L361.447 25.8944C361.786 25.725 362 25.3788 362 25C362 24.6212 361.786 24.275 361.447 24.1056L361 25ZM345 33L344.014 32.8356C343.952 33.2066 344.103 33.5809 344.406 33.8044C344.708 34.0278 345.111 34.0626 345.447 33.8944L345 33ZM346 27L345.836 26.0136C345.414 26.0839 345.084 26.4141 345.014 26.8356L346 27ZM346 23L345.014 23.1644C345.084 23.5859 345.414 23.9161 345.836 23.9864L346 23ZM358 25L358.164 25.9864C358.647 25.906 359 25.4888 359 25C359 24.5112 358.647 24.094 358.164 24.0136L358 25ZM344.553 17.8944L360.553 25.8944L361.447 24.1056L345.447 16.1056L344.553 17.8944ZM360.553 24.1056L344.553 32.1056L345.447 33.8944L361.447 25.8944L360.553 24.1056ZM345.014 26.8356L344.014 32.8356L345.986 33.1644L346.986 27.1644L345.014 26.8356ZM344.014 17.1644L345.014 23.1644L346.986 22.8356L345.986 16.8356L344.014 17.1644ZM345.836 23.9864L357.836 25.9864L358.164 24.0136L346.164 22.0136L345.836 23.9864ZM357.836 24.0136L345.836 26.0136L346.164 27.9864L358.164 25.9864L357.836 24.0136Z" fill={fillColor} />
            <path d="M127.024 31H128.096L130.08 20.792H133.376L133.584 19.8H125.92L125.712 20.792H129.008L127.024 31Z" fill="#666666" />
            <path d="M132.322 34.264H133.394L139.602 22.936H138.434L134.93 29.512L133.89 22.936H132.818L134.13 30.952L132.322 34.264Z" fill="#666666" />
            <path d="M138.777 34.264H139.817L140.521 30.584L140.665 29.864C141.065 30.68 141.945 31.128 142.873 31.128C145.625 31.128 147.289 28.808 147.289 26.024C147.289 24.296 146.425 22.808 144.489 22.808C143.337 22.808 142.457 23.256 141.817 23.992L141.945 23.352L142.025 22.936H140.985L138.777 34.264ZM143.001 30.2C141.625 30.2 141.017 29.24 141.017 27.768C141.017 26.024 142.121 23.736 144.265 23.736C145.641 23.736 146.233 24.888 146.233 26.184C146.233 27.96 145.209 30.2 143.001 30.2Z" fill="#666666" />
            <path d="M151.631 31.128C153.151 31.128 154.431 30.472 155.359 28.904L154.591 28.472C153.823 29.688 152.991 30.2 151.807 30.2C150.479 30.2 149.807 29.272 149.807 27.864C149.807 27.56 149.823 27.304 149.919 26.968H155.919C156.015 26.408 156.047 26.088 156.047 25.688C156.015 23.784 154.847 22.808 153.247 22.808C150.463 22.808 148.767 25.448 148.767 27.816C148.767 29.832 149.871 31.128 151.631 31.128ZM150.191 26.04C150.719 24.744 151.711 23.736 153.119 23.736C154.303 23.736 155.071 24.44 155.071 25.624C155.071 25.752 155.071 25.896 155.055 26.04H150.191Z" fill="#666666" />
            <path d="M164.254 31.128C165.31 31.128 166.062 30.824 166.894 29.928L166.798 30.408L166.686 31H167.726L169.278 22.936H168.238L168.11 23.592L168.014 24.072C167.694 23.304 167.006 22.808 165.87 22.808C163.134 22.808 161.502 25.048 161.502 27.864C161.502 29.592 162.318 31.128 164.254 31.128ZM164.526 30.2C163.134 30.2 162.558 29.064 162.558 27.704C162.558 25.928 163.598 23.736 165.742 23.736C167.118 23.736 167.694 24.728 167.694 26.136C167.694 27.976 166.654 30.2 164.526 30.2Z" fill="#666666" />
            <path d="M174.277 31H175.285L175.957 27.464C176.373 25.256 177.733 23.736 178.981 23.736C179.749 23.736 180.165 24.152 180.165 24.888C180.165 25.08 180.133 25.304 180.069 25.592L179.013 31H180.021L180.709 27.464C181.157 25.192 182.485 23.736 183.733 23.736C184.533 23.736 184.901 24.12 184.901 24.84C184.901 25.064 184.869 25.272 184.805 25.592L183.765 31H184.773L185.845 25.464C185.909 25.128 185.941 24.888 185.941 24.616C185.941 23.512 185.365 22.808 184.069 22.808C182.965 22.808 181.845 23.512 181.173 24.536C181.109 23.432 180.485 22.808 179.317 22.808C178.213 22.808 177.125 23.464 176.533 24.504L176.837 22.936H175.829L174.277 31Z" fill="#666666" />
            <path d="M190.334 31.128C191.854 31.128 193.134 30.472 194.062 28.904L193.294 28.472C192.526 29.688 191.694 30.2 190.51 30.2C189.182 30.2 188.51 29.272 188.51 27.864C188.51 27.56 188.526 27.304 188.622 26.968H194.622C194.718 26.408 194.75 26.088 194.75 25.688C194.718 23.784 193.55 22.808 191.95 22.808C189.166 22.808 187.47 25.448 187.47 27.816C187.47 29.832 188.574 31.128 190.334 31.128ZM188.894 26.04C189.422 24.744 190.414 23.736 191.822 23.736C193.006 23.736 193.774 24.44 193.774 25.624C193.774 25.752 193.774 25.896 193.758 26.04H188.894Z" fill="#666666" />
            <path d="M198.13 31.128C199.81 31.128 200.93 30.312 200.93 28.52C200.93 27.368 199.97 26.76 199.09 26.36C198.386 26.04 197.762 25.72 197.762 25C197.762 24.2 198.37 23.736 199.138 23.736C199.73 23.736 200.29 23.976 200.658 24.632L201.442 24.056C201.01 23.304 200.322 22.808 199.282 22.808C197.714 22.808 196.722 23.672 196.722 25.144C196.722 26.248 197.458 26.776 198.386 27.176C199.138 27.496 199.858 27.848 199.858 28.776C199.858 29.72 199.138 30.2 198.274 30.2C197.138 30.2 196.674 29.368 196.482 28.44L195.522 28.888C195.634 29.88 196.498 31.128 198.13 31.128Z" fill="#666666" />
            <path d="M204.786 31.128C206.466 31.128 207.586 30.312 207.586 28.52C207.586 27.368 206.626 26.76 205.746 26.36C205.042 26.04 204.418 25.72 204.418 25C204.418 24.2 205.026 23.736 205.794 23.736C206.386 23.736 206.946 23.976 207.314 24.632L208.098 24.056C207.666 23.304 206.978 22.808 205.938 22.808C204.37 22.808 203.378 23.672 203.378 25.144C203.378 26.248 204.114 26.776 205.042 27.176C205.794 27.496 206.514 27.848 206.514 28.776C206.514 29.72 205.794 30.2 204.93 30.2C203.794 30.2 203.33 29.368 203.138 28.44L202.178 28.888C202.29 29.88 203.154 31.128 204.786 31.128Z" fill="#666666" />
            <path d="M212.004 31.128C213.06 31.128 213.812 30.824 214.644 29.928L214.548 30.408L214.436 31H215.476L217.028 22.936H215.988L215.86 23.592L215.764 24.072C215.444 23.304 214.756 22.808 213.62 22.808C210.884 22.808 209.252 25.048 209.252 27.864C209.252 29.592 210.068 31.128 212.004 31.128ZM212.276 30.2C210.884 30.2 210.308 29.064 210.308 27.704C210.308 25.928 211.348 23.736 213.492 23.736C214.868 23.736 215.444 24.728 215.444 26.136C215.444 27.976 214.404 30.2 212.276 30.2Z" fill="#666666" />
            <path d="M220.108 34.392C222.412 34.392 223.884 33.496 223.884 31.384C223.884 30.024 223.068 29.496 221.756 29.496H219.788C219.292 29.496 218.94 29.272 218.94 28.792C218.94 28.232 219.372 27.976 219.868 27.816C220.22 28.024 220.716 28.216 221.356 28.216C223.196 28.216 224.46 27.064 224.46 25.192C224.46 24.632 224.316 24.264 224.14 23.944L225.484 23.208L225.116 22.36L223.468 23.192C223.116 22.904 222.652 22.808 222.14 22.808C220.252 22.808 218.94 24.072 218.94 25.944C218.94 26.408 219.1 26.904 219.308 27.224C218.428 27.56 217.932 28.152 217.932 29C217.932 29.416 218.06 29.72 218.316 29.944C217.516 30.472 217.228 31.208 217.228 32.008C217.228 33.432 218.236 34.392 220.108 34.392ZM221.372 27.352C220.556 27.352 219.948 26.888 219.948 25.816C219.948 24.6 220.812 23.672 222.044 23.672C222.844 23.672 223.452 24.216 223.452 25.272C223.452 26.472 222.668 27.352 221.372 27.352ZM220.188 33.496C218.86 33.496 218.188 32.808 218.188 31.912C218.188 31.144 218.588 30.616 219.212 30.312C219.452 30.344 219.644 30.36 219.932 30.36H221.548C222.364 30.36 222.876 30.696 222.876 31.544C222.876 33.048 221.436 33.496 220.188 33.496Z" fill="#666666" />
            <path d="M228.803 31.128C230.323 31.128 231.603 30.472 232.531 28.904L231.763 28.472C230.995 29.688 230.163 30.2 228.979 30.2C227.651 30.2 226.979 29.272 226.979 27.864C226.979 27.56 226.995 27.304 227.091 26.968H233.091C233.187 26.408 233.219 26.088 233.219 25.688C233.187 23.784 232.019 22.808 230.419 22.808C227.635 22.808 225.939 25.448 225.939 27.816C225.939 29.832 227.043 31.128 228.803 31.128ZM227.363 26.04C227.891 24.744 228.883 23.736 230.291 23.736C231.475 23.736 232.243 24.44 232.243 25.624C232.243 25.752 232.243 25.896 232.227 26.04H227.363Z" fill="#666666" />
            <path d="M101 23V24.6C101 28.1346 97.866 31 94 31C90.134 31 87 28.1346 87 24.6V23" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="91" y="16" width="6" height="11" rx="3" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M94 31L94 34" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M96 34L92 34" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="62" cy="25" r="8" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <ellipse cx="59" cy="23.5" rx="1" ry="1.5" fill={fillColor} />
            <ellipse cx="65" cy="23.5" rx="1" ry="1.5" fill={fillColor} />
            <path d="M59 28L59.2265 28.151C60.906 29.2707 63.094 29.2707 64.7735 28.151L65 28" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
            <path d="M38 33V17H22V33H38Z" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="27.5" cy="21.5" r="1.5" fill={fillColor} />
            <path d="M33 25L32.4118 24.1913C32.8098 23.9018 33.3592 23.9449 33.7071 24.2929L33 25ZM33.5882 25.8087L22.5882 33.8087L21.4118 32.1913L32.4118 24.1913L33.5882 25.8087ZM33.7071 24.2929L38.7071 29.2929L37.2929 30.7071L32.2929 25.7071L33.7071 24.2929Z" fill={fillColor} />
        </svg>
    </Container>
)

export default SdkTextBoxMock;