import React, { FunctionComponent } from 'react';
import { styled, css } from '../../../common-styles';
import { requireResource } from '../../../utils';
const plusSign = requireResource('plus.png');

const Container = styled.div`
    width: 100%;
    margin-top:20px;
    height: 80px;
    display: flex;
    align-items: center;
    border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
`;

const AddAppKeyContainer = styled.button <{ isDisabled: boolean }> `
    padding-left: 16px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    ${({ isDisabled }) => isDisabled && css`
        cursor: default;
        opacity: 0.3;
    `}
`;


const StyledPlusSign = styled.img`
    margin-right: 16px;
`;

type AddAnotherAppKeyProps = {
    isDisabled: boolean;
    onClick?: VoidFunction;
}

const AddAnotherAppKey: FunctionComponent<AddAnotherAppKeyProps> = ({ isDisabled, onClick }) => (
    <Container>
        <AddAppKeyContainer isDisabled={isDisabled} onClick={onClick}>
            <StyledPlusSign src={plusSign} /> Add AppKey to this group
        </AddAppKeyContainer>
    </Container>
);

export default AddAnotherAppKey;