import React, { FunctionComponent } from "react";
import { styled } from "../common-styles";
import { SaveButtonStateType } from "../stores/AppDataStore";
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

const SaveChangesButton = styled.button`
  border-radius: 20px;
  min-width: 192px;
  height: 40px;
  display: inline-block;
  font-size: 1.6rem;

  border: 1px solid #E5E5E5;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.borderGray};

  &:active {
    opacity: 0.9;
  }

  transition: background-color .2s, color .2s, border-color .2s;
 
  &.active {    
      background-color: ${({ theme }) => theme.colors.purple};
      border-color:  ${({ theme }) => theme.colors.purple};
      color: ${({ theme }) => theme.colors.white};
      cursor: pointer;
  }
  `;

type SaveButtonProps = {
  text: string;
  state: SaveButtonStateType;
  onClick?: VoidFunction;
}

const SaveButton: FunctionComponent<SaveButtonProps> = ({ text, state, onClick }) => (
  <SaveChangesButton
    className={classNames({
      "active": state === 'active'
    })}
    onClick={onClick}
    data-cy="save-button"
  >
    {text}
  </SaveChangesButton>
);

export default observer(SaveButton);
