import React, { FunctionComponent } from 'react';
import useMarkedText from '../hooks/useMarkedText';

type MarkedTextProps = {
    text: string;
    matchTo: string;
    className?: string;
    disabled?: boolean;
    matchFromStart?: boolean;
}

const MarkedText: FunctionComponent<MarkedTextProps> = ({ text, matchTo, className, disabled, matchFromStart }) => {
    const markedText = useMarkedText(text, matchTo, matchFromStart);

    if (disabled) {
        return (
            <span className={className}>{text}</span>
        )
    }

    return (
        <span
            className={className}
            dangerouslySetInnerHTML={{
                __html: markedText
            }}
        />
    )
}

export default MarkedText;