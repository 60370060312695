import { BackendAppKeyDetails } from './types';
import { StringPropertyModel, ColorPropertyModel, UrlPropertyModel } from '../models';
import BooleanPropertyModel from '../models/BooleanPropertyModel';
import ReadonlyPropertyModel from '../models/ReadonlyPropertyModel';

export default (
    data: BackendAppKeyDetails
) => ({
    appKey: new ReadonlyPropertyModel<string>(data.appKey),
    displayName: new ReadonlyPropertyModel<string>(data.displayName),
    partner: new ReadonlyPropertyModel<string>(data.partner),
    messagingPool: new ReadonlyPropertyModel<string>(data.messagingPool),
    expertise: new ReadonlyPropertyModel<string>(data.expertise?.join?.(", ")),
    color: new ColorPropertyModel("theme", data.theme?.colors?.primary),
    fabioIconUrl: new UrlPropertyModel("fabioIconUrl", data.fabIconUrl),
    hostUrl: new UrlPropertyModel("hostUrl", data.hostUrl),
    notes: new StringPropertyModel("notes", data.notes, {
        maxLength: 250
    }),
    smsLoginEnabled: new BooleanPropertyModel("showLogin", data.showLogin),
    audience: new StringPropertyModel("audience", data.audience),
    tokenUniqueClaim: new StringPropertyModel("tokenUniqueClaim", data.tokenUniqueClaim),
    tokenIssuer: new StringPropertyModel("tokenIssuer", data.tokenIssuer),
    anonymousLoginEnabled: new ReadonlyPropertyModel<boolean>(data.isAnonymousUserSupported),
    welcomeAnimationEnabled: new ReadonlyPropertyModel<boolean>(data.isWelcomeAnimationEnabled),
    welcomeAnimationBubbleText: new ReadonlyPropertyModel<string>(data.welcomeAnimationBubbleText),
    isSmartQuestionsEnabled: new ReadonlyPropertyModel<boolean>(data.isSmartQuestionsEnabled),
    isFrequentQuestionsEnabled: new ReadonlyPropertyModel<boolean>(data.isFrequentQuestionsEnabled),
    isVoipEnabled: new BooleanPropertyModel(
        "isVoipEnabled",
        data.isVoipEnabled
    ),
    publicKeyProviderUrl: new UrlPropertyModel(
        "publicKeysOverriddenProvider",
        data.publicKeysOverriddenProvider
    ),
    hostType: new ReadonlyPropertyModel<'web' | 'mobile'>(data.hostType),
    environment: new ReadonlyPropertyModel<'production' | 'development'>(data.environment),
})