import React, { FunctionComponent, ChangeEvent } from 'react';
import { styled, css } from '../../../common-styles';
import { observer } from 'mobx-react-lite';
import { requireResource } from '../../../utils';
const asteriskIcon = requireResource('asterisk.svg');

const Container = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 3.2rem;
`;

const StyledInput = styled.input<{ hasValue: boolean }>`
    border: 0;
    padding: 0;
    margin: 0;
    outline: none;

    color: ${({ theme }) => theme.colors.purple};
    
    font-weight: 700;
    font-size: inherit;
    border-bottom: 1px dashed ${({ theme }) => theme.colors.purple};
    padding-bottom: 5px;
    width: 100%;

    position: relative;
    z-index: 1;
    background-color: transparent;
    &:focus {
        & + .placeholder-container {
            display: none;
        }
    }

    ${({ hasValue }) =>
        hasValue &&
        css`
            & + .placeholder-container {
                display: none;
            }
        `}
`;

const IconWrapper = styled.div`
    display: flex;
    margin-left: 22px;
`;

const InputContainer = styled.div`
    position: relative;
    flex-basis: 55%;
`;

const PlaceholderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    span {
        display: inline-block;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.purple};
        opacity: 0.5;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    .placeholder-example {
        font-size: 1.6rem;
    }
`;

interface Props {
    onNameChange(event: ChangeEvent<HTMLInputElement>): void;
    placeHolder: string;
    placeHolderExample?: string;
    className?: string;
    value: string;
}

const NameInput: FunctionComponent<Props> = ({
    onNameChange,
    placeHolder,
    placeHolderExample,
    className,
    value
}) => (
    <Container className={className}>
        <InputContainer>
            <StyledInput
                onChange={onNameChange}
                value={value}
                hasValue={!!value}
                data-cy='create-name-input'
            />
            <PlaceholderContainer className='placeholder-container'>
                <span className='placeholder'>{placeHolder}</span>
                {placeHolderExample && (
                    <span className='placeholder-example'>
                        ({placeHolderExample})
                    </span>
                )}
            </PlaceholderContainer>
        </InputContainer>
        <IconWrapper>
            <img src={asteriskIcon} alt='asterisk' />
        </IconWrapper>
    </Container>
);

export default observer(NameInput);
