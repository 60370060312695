import PropertyModel from './PropertyModel';

const getDefaultValue = (value: any): any => {
    if (typeof value === 'string') return '';
    if (typeof value === 'boolean') return false;

    return undefined;
}

export default class ReadonlyPropertyModel<T> extends PropertyModel<T> {
    constructor(value: T) {
        super({
            value: value || getDefaultValue(value),
            readonly: true
        })
    }
}