import React, { FunctionComponent, useState, useCallback } from 'react';
import { styled } from '../../../../../common-styles';
import { HighlightedButton } from '../../../../BasicElements';

const Container = styled.div`
    padding: 13px 8px;
`;

const InputContainer = styled.div`
    margin-bottom: 14px;
`;

const StyledInput = styled.input`
    appearance: none;
    outline: none;

    background-color: #fff;
    width: 100%;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.appBlack};
    padding: 4px 10px;
    caret-color: ${({ theme }) => theme.colors.purple};
    font-family: 'Apercu Mono Pro';

    border: 1px dashed ${({ theme }) => theme.colors.purple};
`;

const ActionButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledHighlightedButton = styled(HighlightedButton)`
    width: auto;
    padding: 10px 21px;

    &:not(:last-child) {
      margin-right: 20px;
    }
`;

type CustomSelectionFormProps = {
    submitText: string;
    onConfirmValue?: (value: string) => void;
    onCancel?: VoidFunction;
}

const CustomSelectionForm: FunctionComponent<CustomSelectionFormProps> = ({ submitText, onConfirmValue, onCancel }) => {
    const [customValue, setCustomValue] = useState<string>('');

    const handleSubmit = useCallback(() => {
        if (!customValue) return;

        onConfirmValue?.(customValue);
        setCustomValue('');
    }, [customValue, setCustomValue]);

    const handleCancel = useCallback(() => {
        onCancel?.();
        setCustomValue('');
    }, [setCustomValue]);

    const handleCustomValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomValue(event.target.value);
    }, [setCustomValue]);

    return (
        <Container>
            <InputContainer>
                <StyledInput data-cy="custom-select-input" value={customValue} onChange={handleCustomValueChange} />
            </InputContainer>
            <ActionButtonsContainer>
                <StyledHighlightedButton data-cy="custom-select-submit-button" onClick={handleSubmit}>{submitText}</StyledHighlightedButton>
                <StyledHighlightedButton isHollow onClick={handleCancel}>Cancel</StyledHighlightedButton>
            </ActionButtonsContainer>
        </Container>
    )
}

export default CustomSelectionForm;