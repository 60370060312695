import React, { FunctionComponent, useMemo } from 'react';
import { styled, css } from '../../../common-styles';
import useGroupManagementStore from '../GroupManagementStore/useGroupManagementStore';
import AddedAppKeyItem from './AddedAppKeyItem';
import { observer } from 'mobx-react-lite';

const BorderContainer = styled.div<{ showTopBorder: boolean }>`
    ${({ showTopBorder, theme }) => showTopBorder && css`
        > div {
            margin: 0 20px;
            border-top: 1px solid ${theme.colors.borderGray};
        }
        background-color: rgba(51, 51, 51, 0.04);
    `}
`;

const AddedAppKeys: FunctionComponent = () => {
    const { groupAppKeys, formData: { addedAppKeys } } = useGroupManagementStore();
    const shouldShowTopBorder = useMemo(() =>
        groupAppKeys.length > 0 && addedAppKeys.value.length > 0, [groupAppKeys, addedAppKeys.value]);

    return (
        <div>
            <BorderContainer showTopBorder={shouldShowTopBorder}>
                <div />
            </BorderContainer>
            {addedAppKeys.value.map((appKeyId) => (
                <AddedAppKeyItem appKeyId={appKeyId} key={appKeyId} />
            ))}
        </div>
    );
}

export default observer(AddedAppKeys);