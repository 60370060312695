import React, {
  FunctionComponent,
  useMemo,
  useEffect,
  useState,
  useCallback
} from 'react';
import UserManagement from './UserManagement';
import {
  UserManagementStore,
  UserManagementStoreProvider,
  UserStore,
} from '../../stores';
import useBackofficeClientStore from '../../hooks/useBackofficeClientStore';
import { UserDetails } from '../../types';

const ManagementMainPage: FunctionComponent = () => {
  const backofficeClientStore = useBackofficeClientStore();
  const userStore = useMemo(() => new UserStore(backofficeClientStore), [
    backofficeClientStore
  ]);

  const [adminUserDetails, setAdminUserDetails] = useState<
    UserDetails | undefined
  >(undefined);

  const getAdminDetails = useCallback(async () => {
    const userDetails = await userStore.getAndRefreshUserDetails();
    if (userDetails && userDetails.role === 'admin') {
      setAdminUserDetails(userDetails);
    }
  }, [userStore, setAdminUserDetails]);

  useEffect(() => {
    getAdminDetails();
  }, []);

  const userManagementStore = useMemo(
    () => new UserManagementStore(backofficeClientStore, adminUserDetails),
    [backofficeClientStore, adminUserDetails]
  );

  return (
    <div>
      <UserManagementStoreProvider value={userManagementStore}>
        <UserManagement />
      </UserManagementStoreProvider>
    </div>
  );
};

export default ManagementMainPage;
