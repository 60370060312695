import React, { useMemo } from 'react';
import { styled } from '../../common-styles';
import CollapsibleGroup from './CollapsibleGroup';
import UsersSection from './UsersSection';
import AppKeysSection from './AppKeysSection';
import {
    GroupManagementStore,
    GroupManagementStoreProvider
} from './GroupManagementStore';
import useUserManagementStore from '../../hooks/useUserManagementStore';
import { observer } from 'mobx-react-lite';
import useBackofficeClientStore from '../../hooks/useBackofficeClientStore';

interface Props {
    groupId: number;
}

const StyledCollapsibleGroup = styled(CollapsibleGroup)`
    border-bottom: 1px solid #d5d6da;
`;

const GroupContent = styled.div`
    position: relative;
    display: flex;
`;

const GroupViewer: React.FunctionComponent<Props> = ({ groupId }) => {
    const userManagementStore = useUserManagementStore();
    const backofficeClientStore = useBackofficeClientStore();
    const groupManagementStore = useMemo(() => new GroupManagementStore(backofficeClientStore, userManagementStore, groupId), [groupId, userManagementStore, backofficeClientStore]);
    const { groupDetails, groupUsers } = groupManagementStore;
    const groupName = useMemo(() => groupDetails?.name || '', [groupDetails]);

    return (
        <GroupManagementStoreProvider value={groupManagementStore}>
            <StyledCollapsibleGroup
                groupName={groupName}
                numberOfMembers={groupUsers.length}
            >
                <GroupContent>
                    <UsersSection />
                    <AppKeysSection />
                </GroupContent>
            </StyledCollapsibleGroup>
        </GroupManagementStoreProvider>
    );
};

export default observer(GroupViewer);
