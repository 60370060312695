import React, { FunctionComponent, useCallback } from 'react';
import { styled, css } from '../../../common-styles';

const StyledLi = styled.li`
    display: flex;
    align-items: center;

    user-select: none;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 10px;
    }
`;

const LeftCntainer = styled.div`
    display: flex;
    margin-right: 16px;
`;
const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Text = styled.span<{ isSelected?: boolean }>`
    cursor: pointer;

    ${({ isSelected, theme }) => !isSelected && css`
        color: ${theme.colors.gray};
    `}
`;

const ImageContainer = styled.div<{ isSelected?: boolean }>`
    margin-top: 10px;
    display: flex;
    cursor: pointer;

    ${({ isSelected }) => !isSelected && css`
        opacity: 0.4;
    `}
`;


const RadioButton = styled.button<{ isSelected?: boolean }>`
    position: relative;
    width: 14px;
    height: 14px;
    border: 1px solid #C4C4C4;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    cursor:pointer;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.purple};
    }

${props => props.isSelected && css`
  &:after {
    content: '';
  }
`}
`;


export type RadioGroupOptionProps = {
    value: string;
    displayName?: string;
    imageUrl?: string;
}

type RadioGroupOptionInnerProps = {
    onClick?: (value: string) => void;
    isSelected?: boolean;
} & RadioGroupOptionProps;

const RadioGroupOption: FunctionComponent<RadioGroupOptionInnerProps> = ({ onClick, value, displayName, isSelected, imageUrl }) => {
    const handleClick = useCallback(_ => {
        onClick?.(value);
    }, [onClick, value]);

    return (
        <StyledLi onClick={handleClick}>
            <LeftCntainer>
                <RadioButton isSelected={isSelected} />
            </LeftCntainer>
            <RightContainer>
                <Text isSelected={isSelected}>{displayName || value}</Text>
                {imageUrl && (
                    <ImageContainer isSelected={isSelected}>
                        <img src={imageUrl} draggable={false} />
                    </ImageContainer>
                )}
            </RightContainer>

        </StyledLi>
    )
}

export default RadioGroupOption;