import { StringPropertyModel, ColorPropertyModel, UrlPropertyModel } from '../models';
import BooleanPropertyModel from '../models/BooleanPropertyModel';
import ReadonlyPropertyModel from '../models/ReadonlyPropertyModel';

export type AppKeyDetails = {
    appKey: ReadonlyPropertyModel<string>;
    displayName: ReadonlyPropertyModel<string>;
    partner: ReadonlyPropertyModel<string>;
    messagingPool: ReadonlyPropertyModel<string>;
    expertise: ReadonlyPropertyModel<string>;
    color: ColorPropertyModel;
    fabioIconUrl: UrlPropertyModel;
    hostUrl: UrlPropertyModel;
    notes: StringPropertyModel;
    smsLoginEnabled: BooleanPropertyModel;
    tokenUniqueClaim: StringPropertyModel;
    audience: StringPropertyModel;
    tokenIssuer: StringPropertyModel;
    anonymousLoginEnabled: ReadonlyPropertyModel<boolean>;
    welcomeAnimationEnabled: ReadonlyPropertyModel<boolean>;
    welcomeAnimationBubbleText: ReadonlyPropertyModel<string>;
    isFrequentQuestionsEnabled: ReadonlyPropertyModel<boolean>;
    isSmartQuestionsEnabled: ReadonlyPropertyModel<boolean>;
    isVoipEnabled: ReadonlyPropertyModel<boolean>;
    publicKeyProviderUrl: UrlPropertyModel;
    hostType: ReadonlyPropertyModel<'mobile' | 'web'>;
    environment: ReadonlyPropertyModel<'production' | 'development'>;
};

export interface BackendAppKeyDetails {
    appKey: string;
    displayName: string;
    partner: string;
    messagingPool: string;
    expertise: string[];
    showLogin: boolean;
    theme: SdkTheme;
    fabIconUrl: string;
    isWelcomeAnimationEnabled: boolean;
    welcomeAnimationBubbleText: string;
    isFrequentQuestionsEnabled: boolean;
    isSmartQuestionsEnabled: boolean;
    isVoipEnabled: boolean;
    isAnonymousUserSupported: boolean;
    shouldValidateTokenAudience: boolean;
    tokenIssuer: string;
    tokenUniqueClaim: string;
    audience: string;
    timestamp?: string;
    hostUrl: string;
    notes: string;
    publicKeysOverriddenProvider: string;
    hostType: 'web' | 'mobile';
    environment: 'production' | 'development';
}

export interface AppKeySideBar {
    name: string;
    appKey: string;
    hostType: 'web' | 'mobile';
    environment: 'production' | 'development';
}

export type SdkTheme = {
    colors: {
        primary: string;
    }
}

export type SaveButtonStateType = 'disable' | 'active' | 'saving';

interface SaveButtonState {
    [key: string]: SaveButtonStateType;
}

export const SaveButtonStateEnum: SaveButtonState = Object.freeze({
    DISABLE: "disable",
    ACTIVE: "active",
    SAVING: "saving"
});

export interface BackendPropertiesToUpdate {
    showLogin?: boolean;
    color?: string;
    fabIconUrl?: string;
    isWelcomeAnimationEnabled?: boolean;
    welcomeAnimationBubbleText?: string;
    isFrequentQuestionsEnabled?: boolean;
    isSmartQuestionsEnabled?: boolean;
    isVoipEnabled?: boolean;
    isAnonymousUserSupported?: boolean;
    shouldValidateTokenAudience?: boolean;
    tokenIssuer?: string;
    tokenUniqueClaim?: string;
    hostType?: string;
    appKey?: string;
    displayName?: string;
    hostUrl?: string;
    notes?: string;
}

export type PropertyState = 'idle' | 'changed' | 'invalid' | 'saved';

export enum PropertyType {
    StringValue = 'string',
    BooleanValue = 'boolean',
    ColorValue = 'color',
    UrlValue = 'url',
}


export type Predicate = (value: any) => boolean;