import { action, observable, reaction } from 'mobx';
import { GroupDetails, UserDetails, UpsertGroupRequest } from '../../types';
import { AxiosInstance } from 'axios';
import { AuthenticationStore } from '../AuthenticationStore';
import { BackendAppKeyDetails, BackendPropertiesToUpdate } from '../AppDataStore';
import { CreateAppKeyParams } from '../../clients/types';
import createBackofficeClient from './createBackofficeClient';
import logger from '../../utils/logger';
import { getUser } from '../../authentication';
import { UserManagementData } from '../UserManagementStore/types';

export interface AvailableQueue {
    queue: string;
    expertise: string[];
}

class BackofficeClientStore {
    @observable private backofficeApiClient: AxiosInstance;
    @observable private authenticationStore: AuthenticationStore;

    constructor(authenticationStore: AuthenticationStore) {
        this.authenticationStore = authenticationStore;
        this.backofficeApiClient = createBackofficeClient();

        reaction(() => this.authenticationStore.isLoggedIn, () => {
            this.backofficeApiClient = createBackofficeClient(getUser()?.token || '');
        }, {
            fireImmediately: true
        })
    }

    @action
    getAvailableQueues = async (): Promise<AvailableQueue[]> => {
        try {
            const response = await this.backofficeApiClient.get<AvailableQueue[]>(
                'api/v1/availableQueues'
            );

            if (response.status !== 200 && response.status !== 204)
                throw `bad request status: ${response}`;

            return response.data;
        }
        catch (error) {
            logger.error('getAvailableQueues: failed to fetch data from backoffice api', {
                error
            })
        }

        return [];
    }

    @action
    updateToken = (authToken: string) => {
        this.backofficeApiClient.defaults.headers['Authorization'] = `Bearer ${authToken}`;
    }

    @action
    fetchUserData = async (): Promise<UserDetails | undefined> => {
        try {
            const response = await this.backofficeApiClient.get<UserDetails>(
                "api/v1/getUserData"
            );

            if (response.status !== 200 && response.status !== 204)
                throw `bad request status: ${response}`;

            return response.data;
        } catch (error) {
            logger.error('fetchAppKeys: failed to fetch data from backoffice api', {
                error
            });
        }

        return undefined;
    };

    @action
    fetchAppKeyDetails = async (
        appKeyGuid: string
    ): Promise<BackendAppKeyDetails | undefined> => {
        try {
            const response = await this.backofficeApiClient.get<BackendAppKeyDetails>(
                `api/v1/appKey/${appKeyGuid}`
            );

            if (response.status !== 200 && response.status !== 204)
                throw `bad request status: ${response}`;

            return response.data;

        } catch (error) {
            logger.error('fetchAppKeyDetails: failed to fetch data from backoffice api', {
                error
            })
        }
        return undefined;
    };

    @action
    updateAppKeyDetails = async (appKey: string, changedProperties: BackendPropertiesToUpdate): Promise<void> => {
        try {
            const response = await this.backofficeApiClient.patch(
                `api/v1/appKey/${appKey}`,
                changedProperties
            );

            if (!(response.status === 200 || response.status === 204)) throw `invalid response from server, status: ${response.status}`;

            logger.info('app key updated', {
                appKey,
                changedProperties
            })
        } catch (error) {
            logger.error('updateAppKeyDetails: failed to patch data to backoffice api', {
                appKey,
                changedProperties,
                error
            })

            throw error;
        }
    };

    @action
    createAppKey = async (params: CreateAppKeyParams): Promise<{ appKey: string }> => {
        try {
            const response = await this.backofficeApiClient.post(
                `api/v1/appKeys`,
                params
            );

            if (!(response.status === 200 || response.status === 204)) throw `invalid response from server, status: ${response.status}`;

            logger.info('app key created', {
                appKey: response.data.appKey
            })

            return response.data;
        } catch (error) {
            logger.error('createAppKey: failed to post data to backoffice api', {
                params,
                error
            })

            throw error;
        }
    };

    @action
    upsertManagementGroup = async (params: UpsertGroupRequest): Promise<void> => {
        try {
            const response = await this.backofficeApiClient.put(
                `api/v1/management/groups`,
                params
            );

            if (!(response.status === 200 || response.status === 204)) throw `invalid response from server, status: ${response.status}`;

        } catch (error) {
            logger.error('upsertManagementGroup: failed to put data to backoffice api', {
                params,
                error
            })

            throw error;
        }
    };

    @action
    getUserGroups = async (): Promise<GroupDetails[]> => {
        try {
            const response = await this.backofficeApiClient.get(
                `api/v1/groups`,
            );

            if (!(response.status === 200 || response.status === 204)) throw `invalid response from server, status: ${response.status}`;

            return response.data as GroupDetails[];
        } catch (error) {
            logger.error('getUserGroups: failed to get data from backoffice api', {
                error
            })

            throw error;
        }
    }

    @action
    fetchManagementData = async (): Promise<UserManagementData> => {
        try {
            const response = await this.backofficeApiClient.get<UserManagementData>(
                `api/v1/management/data`,
            );

            if (!(response.status === 200 || response.status === 204)) throw `invalid response from server, status: ${response.status}`;

            return response.data;
        } catch (error) {
            logger.error('fetchManagementData: failed to get data from backoffice api', {
                error
            })
            throw error;
        }
    };
}

export default BackofficeClientStore;