import React, { FunctionComponent, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '../common-styles';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    background-color: rgba(0, 0, 0, .4);
    backdrop-filter: blur(4px);
`;

const PopupContainer = styled.div`
    position: relative;
    z-index: 2;
    width: 664px;
`;

const PopupContent = styled.div`
    background-color: #fff;
    border-radius: 10px;
    padding: 50px 32px;
`;

interface Props {
    onClose():void;
    children?: ReactNode;
    className?: string;
}

const Popup: FunctionComponent<Props> = ({onClose, children, className}) => (
        <Container data-cy="popup">
            <Backdrop onClick={onClose} />
            <PopupContainer className={className}>
                <PopupContent>
                    {children}
                </PopupContent>
            </PopupContainer>
        </Container>
)

export default observer(Popup);