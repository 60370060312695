import { getUser } from '../authentication';

const BACKOFFICE_APP_KEY = '36b4ba5f-9cd8-4302-b096-4b4c7c814a08';

export default () => {
    (window as any).AE_SDK.initialize(BACKOFFICE_APP_KEY).then(() => {
        const token = getUser()?.token;
        if (!token) return;

        (window as any).AE_SDK.signIn(getUser()?.token);
    });
}