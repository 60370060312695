import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import MarkedText from '../MarkedText';
import useUserManagementStore from '../../hooks/useUserManagementStore';

type MarkedTextProps = {
    text: string;
    matchFromStart?: boolean;
    className?: string;
}

const GroupFilterMarkedText: FunctionComponent<MarkedTextProps> = ({ text, className, matchFromStart }) => {
    const { groupsSearchTerm, isSearchTermValid } = useUserManagementStore();

    return (
        <MarkedText
            className={className}
            text={text}
            matchTo={groupsSearchTerm}
            disabled={!isSearchTermValid}
            matchFromStart={matchFromStart}
        />
    )
}

export default observer(GroupFilterMarkedText);