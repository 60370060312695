import React, { FunctionComponent, useMemo } from 'react';
import { styled } from '../../../common-styles';
import { InputProps } from '../types';
import Input from '../base/Input';
import useTheme from '../../../hooks/useTheme';

const Container = styled.div`
    width: 100%;
`;

const ColorIndicatorWrapper = styled.div`
    margin: 0 7px 0 10px;
`;

const ColorIndicator = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 1px;
    border: 1px solid;
    transition: background-color .1s;
`;

const ColorInput: FunctionComponent<InputProps> = (props) => {
    const { colors } = useTheme();
    const { state, value } = props;
    const color = useMemo(() => (state !== 'invalid' && value) ? value : colors.lightgray, [state, value])

    return (
        <Container data-cy="color">
            <Input {...props} childrenDirection="left">
                <ColorIndicatorWrapper>
                    <ColorIndicator style={{
                        backgroundColor: value ? color : 'transparent',
                        borderColor: color
                    }} />
                </ColorIndicatorWrapper>
            </Input>
        </Container>
    )
}

export default ColorInput;

