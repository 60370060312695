import React, { useEffect, FunctionComponent, useMemo } from 'react';
import Heading from '../../components/Heading';
import SideMenu from '../../components/SideMenu';
import AppKeyDetails from '../../components/AppKeyDetails';
import { styled } from '../../common-styles';
import { observer } from "mobx-react-lite";
import useAppDataStore from '../../hooks/useAppDataStore';
import MobileNotSupported from './MobileNotSupported';
import bowser from 'bowser';
import CreateFormReviewPopup from '../../components/CreateAppKeyForm/CreateFormReviewPopup';
import NavigationConfirmationPopup from '../../components/NavigationConfirmationPopup';
import useCreateAppKeyStore from '../../hooks/useCreateAppKeyStore';
import useConfirmationPopupMessageStore from '../../hooks/useConfirmationPopupMessageStore';
const bowserParser = bowser.getParser(window.navigator.userAgent);
const isMobilePlatform = bowserParser.getPlatformType(true) === 'mobile';

const MainContainer = styled.div`
    width: 1300px;
    margin: 0 auto;
    padding: 0 10px;
`;

const ContentContainer = styled.div`
    position: relative;
    display: flex;
`;

const AppKeyDetailsWrapper = styled.div`
    flex-basis: 70%;
    padding: 0 0 30px 20px;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;   
    z-index: 1000;
    background-color:rgba(255, 255, 255, .5);
`;

const MainPageView: FunctionComponent = () => {
    const { isSavingState, changedProperties } = useAppDataStore();
    const { shouldShowFormReview, isSubmitting, isFormChanged: isCreateAppKeyFormChanged } = useCreateAppKeyStore();
    const { shouldShowPopup } = useConfirmationPopupMessageStore();
    const shouldShowOverlay = useMemo(() => isSavingState || isSubmitting, [isSavingState, isSubmitting]);

    useEffect(() => {
        if (changedProperties.length || isCreateAppKeyFormChanged) {
            window.onbeforeunload = () => true;
            return;
        }

        window.onbeforeunload = null;
        return () => {
            window.onbeforeunload = null;
        }
    }, [changedProperties, isCreateAppKeyFormChanged]);

    if (isMobilePlatform) {
        return <MobileNotSupported />;
    }

    return (
        <MainContainer data-cy="main-container">
            <Heading />
            {shouldShowFormReview && <CreateFormReviewPopup />}
            {shouldShowPopup && <NavigationConfirmationPopup />}
            <ContentContainer>
                {shouldShowOverlay && <Overlay />}
                <SideMenu />
                <AppKeyDetailsWrapper>
                    <AppKeyDetails />
                </AppKeyDetailsWrapper>
            </ContentContainer>
        </MainContainer>
    )
};


export default observer(MainPageView);