import Cookie from 'js-cookie';
import { BackofficeIdentity } from './BackofficeIdentity';

const identityCookieKey = 'identity';

export const removeUser = (): void => {
    Cookie.remove(identityCookieKey)
}

export const saveUser = (user: BackofficeIdentity): void => {
    Cookie.set(identityCookieKey, user);
}

export const getUser = (): BackofficeIdentity | undefined =>
    Cookie.getJSON(identityCookieKey) as
    | BackofficeIdentity
    | undefined;


