import React, { FunctionComponent } from 'react';
import { styled } from '../common-styles';
import { HighlightedText } from './BasicElements';
import { requireResource } from '../utils';
import BoLinks from './BoLinks';

const crazyArrowIcon = requireResource('crazy-arrow.svg');

const Container = styled.div`
    position: relative;
    padding-top: 37px;
`;

const TextContainer = styled.div`
    margin: 5px 0;
`;


const CrazyArrowContainer = styled.div`
    position: absolute;
    top: -84px;
    right: 0;
`;

const LinksContainer = styled.div`
    margin-top: 29px;
`;

const UnderConstruction: FunctionComponent = () => (
    <Container>
        <CrazyArrowContainer>
            <img src={crazyArrowIcon} alt="arrow" />
        </CrazyArrowContainer>
        <TextContainer>
            <HighlightedText>
                This section is still under construction. <br />
                To edit features for this AppKey, please contact the AE SDK team
            </HighlightedText>
        </TextContainer>
        <LinksContainer>
            <BoLinks />
        </LinksContainer>
    </Container>
)

export default UnderConstruction;