import axios, { AxiosInstance } from 'axios';
const BACKOFFICE_API_BASE_URL = 'https://sdk-backoffice-api.anywhere.expert';

export default (
    token?: string
): AxiosInstance => {
    return axios.create({
        baseURL: BACKOFFICE_API_BASE_URL,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
};
