import React, { FunctionComponent } from 'react';
import { PropertyTitle, GridColumnOne, GridColumnTwo, PropertySubtitle, ValueText, GridColumnThree } from './common-styles';
import useAppDataStore from '../../hooks/useAppDataStore';

import { GridSectionSeperator } from '../BasicElements';
import { observer } from 'mobx-react-lite';

const PartnerInfoSection: FunctionComponent = () => {
    const { appKeyDetails } = useAppDataStore();

    if (!appKeyDetails) return null;

    const { partner, messagingPool, expertise } = appKeyDetails;

    return (
        <>
            <GridColumnOne>
                <PropertyTitle>Routing info</PropertyTitle>
            </GridColumnOne>
            <GridColumnTwo>
                <PropertySubtitle>Partner's name</PropertySubtitle>
            </GridColumnTwo>
            <GridColumnThree>
                <ValueText>{partner.value}</ValueText>
            </GridColumnThree>
            <GridColumnTwo>
                <PropertySubtitle>Queue/Pool name</PropertySubtitle>
            </GridColumnTwo>
            <GridColumnThree>
                <ValueText>{messagingPool.value}</ValueText>
            </GridColumnThree>
            <GridColumnOne>
                <PropertyTitle>Expert skill</PropertyTitle>
            </GridColumnOne>
            <GridColumnTwo>
                <PropertySubtitle>Expertise</PropertySubtitle>
            </GridColumnTwo>
            <GridColumnThree>
                <ValueText>{expertise.value}</ValueText>
            </GridColumnThree>
            <GridSectionSeperator />
        </>
    )
}

export default observer(PartnerInfoSection);