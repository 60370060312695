import React, { FunctionComponent } from 'react';
import { styled } from '../common-styles';
import { HighlightedText } from './BasicElements';
import { requireResource } from '../utils';
import BoLinks from './BoLinks';

const noPermissionIcon = requireResource('ic-no-perm.svg');

const TextContainer = styled.div`
    margin: 5px 0;
`;

const LinksContainer = styled.div`
    margin-top: 29px;
`;

const IconContainer = styled.div`
    margin-bottom: 26px;
`;

const NoAppKeys: FunctionComponent = () => (
    <div>
        <IconContainer>
            <img src={noPermissionIcon} alt="no permission icon" />
        </IconContainer>
        <TextContainer>
            <HighlightedText>
                Oh, it looks like you don’t have access permissions to the Backoffice. <br />
                To get permission, please contact us:
            </HighlightedText>
        </TextContainer>
        <LinksContainer>
            <BoLinks />
        </LinksContainer>
    </div>
)

export default NoAppKeys;