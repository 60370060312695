import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import useUserManagementStore from '../../hooks/useUserManagementStore';
import GroupViewer from './GroupViewer';
import GroupCreator from './GroupCreator';

const GroupViewerList: FunctionComponent = () => {
    const { groupsDetails, isFetching } = useUserManagementStore();

    if (isFetching) {
        return <div>Loading...</div>;
    }

    if (!groupsDetails || groupsDetails.length === 0) {
        return <div>No results</div>;
    }

    return (
        <div>
            <GroupCreator />
            {groupsDetails.map(details => (
                <GroupViewer key={details.id} groupId={details.id} />
            ))}
        </div>
    );
};

export default observer(GroupViewerList);
