import React, { FunctionComponent } from 'react';
import { styled } from '../../common-styles';
import { menuItemStyle } from './common';

type SideMenuItemProps = {
    text: string;
    iconSrc: string;
    onClick?: VoidFunction;
    testId?: string;
};

const SideMenuItemContainer = styled.div`
    ${menuItemStyle}
`;

const Title = styled.h3`
    font-size: 1.6rem;
    transition: color .4s;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const IconWrapper = styled.div`
    margin: 0 20px;
    will-change: transform;
    transform: translate3d(0, 0, 0) rotate(0);
    transition: transform .4s;
    width: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SideMenuItem: FunctionComponent<SideMenuItemProps> = ({
    text,
    onClick,
    iconSrc,
    testId
}) => (
        <SideMenuItemContainer onClick={onClick} data-cy={testId || 'side-menu-item'}>
            <IconWrapper>
                <img src={iconSrc} />
            </IconWrapper>
            <Title>{text}</Title>
        </SideMenuItemContainer>
    );


export default SideMenuItem;
