import React, { FunctionComponent, useMemo, useCallback } from "react";
import { SaveButtonStateEnum, SaveButtonStateType } from "../../stores/AppDataStore";
import { observer } from 'mobx-react-lite';
import SaveButton from '../SaveButton';
import useCreateAppKeyStore from '../../hooks/useCreateAppKeyStore';

const buttonStateToTextMap = {
  [SaveButtonStateEnum.ACTIVE]: 'Save and Create key',
  [SaveButtonStateEnum.DISABLE]: 'Save and Create key',
  [SaveButtonStateEnum.SAVING]: 'Saving...'
}

const CreateFormSaveButton: FunctionComponent = () => {
  const { isFormValid, showFormReview, isSubmitting } = useCreateAppKeyStore();

  const saveButtonState = useMemo<SaveButtonStateType>(() => {
    if (!isSubmitting) {
      return isFormValid ? 'active' : 'disable';
    }

    return 'saving';
  }, [isFormValid, isSubmitting])

  const buttonText = useMemo(() => buttonStateToTextMap[saveButtonState], [saveButtonState]);

  const handleButtonClick = useCallback(() => {
    if (saveButtonState !== 'active') return;

    showFormReview();
  }, [saveButtonState, showFormReview])

  return <SaveButton text={buttonText} onClick={handleButtonClick} state={saveButtonState} />
}

export default observer(CreateFormSaveButton);
