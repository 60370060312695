import React, { FunctionComponent } from 'react';
import { SvgIconProps } from '.';

const OpenLockIcon: FunctionComponent<SvgIconProps> = ({ fillColor }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 23V14H11V23H21Z" stroke={fillColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 14V11C13 9.34315 14.3431 8 16 8C17.6569 8 19 9.34315 19 11V14" stroke={fillColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.5 19C16.5 18.7239 16.2761 18.5 16 18.5C15.7239 18.5 15.5 18.7239 15.5 19H16.5ZM15.5 20C15.5 20.2761 15.7239 20.5 16 20.5C16.2761 20.5 16.5 20.2761 16.5 20H15.5ZM15.5 19V20H16.5V19H15.5Z" fill={fillColor} />
        <circle cx="16" cy="18" r="0.5" fill="white" stroke={fillColor} />
    </svg>

)

export default OpenLockIcon;