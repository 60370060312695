import { useEffect, useCallback, MutableRefObject } from 'react';

const useClickOutsideElement = (elementRef: MutableRefObject<any>, handler: VoidFunction): void => {
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (elementRef.current && !elementRef.current.contains(event.target)) {
            handler?.();
        }
    }, [elementRef, handler]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [handleClickOutside])
}

export default useClickOutsideElement;