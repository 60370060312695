import React, { FunctionComponent } from 'react';
import Heading from '../../components/Heading';
import { styled } from '../../common-styles';
import { GroupViewerList } from '../../components/GroupDetails';
import AdminSearchBar from '../../components/AdminSearchBar';
import { observer } from 'mobx-react-lite';

const MainContainer = styled.div`
    width: 1300px;
    margin: 0 auto;
    padding: 0 10px 300px;
`;

const Title = styled.div`
    font-size: 32px;
    font-weight: bold;
`;

const PageHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
`;

const SearchBarContainer = styled.div`
    width: 600px;
`;

const GroupsSection = styled.div`
    margin-top: 42px;
`;

const UserManagement: FunctionComponent = () => (
    <MainContainer data-cy='user-management-main-container'>
        <Heading />
        <PageHeaderContainer>
            <Title>Permissions management</Title>
            <SearchBarContainer>
                <AdminSearchBar />
            </SearchBarContainer>
        </PageHeaderContainer>
        <GroupsSection>
            <GroupViewerList />
        </GroupsSection>
    </MainContainer>
)

export default observer(UserManagement);