import React, { FunctionComponent } from 'react';
import { styled } from '../../../../common-styles';
import MultipleSelectionItem, { MultipleSelectionItemProps } from './MultipleSelectionItem';

const StyledList = styled.ul`
    display: flex;
    align-items: center;
`;

type MultipleSelectionListProps = {
    items: MultipleSelectionItemProps[];
    onItemClick: (value: any) => void;
    selectedValue: string;
}

const MultipleSelectionList: FunctionComponent<MultipleSelectionListProps> = ({ items, onItemClick, selectedValue }) => (
    <StyledList data-cy='multiple-selection-list'>
        {items.map((item, index) =>
            <MultipleSelectionItem
                {...item}
                isSelected={item.value === selectedValue}
                onClick={onItemClick}
                key={index}
            />
        )}
    </StyledList>
)

export default MultipleSelectionList;