import React, { FunctionComponent } from 'react';
import { SvgIconProps } from '.';

const DesktopIndicatorIcon: FunctionComponent<SvgIconProps> = ({ fillColor }) => (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="15.0036" height="12" rx="1.23954" stroke={fillColor} strokeWidth="1.23954" />
        <line x1="1" y1="4.38023" x2="16.0036" y2="4.38023" stroke={fillColor} strokeWidth="1.23954" />
        <circle cx="14.2071" cy="2.64853" r="0.375089" fill={fillColor} />
        <circle cx="12.8243" cy="2.64853" r="0.375089" fill={fillColor} />
        <circle cx="11.4298" cy="2.64853" r="0.375089" fill={fillColor} />
    </svg>
)

export default DesktopIndicatorIcon;