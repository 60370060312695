import React, { useCallback, ChangeEvent } from 'react';
import useCreateAppKeyStore from '../../hooks/useCreateAppKeyStore';
import { NameInput } from '../AppInputs';
import { observer } from 'mobx-react-lite';

const CreateAppKeyNameInput = () => {
    const {
        formData: { appKeyName }
    } = useCreateAppKeyStore();

    const handleInputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            appKeyName.setValue(event.target.value);
        },
        [appKeyName]
    );
    
    return (
        <NameInput
            onNameChange={handleInputChange}
            placeHolder='Name this AppKey'
            placeHolderExample='e.g. Asurion Hub'
            value={appKeyName.value}
        />
    );
};

export default observer(CreateAppKeyNameInput);
