import { requireResources } from '../utils';
import { Assets } from '../theme';

const [mailIcon, slackIcon] = requireResources(['mail-icon.png', 'slack-mark.png']);

const assets: Assets = {
    emailIconUrl: mailIcon,
    slackIconUrl: slackIcon,
};

export default assets;
