import { action, observable } from 'mobx';

const MESSAGE_EXIT_TIMEOUT = 6000;

class SnackbarStore {
    @observable isShown: boolean = false;
    @observable message: string = '';
    @observable actionText: string = '';
    @observable private innerActionCallback: VoidFunction = () => { };

    private actionTimeoutReference: number | undefined;

    @action
    readonly showMessage = (message: string, actionText: string, actionCallback: VoidFunction) => {
        this.message = message;
        this.actionText = actionText;
        this.innerActionCallback = actionCallback;
        this.timeMessage();
    }

    @action
    readonly hide = () => {
        this.clearTimeouts();
        this.innerActionCallback = () => { };
        this.isShown = false;
    }

    @action
    readonly actionCallback = () => {
        this.innerActionCallback();
        this.hide();
    }

    @action
    private readonly clearTimeouts = () => {
        if (typeof this.actionTimeoutReference !== 'undefined') {
            clearTimeout(this.actionTimeoutReference);
            this.actionTimeoutReference = undefined;
        }
    }

    @action
    private readonly timeMessage = () => {
        this.clearTimeouts();

        this.isShown = true;
        this.actionTimeoutReference = setTimeout(() => {
            this.isShown = false;
        }, MESSAGE_EXIT_TIMEOUT)
    }
}

export default SnackbarStore;