import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { styled, css } from '../../../common-styles';
import { GroupAppKey } from '../../../types';
import { requireResource } from '../../../utils';
import GroupFilterMarkedText from '../GroupFilterMarkedText';
import { observer } from 'mobx-react-lite';
import useTextMapping from '../../../hooks/useTextMapping';
import useGroupManagementStore from '../GroupManagementStore/useGroupManagementStore';
import MobileIndicatorIcon from '../../../svg-icons/MobileIndicatorIcon';
import DesktopIndicatorIcon from '../../../svg-icons/DesktopIndicatorIcon';
import useTheme from '../../../hooks/useTheme';
const removeAppKeyIcon = requireResource('remove-button.svg');
const resetIcon = requireResource('reset.svg');

const ItemContainer = styled.div<{ isRemoved: boolean }>`
    height: 80px;
    display: flex;
    align-items: center;
    position: relative;

    &:not(:last-child) {
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: calc(100% - 32px);
            margin: 0 auto;
            height: 1px;
            background-color: ${({ theme }) => theme.colors.borderGray};
        }
    }

    .remove-app-key-button {
        display: none;
    }

    ${({ isRemoved }) => !isRemoved && css`
        &:after {
            width: 90%;
        }

        &:hover {
            .remove-app-key-button {
                display: flex;
            }

            > div {
                background-color: rgba(51, 51, 51, 0.04);
            }

            .host-type-icon-container  {
                transform: translate3d(0, 0, 0) rotate(16deg);
            }
        }
    `}
`;

const ContentContainer = styled.div<{ isRemoved: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 16px);
    margin: 0 16px;
    border-radius: 4px;

    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        height: 1px;
        width: 0px;
        background-color: ${({ theme }) => theme.colors.purple};
        transition: width .2s;
    }

    ${({ isRemoved }) => isRemoved && css`
        &:after {
            width: 90%;
        }
    `}
`;

const DetailsContainer = styled.div<{ isRemoved: boolean }>`
    display: flex;
    align-items: center;
    transition: opacity .2s;

    ${({ isRemoved }) => isRemoved && css`
        opacity: 0.4;
    `}
`;


const AppKeyName = styled.div`
    line-height: 25px;
`;

const AppKey = styled.div`
    font-size: 10px;
`;

const ActionButtonContainer = styled.div`
    margin-right: 24px;
    margin-left: 8px;
    width: 24px;
    display: flex;
    justify-content: center;
`;

const ActionButton = styled.button`
    display: flex;
    cursor: pointer;

    &.reset-app-key-button {
        position: absolute;
        width: 24px;
        height: 24px;
        right: -4px;
        top: 0;
        bottom: 0;
        margin: auto 0;
    }
`;

const HostTypeIconContainer = styled.div`
    display: flex;
    margin-right: 16px;
    justify-content: center;
    width: 15px;
    will-change: transform;
    transform: translate3d(0, 0, 0) rotate(0);
    transition: transform .4s;
`;

interface AppKeyDetailsProps {
    appKey: GroupAppKey;
}

const AppKeyDetails: FunctionComponent<AppKeyDetailsProps> = ({ appKey }) => {
    const { formData: { removedAppKeys } } = useGroupManagementStore();
    const theme = useTheme();
    const HostTypeIconComponent = useMemo(() =>
        appKey.hostType === 'mobile' ? MobileIndicatorIcon : DesktopIndicatorIcon, [appKey.hostType]);
    const environmentDisplayText = useTextMapping('appKeyEnvironment', appKey.environment);
    const isRemoved = useMemo(() => removedAppKeys.value.includes(appKey.id), [appKey, removedAppKeys.value]);

    const handleDeleteButtonClick = useCallback(() => {
        removedAppKeys.add(appKey.id);
    }, [appKey, removedAppKeys]);

    const handleResetButtonClick = useCallback(() => {
        removedAppKeys.remove(appKey.id);
    }, [appKey, removedAppKeys]);

    return (
        <ItemContainer isRemoved={isRemoved}>
            <ContentContainer isRemoved={isRemoved}>
                <ActionButtonContainer>
                    {isRemoved ? (
                        <ActionButton onClick={handleResetButtonClick} className='reset-app-key-button'>
                            <img src={resetIcon} />
                        </ActionButton>
                    ) : (
                            <ActionButton onClick={handleDeleteButtonClick} className='remove-app-key-button'>
                                <img src={removeAppKeyIcon} />
                            </ActionButton>
                        )}
                </ActionButtonContainer>
                <DetailsContainer isRemoved={isRemoved}>
                    <HostTypeIconContainer className='host-type-icon-container'>
                        <HostTypeIconComponent fillColor={theme.colors.appBlack} />
                    </HostTypeIconContainer>
                    <div>
                        <AppKeyName>
                            <GroupFilterMarkedText text={appKey.friendlyName} />{' '}
                            <span>[{environmentDisplayText}]</span>
                        </AppKeyName>
                        <AppKey>
                            <GroupFilterMarkedText text={appKey.appKey} />
                        </AppKey>
                    </div>
                </DetailsContainer>
            </ContentContainer>
        </ItemContainer>
    );
}

export default observer(AppKeyDetails);