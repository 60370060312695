import PropertyModel from './PropertyModel';
import { Predicate } from '../AppDataStore';
import { generateMinLengthValidation, generateMaxLengthValidation } from './validations';

const MAX_URL_CHARS = 1000;

function isValidUrl(str: string) {
    if (!str.length) return true;
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
}

type UrlValidationsProps = {
    mandatory?: boolean;
}

const getValidations = (validationSettings?: UrlValidationsProps): Predicate[] => {
    let validations: Predicate[] = [isValidUrl, generateMaxLengthValidation(MAX_URL_CHARS)];

    if (validationSettings?.mandatory)
        validations.push(generateMinLengthValidation(1));

    return validations;
}

export default class UrlPropertyModel extends PropertyModel<string> {
    constructor(backendKey: string, value: string, validationSettings?: UrlValidationsProps) {
        super({
            backendKey,
            value: value || '',
            validations: getValidations(validationSettings)
        })
    }
}