import React, { FunctionComponent, useCallback } from 'react';
import { styled, css } from '../../../../common-styles';

const StyledListItem = styled.li<{ isSelected: boolean }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    font-family: 'Apercu Mono Pro';
    
    &:not(:last-child) {
        margin-bottom: 8px;
    }
    
    ${({ isSelected }) => isSelected && css`
        background-color: rgba(130, 35, 210, .1);
    `}

    ${({ isSelected }) => !isSelected && css`
         &:hover {
            background-color: ${({ theme }) => theme.colors.lightgrayEditArea};˝
         }
    `}
`;

const Text = styled.span`
    color: ${({ theme }) => theme.colors.appBlack};
    white-space: nowrap;
    font-size: 1.6rem;
`;

export type SelectItemProps = {
    value: any;
    displayName?: string;
}

type SelectItemInnerProps = {
    onClick?: (value: any) => void;
    isSelected: boolean;
} & SelectItemProps

const SelectItem: FunctionComponent<SelectItemInnerProps> = ({
    value,
    displayName,
    isSelected,
    onClick
}) => {
    const handleItemClick = useCallback(() => {
        onClick?.(value);
    }, [onClick, value]);

    return (
        <StyledListItem isSelected={isSelected} onClick={handleItemClick} data-cy='select-item'>
            <Text>{displayName || value}</Text>
        </StyledListItem>
    )
}

export default SelectItem;