import React from 'react';
import { styled } from '../../../../common-styles';
import { requireResource } from '../../../../utils';
import GroupFilterMarkedText from '../../GroupFilterMarkedText';
const arrowIcon = requireResource('arrow-down.png');

const MembersCount = styled.div<{ isOpen: boolean }>`
    opacity: ${props => (props.isOpen ? 0 : 1)};
    transition: opacity 400ms;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray};
    line-height: 2;
`;

const GroupName = styled(GroupFilterMarkedText) <{ isOpen: boolean }>`
    font-weight: ${props => (props.isOpen ? 'bold' : '400')};
`;

const HeaderContainer = styled.div`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    cursor: pointer;
`;

const ArrowIcon = styled.img<{ isOpen: boolean }>`
    height: 7px;
    width: 12px;
    transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform 400ms;
`;

const NameAndCount = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export interface Props {
    groupName: string;
    numberOfMembers: number;
    isOpen: boolean;
}

export const CollapsibleGroupHeader: React.FunctionComponent<Props> = ({
    groupName,
    numberOfMembers,
    isOpen
}) => (
        <HeaderContainer data-cy='management-group-view-header'>
            <NameAndCount>
                <div data-cy='management-group-name'>
                    <GroupName isOpen={isOpen} text={groupName} />
                </div>
                <MembersCount isOpen={isOpen} data-cy='management-group-member-count'>{numberOfMembers} members</MembersCount>
            </NameAndCount>
            <ArrowIcon src={arrowIcon} isOpen={isOpen} />
        </HeaderContainer>
    );
