import React, { FunctionComponent, useEffect, useMemo } from "react";
import { styled, css } from "../../common-styles";
import { PropertyGrid, GridColumn, GridColumnTwo } from '../sections/common-styles';
import { HeadingSection, AppKeySection, ThemeSection, PartnerInfoSection, AuthenticationSection, FeaturesSection, SnackbarSection } from '../sections';
import useAppDataStore from '../../hooks/useAppDataStore';
import NoAppKeys from '../NoAppKeys';
import { requireResource } from '../../utils';
import { FadeInContainer } from '../BasicElements';
import Tooltip from '../Tooltip';
import { observer } from 'mobx-react-lite';
import { useAnalytics } from 'react-shisell';
import EditFormSaveButton from './EditFormSaveButton';

const dataPlaceholderImage = requireResource('data-empty-state-placeholder.svg');

const AppKeyDetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const PlaceholderContainer = styled.div`
  position: relative;
  height: 100%;
  padding-top: 30px;
`;

const Placeholder = styled.div<{ faded?: boolean }>`
  grid-column-end:span 4;
  height: 100%;
  background-image:url(${dataPlaceholderImage});
  background-position: left top;
  background-repeat: no-repeat;

  transition: opacity 1s;
  ${props => props.faded && css`
    opacity: .2;
  `}
`;

const NoAppKeysContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 1;
`;


const EditAppKeyForm: FunctionComponent = () => {
  const { appKeyDetails, sideBarAppkeys, initialized } = useAppDataStore();
  const analytics = useAnalytics();
  const appKeyDetailsExist = useMemo(() => !!appKeyDetails && Object.keys(appKeyDetails).length > 1, [appKeyDetails])

  useEffect(() => {
    if (!appKeyDetailsExist) return;

    analytics.dispatcher
              .withExtra('Page', 'Edit')
              .withExtra('AppKey', appKeyDetails?.appKey?.value)
              .withExtra('FriendlyName', appKeyDetails?.displayName?.value)
              .dispatch('View')
  }, [appKeyDetails, analytics]);

  if (!appKeyDetailsExist) {
    const noAppKeysFetched = initialized && sideBarAppkeys.length === 0;
    return (
      <PlaceholderContainer>
        {noAppKeysFetched && (
          <NoAppKeysContainer>
            <FadeInContainer>
              <NoAppKeys />
            </FadeInContainer>
          </NoAppKeysContainer>
        )}
        <Placeholder faded={noAppKeysFetched} />
      </PlaceholderContainer>
    )
  }

  return (
    <FadeInContainer>
      <Tooltip showFor="reset-button" />
      <Tooltip showFor="lock" />
      <AppKeyDetailsContainer>
        <PropertyGrid>
          <SnackbarSection />
          <HeadingSection />
          <AppKeySection />
          <PartnerInfoSection />
          <AuthenticationSection />
          <ThemeSection />
          <FeaturesSection />
          <GridColumnTwo />
          <GridColumn horizontalCenter start={1} end={4}>
            <EditFormSaveButton />
          </GridColumn>
        </PropertyGrid>
      </AppKeyDetailsContainer>
    </FadeInContainer>
  )
}


export default observer(EditAppKeyForm);
