import React, {
    FunctionComponent,
    useState,
    useCallback,
    useEffect
} from 'react';
import { styled, css } from '../../../common-styles';
import { GroupMember as GroupMemberType } from '../../../types';
import { requireResource, delay } from '../../../utils';
import GroupFilterMarkedText from '../GroupFilterMarkedText';
import useTextMapping from '../../../hooks/useTextMapping';
const removeUser = requireResource('remove-button.svg');
const resetIcon = requireResource('reset.svg');
const lock = requireResource('lock.svg');
import { observer } from 'mobx-react-lite';

interface Props {
    member: GroupMemberType;
    isDeleted: boolean;
    isAdmin?: boolean;
    onUserStatusChanged: (isDeleted: boolean, userId: number, email?: string) => void;
}

const Container = styled.div`
    padding: 0 16px;
    height: 50px;
    align-items: center;
    position: relative;
    display: flex;
    &:hover {
        background-color: rgba(51, 51, 51, 0.04);
    }
`;

const ContentContainer = styled.div<{ isDeleted: boolean }>`
    display: flex;
    align-items: center;
    flex: 1;
    opacity: ${props => (props.isDeleted ? 0.5 : 1)};
    transition: opacity 300ms;
    height: 20px;
`;

const Lock = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
`;

const DeleteUserButton = styled.button`
    height: 24px;
    width: 24px;
    display: flex;
    cursor: pointer;
`;

const RoleText = styled.span`
    padding-left: 16px;
    font-family: 'Apercu Mono Pro';
    color: ${({ theme }) => theme.colors.gray};
    font-size: 1.4rem;
`;

const FirstColumnWrapper = styled.div`
    margin-right: 16px;
    width: 24px;
    display: flex;
`;

const SecondColumnWrapper = styled.div`
    margin-right: 16px;
    flex: 1;
`;

const ThirdColumnWrapper = styled.div<{ isDeleted: boolean }>`
    width: ${props => (props.isDeleted ? '130px' : '150px')};
`;

const MarkDeleted = styled.div<{ isDeleted: boolean }>`
    position: absolute;
    top: 0;
    left: 39px;
    bottom: 0;
    margin: auto 0;
    background-color: ${({ theme }) => theme.colors.purple};
    height: 1px;
    width: 0;
    transition: width 200ms;

    ${({ isDeleted }) => isDeleted && css`
        width: calc(100% - 127px);
    `}
`;

const ResetDeletedItem = styled.button`
    height: 13px;
    width: 20px;
    background-repeat: no-repeat;
    background-image: url(${resetIcon});
    cursor: pointer;
`;

const GroupMember: FunctionComponent<Props> = ({ member, isDeleted, onUserStatusChanged, isAdmin }) => {
    const [isInEditMode, setIsInEditMode] = useState(false);

    const groupRoleText = useTextMapping('userGroupRole', member.groupRole);

    const deleteClicked = useCallback(() => {
        onUserStatusChanged(true, member.id, member.email)
    }, [member, onUserStatusChanged]);

    const handleMouseEnter = useCallback(() => {
        setIsInEditMode(true);
    }, [setIsInEditMode]);

    const handleMouseLeave = useCallback(() => {
        setIsInEditMode(false);
    }, [setIsInEditMode]);

    return (
        <Container
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <MarkDeleted isDeleted={isDeleted} />
            <ContentContainer isDeleted={isDeleted}>
                <FirstColumnWrapper>
                    {(isInEditMode && isAdmin) ? (
                        <Lock>
                            <img src={lock} />
                        </Lock>
                    ) :
                        (isInEditMode && !isDeleted) && (
                            <DeleteUserButton onClick={() => deleteClicked()}>
                                <img src={removeUser} />
                            </DeleteUserButton>
                        )
                    }
                </FirstColumnWrapper>
                <SecondColumnWrapper data-cy="existed-group-member">
                    <GroupFilterMarkedText
                        text={member.email}
                        matchFromStart={true}
                    />
                </SecondColumnWrapper>
                <ThirdColumnWrapper isDeleted={isDeleted}>
                    <RoleText>{groupRoleText}</RoleText>
                </ThirdColumnWrapper>
            </ContentContainer>
            {isDeleted && (
                <ResetDeletedItem onClick={() => onUserStatusChanged(false, member.id)} />
            )}
        </Container>
    );
};

export default observer(GroupMember);
