import { AnalyticsEventModel, AnalyticsDispatcher } from 'shisell';
import { analytics } from 'react-shisell';
import axios from 'axios';
import { useMemo, useEffect } from 'react';

const ANALYTICS_API_BASE_URL = 'https://prodhome1analyticslistener.mysoluto.com';
const ANALYTICS_API_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

const APP_SCOPE_NAME = 'AEBackOffice';

const analyticsWriter = (eventModel: AnalyticsEventModel) => {
    axios.post(`${ANALYTICS_API_BASE_URL}/api/v1/Events/`, eventModel, {
        headers: ANALYTICS_API_HEADERS
    }).catch(error => console.error('error while sending analytic', eventModel, error))
};

const useEnrichedAnalytics = (extraData?: object) => {
    const writer = useMemo(() => (eventModel: AnalyticsEventModel) => {
        if (globals.REACT_APP_IS_TEST) {
            console.log('[analytic]\t', eventModel);
            return;
        }
        analyticsWriter(eventModel);
    }, []);

    useEffect(() => {
        analytics.setWriter(writer);
    }, [writer])

    useEffect(() => {
        analytics.transformDispatcher((dispatcher: AnalyticsDispatcher<void>) =>
            dispatcher
                .createScoped(APP_SCOPE_NAME)
                .withExtras(extraData || {})
        );
    }, [extraData])
}

export default useEnrichedAnalytics;