import React, { FunctionComponent, useCallback } from 'react'
import { styled } from "../common-styles";
import Popup from './Popup';
import useConfirmationPopupMessageStore from '../hooks/useConfirmationPopupMessageStore';
import { HighlightedText, HighlightedButton } from './BasicElements';

const StyledPopup = styled(Popup)`
    width: 465px;
    line-height: 24px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 49px;
    margin-bottom: 10px;
`;

const WideHighlightedButton = styled(HighlightedButton)`
    width: 220px;
`;

const NavigationConfirmationPopup:FunctionComponent = () => {
    const confirmationPopupMessageStore = useConfirmationPopupMessageStore();

    const onStayOnPage = useCallback(()=> confirmationPopupMessageStore.hidePopup(false), [confirmationPopupMessageStore]);
    const onDiscardChangesAndNavigate = useCallback(()=> confirmationPopupMessageStore.hidePopup(true), [confirmationPopupMessageStore]);

    return (<StyledPopup onClose={onStayOnPage}>
        <HighlightedText>You have unsaved changes. Are you sure you want to navigate away from here?</HighlightedText>
        <ButtonsContainer>
            <WideHighlightedButton data-cy="discard-button" onClick={onDiscardChangesAndNavigate}>Discard changes and leave</WideHighlightedButton>
            <HighlightedButton isHollow={true}  onClick={onStayOnPage}>Stay on Page</HighlightedButton>
        </ButtonsContainer>
    </StyledPopup>)
}

export default NavigationConfirmationPopup;