import React, { FunctionComponent, useMemo } from 'react';
import { styled, keyframes, css } from '../common-styles';
import useSnackbarStore from '../hooks/useSnackbarStore';
import { observer } from 'mobx-react-lite';

type SnackbarDisplayState = 'idle' | 'shown' | 'hidden';

const snackbarShownStyle = css`
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
`;

const snackbarHiddenStyle = css`
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -10px, 0);
`;

const snackbarInAnimation = keyframes`
    0% {
        ${snackbarHiddenStyle}
    }

    70% {
        ${snackbarShownStyle}
    }
`;

const snackbarOutAnimation = keyframes`
    0% {
        ${snackbarShownStyle}
    }

    70% {
        ${snackbarHiddenStyle}
    }
`;


const Container = styled.div<{ displayState?: SnackbarDisplayState }>`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.warning};
    border-radius: 4px;
    text-align: center;
    padding: 6px;
    ${snackbarHiddenStyle}

    animation-duration: .4s;

    ${props => props.displayState === 'shown' && css`
        ${snackbarShownStyle}
        animation-name: ${snackbarInAnimation};
    `} 

    ${props => props.displayState === 'hidden' && css`
        animation-name: ${snackbarOutAnimation};
    `} 
`;

const Text = styled.span`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.white};
`;

const ActionButton = styled.button`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    text-decoration: underline;
`;

const Snackbar: FunctionComponent = () => {
    const { isShown, message, actionText, actionCallback } = useSnackbarStore();
    const displayState = useMemo<SnackbarDisplayState>(() => {
        if (!isShown && !message) return 'idle';
        return isShown ? 'shown' : 'hidden';
    }, [isShown, message]);

    return (
        <Container displayState={displayState}>
            <Text>{message}</Text>&nbsp;
            {actionText && <ActionButton onClick={actionCallback}>{actionText}</ActionButton>}
        </Container>
    )
}

export default observer(Snackbar);