import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '../../../common-styles';
import Collapsible from 'react-collapsible';
import Header from './Header';
import useUserManagementStore from '../../../hooks/useUserManagementStore';
import { observer } from 'mobx-react-lite';
import useGroupManagementStore from '../GroupManagementStore/useGroupManagementStore';
import { HighlightedButton, FadeInContainer } from '../../BasicElements';

const Container = styled.div`
    position: relative;
`;

const ContentContainer = styled.div`
    padding-left: 20px;
`;

const ContentHeading = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 40px;
`;

const UpdateGroupButton = styled(HighlightedButton)`
    &:not(:last-child) {
        margin-right: 5px;
    }
`;

const Overlay = styled(FadeInContainer)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 100;
`;
export interface Props {
    groupName: string;
    numberOfMembers: number;
    className?: string;
    isCreateNewGroup?: boolean;
}

const CollapsibleGroup: React.FunctionComponent<Props> = ({
    groupName,
    numberOfMembers,
    children,
    className,
    isCreateNewGroup = false
}) => {
    const { isSearchTermValid } = useUserManagementStore();
    const { reset: resetGroup, submit: submitForm, isSubmitting, isFormValid } = useGroupManagementStore();

    const [isOpen, setIsOpen] = useState(false);

    const collapseGroup = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const openGroup = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    useEffect(() => {
        setIsOpen(isSearchTermValid);
    }, [isSearchTermValid]);

    useEffect(() => {
        if (isOpen) return;

        resetGroup();
    }, [isOpen, resetGroup])

    const handleSubmitClick = useCallback(async () => {
        if (!isFormValid || isSubmitting) return;

        await submitForm();
    }, [submitForm, isSubmitting, isFormValid]);

    return (
        <Container className={className} data-cy={`management-group-view${isOpen ? '-open' : ''}`}>
            {isSubmitting && <Overlay />}
            <Collapsible
                trigger={
                    <Header
                        isOpen={isOpen}
                        groupName={groupName}
                        numberOfMembers={numberOfMembers}
                        isCreateNewGroup={isCreateNewGroup}
                    />
                }
                onOpening={openGroup}
                onClose={collapseGroup}
                open={isOpen}
                overflowWhenOpen='visible'
            >
                {isOpen &&
                    <ContentContainer>
                        <ContentHeading>
                            <ButtonsContainer>
                                {isFormValid && (
                                    <>
                                        <UpdateGroupButton data-cy="update-group-button" onClick={handleSubmitClick}>{isSubmitting ? 'Saving...' : 'Save changes'}</UpdateGroupButton>
                                        <HighlightedButton isHollow onClick={collapseGroup}>
                                            Cancel
                                        </HighlightedButton>
                                    </>
                                )}
                            </ButtonsContainer>
                        </ContentHeading>
                        {children}
                    </ContentContainer>}
            </Collapsible>
        </Container>
    );
};

export default observer(CollapsibleGroup);
