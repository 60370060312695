import { observable, action } from "mobx";
import Amplify, { Auth, Hub } from 'aws-amplify';
import { amplifyConfiguration } from './amplify-configuration';
import { getLoggedInUser } from './auth-utils';
import { cognitoIdentity } from '../../authentication/cognito/cognitoIdentity';
import { saveUser, removeUser } from '../../authentication/userManagement';
import queryString from '../../utils/queryString';
Amplify.configure(amplifyConfiguration);

//For Cypress Tests skip Authentication. Safe because we have token for backendApi. 
const IS_CYPRESS_TEST_ENV = window.location.href === globals.REACT_APP_CYPRESS_TEST_URL;
const REDIRECT_FROM_LOGIN_PARAM_NAME = 'authenticated';
const MAX_LOGGED_OUT_TIMEOUT = 7000;

const isRedirectedFromLogin = (): boolean => !!(queryString.get()[REDIRECT_FROM_LOGIN_PARAM_NAME]);

const runBackgroundTask = (fn: VoidFunction) => {
    setTimeout(() => {
        fn();
    }, 0);
}

type UserInfo = {
    email: string;
}

class AuthenticationStore {
    @observable isLoggedIn: boolean = false;
    @observable currentUser: UserInfo | undefined;

    @action
    initialize = async (): Promise<void> => {
        this.initiateHubListener();
        await this.logIn();
        setTimeout(() => {
            if (!this.isLoggedIn) {
                window.location.reload();
            }
        }, MAX_LOGGED_OUT_TIMEOUT);
    }

    @action
    logOut = (): void => {
        this.isLoggedIn = false;
        this.currentUser = undefined;
        removeUser();
        runBackgroundTask(() => {
            Auth.signOut();
        });
    }

    @action
    logIn = async (): Promise<void> => {
        if (IS_CYPRESS_TEST_ENV) {
            this.isLoggedIn = true;
            queryString.removeAndUpdateUrl(REDIRECT_FROM_LOGIN_PARAM_NAME);
            return;
        }
        const currentUser = await getLoggedInUser();
        if (!currentUser) {
            !isRedirectedFromLogin() && await Auth.federatedSignIn();
            return;
        }

        const userDetails = await cognitoIdentity.toIdentityUser(currentUser);
        saveUser(userDetails);
        this.isLoggedIn = true;
        this.currentUser = { 
            email: userDetails.email
        }

        queryString.removeAndUpdateUrl(REDIRECT_FROM_LOGIN_PARAM_NAME);
    }

    @action
    private initiateHubListener = () => {
        Hub.listen('auth', data => {
            const { payload } = data;
            if (payload.event === 'signIn' && !this.isLoggedIn) {
                this.logIn();
            }

            if (payload.event === 'signOut' && this.isLoggedIn) {
                this.logOut();
            }
        });
    }
}

export default AuthenticationStore;
