import useAnalyticsReportProxy from './useAnalyticsReportProxy';

const usePremadeAnalyticsReportProxy = () => {
    const analyticsReportOpenUrlProxy = useAnalyticsReportProxy('OpenURL');
    const analyticsReportResetInputProxy = useAnalyticsReportProxy('RevertChanges');
    const analyticsReportCopyIdProxy = useAnalyticsReportProxy('CopyId');

    return {
        analyticsReportOpenUrlProxy,
        analyticsReportResetInputProxy,
        analyticsReportCopyIdProxy
    }
}

export default usePremadeAnalyticsReportProxy;