import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { styled, css } from '../../../../common-styles';
import MobileIndicatorIcon from '../../../../svg-icons/MobileIndicatorIcon';
import DesktopIndicatorIcon from '../../../../svg-icons/DesktopIndicatorIcon';
import useTheme from '../../../../hooks/useTheme';
import useTextMapping from '../../../../hooks/useTextMapping';

const StyledListItem = styled.li<{ isSelected: boolean }>`
    cursor: pointer;
    display: flex;
    width: 100%;
    padding: 14px;
    border-radius: 10px;
    
    &:not(:last-child) {
        margin-bottom: 8px;
    }
    
    ${({ isSelected }) => isSelected && css`
        background-color: rgba(130, 35, 210, .1);
    `}

    ${({ isSelected }) => !isSelected && css`
         &:hover {
            background-color: ${({ theme }) => theme.colors.lightgrayEditArea};

            .icon-container  {
                transform: translate3d(0, 0, 0) rotate(16deg);
            }
         }
    `}
`;

const DetailsContainer = styled.div`
    margin-left: 16px;
`;

const IconContainer = styled.div`
    margin-top: 3px;
    will-change: transform;
    transform: translate3d(0, 0, 0) rotate(0);
    transition: transform .4s;
`;

const Title = styled.h3`
    font-size: 1.6rem;
    margin-bottom: 5px;
`;

const Subtitle = styled.h4`
    font-size: 1rem;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.colors.gray};
`;

export type AppKeySelectItemProps = {
    id: number;
    appKey: string;
    displayName: string;
    hostType: string;
    environment: string;
}

type AppKeySelectItemInnerProps = {
    onClick?: (value: number) => void;
    isSelected: boolean;
} & AppKeySelectItemProps

const AppKeySelectItem: FunctionComponent<AppKeySelectItemInnerProps> = ({
    id,
    displayName,
    appKey,
    environment,
    hostType,
    isSelected,
    onClick
}) => {
    const { colors } = useTheme();
    const IconComponent = useMemo(() => hostType === 'mobile' ? MobileIndicatorIcon : DesktopIndicatorIcon, [hostType]);
    const environmentText = useTextMapping('appKeyEnvironment', environment);
    const titleText = useMemo(() => `${displayName} [${environmentText}]`, [displayName, environmentText]);
    const handleItemClick = useCallback((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.stopPropagation();
        onClick?.(id);
    }, [onClick, id]);

    return (
        <StyledListItem isSelected={isSelected} onClick={handleItemClick}>
            <IconContainer className="icon-container">
                <IconComponent fillColor={colors.appBlack} />
            </IconContainer>
            <DetailsContainer>
                <Title>{titleText}</Title>
                <Subtitle>{appKey}</Subtitle>
            </DetailsContainer>
        </StyledListItem>
    )
}

export default AppKeySelectItem;