import PropertyModel from './PropertyModel';

export default class ArrayPropertyModel<T> extends PropertyModel<Array<T>> {
    constructor() {
        super({
            value: []
        })
    }

    add(value: T) {
        if (this.value.includes(value)) return;
        this.setValue([...this.value, value]);
    }

    remove(value: T) {
        this.setValue(this.value.filter(val => val !== value));
    }

    removeByProp(prop: string, value: any) {
        this.setValue(this.value.filter(val => val[prop] !== value));
    }
}