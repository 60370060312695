import { action, computed, observable } from "mobx";
import { PropertyState, Predicate } from '../AppDataStore';

type PropertyModelProps<T> = {
    backendKey?: string;
    value: T;
    validations?: Predicate[];
    readonly?: boolean;
}

export default class PropertyModel<T> {
    @observable public value: T;
    @observable private originValue: T;
    @observable private wasSaved: boolean = false;
    backendKey?: string;
    validations: Predicate[] = [];
    readonly: boolean = false;

    constructor({ backendKey, value, validations = [], readonly }: PropertyModelProps<T>) {
        this.backendKey = backendKey;
        this.value = value;
        this.originValue = value;
        this.validations = validations;
        this.readonly = !!readonly;
    }

    @computed
    get state(): PropertyState {
        if (this.readonly) return 'idle';

        if (this.validations?.some(validation => !validation(this.value))) return 'invalid';

        if (this.value !== this.originValue) return 'changed';

        if (this.wasSaved) return 'saved';

        return 'idle';
    }

    @action
    purgeValue = () => {
        if (this.readonly) return;
        this.originValue = this.value;
        this.wasSaved = true;
    }

    @action
    public setValue = (val: T) => {
        if (this.readonly) return;
        this.value = val;
        this.wasSaved = false;
    }

    @action
    getUpdatePath: any = () => {
        return this.value;
    }

    @action
    reset = () => {
        this.value = this.originValue;
    }
}
