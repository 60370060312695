export const amplifyConfiguration = {
    Auth: {
        identityPoolId: "us-east-2:2c690af9-be78-42a9-9d59-818dcf874153",
        region: "us-east-2",
        userPoolId: "us-east-2_QUPe4jzWE",
        userPoolWebClientId: "1gen226tm7l65093o8h823lepu",
        oauth: {
            domain: "ae-sdk-backoffice.auth.us-east-2.amazoncognito.com",
            redirectSignIn: `${globals.REACT_APP_BASE_URL}/?authenticated=true`,
            redirectSignOut: `${globals.REACT_APP_BASE_URL}`,
            responseType: "token",
            scope: ["email", "profile", "openid", "openid", "aws.cognito.signin.user.admin"],
            identityProvider: "Asurion.com"
        }
    }
};
