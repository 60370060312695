import { styled, keyframes, css } from "../common-styles";
import React from "react";
import { truncate } from '../utils';
const urlIcon = require('../resources/url-icon.svg');

interface IValueStringProps {
  value: string;
};

export const Title = styled.div`
  padding-left: 1.6rem;
  grid-column-start: 1;
  grid-column-end: 2;
  font-family: Apercu Pro;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 125%;
  justify-content: left;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #a6aaaf;
  padding: 10px 16px;

  &:nth-last-child(2) {
    border-width: 0 1px 0 0;
  }
`;

export const Value = styled.div`
  min-height: 4rem;
  padding-left: 1.6rem;
  grid-column-start: 2;
  grid-column-end: 3;
  font-family: Apercu Pro;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.11rem;
  color: #333f48;
  justify-content: left;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #a6aaaf;
  padding: 10px 16px;

  &:last-child {
    border-width: 0;
  }
`;

export const PropertyTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 700;
  align-self: center;
`;

export const PropertySubtitle = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  min-width: 19rem;
  height: 4rem;
  font-weight: normal;
  display: flex;
  align-items: center;
`;

export const PropertyValue = styled(PropertySubtitle)`
  font-size: 1.6rem;
  font-family: 'Apercu Mono Pro';
`;

export const GridBasicValue = styled.div`
  grid-column-start: 3;
  grid-column-end: 4;
`;

const UrlValueContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightgrayEditArea};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1px 12px 1px 12px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const UrlLink = styled.a`
   width: 32px;
   height: 32px;
`;


export const ColorBox = styled.span<{ color: string }>`
  width: 1rem;
  height: 1rem;
  display: inline-block;
  background-color: ${({ color }) => color};
  margin-right: 1rem;
`;

export const ColorValue = ({ value }: IValueStringProps) => {
  return (
    <Value>
      <ColorBox color={value} />
      {value}
    </Value>
  );
};


export const UrlValue = ({ value }: IValueStringProps) => {
  return (
    <UrlValueContainer title={value}>
      {truncate(value, 35)}
      <UrlLink href={value} target="_blank">
        <img src={urlIcon} />
      </UrlLink>
    </UrlValueContainer>
  );
};

export const GridSectionSeperator = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  height: 1px;
  background-color: #D5D6DA;
  margin: 10px 0 20px;
`;

export const HighlightedText = styled.span`
    background-color: ${({ theme }) => theme.colors.lightgreen};
    border-radius: 0.4rem;
    padding: 0.3rem;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.8;
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const FadeInContainer = styled.div`
  animation-name: ${fadeInAnimation};
  animation-duration: 1s;
`;

export const BlankOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

export const HighlightedButton = styled.button<{ isHollow?: boolean }>`
  cursor: pointer;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.purple};
  border-radius: 20px;
  width: 140px;
  height: 40px;
  font-size: 1.6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
      margin-right: 40px;
  }

  ${({ theme, isHollow }) => isHollow && css`
      color: ${theme.colors.purple};
      background-color: #fff;
      border: 1px solid ${theme.colors.purple};
  `}
`;
