import React, { FunctionComponent, useCallback } from 'react';
import { styled } from '../common-styles';

const LinkContainer = styled.a`
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    user-select: none;
    box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.03);
    
    &,
    &:hover,
    &:active {
        color: inherit;
        text-decoration: none;
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IconImage = styled.img`
    max-width: 2.5rem;
`;

const Text = styled.span`
    position: relative;
    z-index: 1;
    font-size: 1.6rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.appBlack};
    margin-left: 1.5rem;
    padding: 0 0.2rem;
    &:after {
        content: '';
        z-index: -1;
        height: 0.5rem;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.lightgreen};
        position: absolute;
        left: 0;
        bottom: 0.1rem;
        border-radius: 1rem;
    }
`;

type Props = {
    text: string;
    iconSrc: string;
    targetSrc?: string;
    className?: string;
    onClick?: VoidFunction;
};

const LinkButton: FunctionComponent<Props> = ({ text, iconSrc, targetSrc, className, onClick }) => {
    const handleClick = useCallback((event: React.MouseEvent) => {
        if (!targetSrc) {
            event.preventDefault();
        }

        onClick?.();
    }, [targetSrc, onClick]);

    return (
        <LinkContainer href={targetSrc} target='_blank' className={className} onClick={handleClick}>
            <Content>
                <IconImage src={iconSrc} />
                <Text>{text}</Text>
            </Content>
        </LinkContainer>
    );
}

export default LinkButton;
