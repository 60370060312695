import React, { FunctionComponent, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { MultipleSelectionItemProps } from './MultipleSelectionItem';
import MultipleSelectionList from './MultipleSelectionList';

type MultipleSelectionProps = {
    items: MultipleSelectionItemProps[];
    value: any;
    onChange: (value: any) => void;
}

const MultipleSelection: FunctionComponent<MultipleSelectionProps> = ({ items, value, onChange }) => {
    const handleItemClick = useCallback((value: string) => {
        onChange?.(value);
    }, [onChange]);

    return (
        <div>
            <MultipleSelectionList items={items} selectedValue={value} onItemClick={handleItemClick} />
        </div>
    )
}

export default observer(MultipleSelection);