import PropertyModel from './PropertyModel';
import { action } from 'mobx';

const isValidColor = (hex: string) => !hex || /^#([0-9A-F]{3}){1,2}$/i.test(hex);

export default class ColorPropertyModel extends PropertyModel<string> {
    constructor(backendKey: string, value: string) {
        super({
            backendKey,
            value,
            validations: [isValidColor]
        })
    }

    @action
    getUpdatePath = () => {
        return {
            colors: {
                primary: this.value
            }
        }
    }
}
