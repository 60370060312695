import React, { FunctionComponent } from 'react';
import { styled } from '../common-styles';

type InputErrorMessageProps = {
    message?: string;
    show?: boolean;
}

const StyledErrorText = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.warning};
    font-weight: 400;
    width: 100%;
    text-align: center;
`;

const InputErrorMessage: FunctionComponent<InputErrorMessageProps> = ({ show, message }) => {

    if (!show) return null;

    return (
            <StyledErrorText data-cy="input-error-message">{message}</StyledErrorText>
    )
}

export default InputErrorMessage;