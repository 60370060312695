import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { AppKeySelect } from '../../AppInputs/';
import { AppKeySelectItemProps } from '../../AppInputs/inputs/AppKeySelect/AppKeySelectItem';
import styled from 'styled-components';
import useGroupManagementStore from '../GroupManagementStore/useGroupManagementStore';
import { observer } from 'mobx-react-lite';

const Container = styled.div`
    width: 100%;
`;

type AppKeysDropdownProps = {
    onComplete?: VoidFunction;
}

const AppKeysDropdown: FunctionComponent<AppKeysDropdownProps> = ({ onComplete }) => {
    const { formData: { addedAppKeys }, availableAppKeys } = useGroupManagementStore();
    const dropdownAppKeys = useMemo(() =>
        availableAppKeys
            .map<AppKeySelectItemProps>(appKey => ({
                id: appKey.id,
                appKey: appKey.appKey,
                displayName: appKey.displayName,
                environment: appKey.environment,
                hostType: appKey.hostType
            })), [availableAppKeys]);


    const handleChange = useCallback((selectedAppKeyId: number) => {
        addedAppKeys.add(selectedAppKeyId);
        onComplete?.();
    }, [onComplete]);

    return (
        <Container>
            <AppKeySelect
                items={dropdownAppKeys}
                onChange={handleChange}
            />
        </Container>
    )
}

export default observer(AppKeysDropdown);