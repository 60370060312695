import React, { useCallback, ChangeEvent, FunctionComponent, useMemo } from 'react';
import { InnerInputProps, InputState } from '../types';
import { styled, css } from '../../../common-styles';
import TextAreaAutoSize from 'react-textarea-autosize';
import ResetButton from './ResetButton';

const Container = styled.div`
    width: 100%;
    position: relative;
`;

const overflowElipsisStyle = css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const hidePlaceholderOnFocusStyle = css`
    &:focus {
        &::placeholder {
            display: none;
            color: transparent;
        }
    }
`;

export const StyledInput = styled.input`
    appearance: none;
    border: unset;
    background:transparent;
    outline: none;

    width: 100%;
    height: 100%;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.appBlack};
    padding: 7px;
    flex: 1;
    caret-color: ${({ theme }) => theme.colors.purple};
    font-family: 'Apercu Mono Pro';

    ${overflowElipsisStyle} 
    ${hidePlaceholderOnFocusStyle}
`;

const InputWrapper = styled.div<{ isDisabled?: boolean }>`
    position: relative;

    height: 36px;
    width: 100%;
    border-radius: 4px;
    padding: 4px 6px;
    border: 1px solid #D5D6DA;
    background-color: ${({ theme }) => theme.colors.white};

    display: flex;
    align-items: center;

    ${({ theme, isDisabled }) => isDisabled && css`
      background-color: ${theme.colors.lightgrayEditArea};
      border-color: ${theme.colors.lightgrayEditArea};

      input, textarea {
          caret-color: transparent;
      }
    `}
`;

const TextAreaWrapper = styled(InputWrapper)`
    height: auto;
    min-height: 36px;
`;


const StyledTextArea = styled(TextAreaAutoSize)`
    border: unset;
    border-radius: 4px;
    padding: 0 7px;
    resize: none;
    width: 100%;
    height: auto;
    font-size: 1.6rem;
    font-family: 'Apercu Mono Pro';
    line-height: 1.4;
    max-height: 110px;
    outline: none;
    color: ${({ theme }) => theme.colors.appBlack};
    caret-color: ${({ theme }) => theme.colors.purple};
    ${hidePlaceholderOnFocusStyle}
`;

const HighlighterContainer = styled.div<{ state: InputState, isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px dashed transparent;

    ${({ theme, isDisabled }) => !isDisabled && css`
        &:hover, &:active {
            border-color: ${theme.colors.purple}; 
        }
    `}

    ${({ state, theme, isDisabled }) => state === 'invalid' && !isDisabled && css`
        &, &:hover, &:active {
            border-color: ${theme.colors.warning}; 
        }
    `}
`;

const ResetButtonContainer = styled.div`
    display: flex;
    align-items: center;

    position: absolute;
    top: 0;
    bottom: 0;
    right: -30px;
    margin: auto 0;
`;

const Input: FunctionComponent<InnerInputProps> = ({
    childrenDirection = 'right',
    state = 'idle',
    onChange,
    children,
    value,
    disabled,
    placeholder,
    multiline,
    onReset,
    enableReset = true
}) => {
    const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (disabled) return;
        onChange?.(event.target.value);
    }, [disabled]);

    const shouldShowResetButton = useMemo(() => state === 'changed' || state === 'saved', [state]);
    const shouldFinalizeResetButton = useMemo(() => shouldShowResetButton && state === 'saved', [shouldShowResetButton, state]);

    if (multiline) {
        return (
            <Container>
                <TextAreaWrapper>
                    {enableReset && <ResetButtonContainer>
                        <ResetButton onClick={onReset} hide={!shouldShowResetButton} finalize={shouldFinalizeResetButton} />
                    </ResetButtonContainer>
                    }
                    <HighlighterContainer state={state}>
                        <StyledTextArea onChange={handleInputChange} placeholder={placeholder} value={value} data-cy="multiline-input"/>
                    </HighlighterContainer>
                </TextAreaWrapper>
            </Container>
        )
    }

    return (
        <Container>
            <InputWrapper isDisabled={disabled}>
                {enableReset &&<ResetButtonContainer>
                    <ResetButton onClick={onReset} hide={!shouldShowResetButton} finalize={shouldFinalizeResetButton} />
                </ResetButtonContainer>
                }
                {childrenDirection === 'left' && children}
                <HighlighterContainer state={state} isDisabled={disabled}>
                    <StyledInput onChange={handleInputChange} value={value} placeholder={placeholder} data-cy="input"/>
                </HighlighterContainer>
                {childrenDirection === 'right' && children}
            </InputWrapper>
        </Container>
    )
}

export default Input;