import React, { FunctionComponent, useCallback } from 'react';
import { styled } from '../../../common-styles';
import RadioGroupOption, { RadioGroupOptionProps } from '../base/RadioGroupOption';

const StyledUl = styled.ul`
    display: flex;
    flex-direction: column;
`;

type RadioGroupProps = {
    selectedValue: string;
    options: RadioGroupOptionProps[];
    onChange?: (value: string) => void;
}


const RadioGroup: FunctionComponent<RadioGroupProps> = ({ options, selectedValue, onChange }) => {
    const handleClick = useCallback((value: string) => {
        onChange?.(value);
    }, []);

    return (
        <div>
            <StyledUl>
                {options.map((option, index) =>
                    <RadioGroupOption key={index} {...option} onClick={handleClick} isSelected={option.value === selectedValue} />
                )}
            </StyledUl>
        </div>
    )
}

export default RadioGroup;