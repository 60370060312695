import React, { FunctionComponent, useCallback } from 'react';
import { styled } from '../common-styles';
import { externalLinks } from '../consts';
import LinkButton from './LinkButton';
import { requireResources } from '../utils';
const [mailIcon, slackIcon, sdkShapeIcon] = requireResources(['mail-icon.png', 'slack-mark.png', 'sdk-shape.svg']);

const LinksContainer = styled.div`
    display: flex;
    align-items: center;

    a:not(:last-child) {
        margin-right: 18px;
    }
`;

const BoLinks: FunctionComponent = () => {
    const handleMessageUsClick = useCallback(() => {
        (window as any)?.AE_SDK?.triggerMessagingOverlay();
    }, []);

    return (
        <LinksContainer>
            <LinkButton text="Email us" iconSrc={mailIcon} targetSrc={externalLinks.mailTo} />
            <LinkButton text="Slack us" iconSrc={slackIcon} targetSrc={externalLinks.slack} />
            <LinkButton text="Message us" iconSrc={sdkShapeIcon} onClick={handleMessageUsClick} />
        </LinksContainer>
    )
}

export default BoLinks;