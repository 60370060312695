import { computed, action, observable } from 'mobx';
import { GroupDetails, UserDetails } from '../../types';
import { lazyObservable } from 'mobx-utils';
import { BackofficeClientStore } from '../BackofficeClientStore';

class UserStore {
    backofficeClientStore: BackofficeClientStore;
    @observable userDetails?: UserDetails | undefined;

    readonly _userGroupsLazyObservable = lazyObservable<GroupDetails[]>(async sink => {
        const groups = await this.backofficeClientStore.getUserGroups();
        sink(groups || [])
    }, [])

    constructor(backofficeClientStore: BackofficeClientStore) {
        this.backofficeClientStore = backofficeClientStore;
    }

    @action
    async getAndRefreshUserDetails(): Promise<UserDetails | undefined> {
        const userDetails = await this.backofficeClientStore.fetchUserData();

        this.userDetails = userDetails;

        return userDetails;
    }

    @action
    async refreshUserDetails(): Promise<void> {
        this.userDetails = await this.backofficeClientStore.fetchUserData();
    }

    @computed
    get userGroups(): GroupDetails[] {
        return this._userGroupsLazyObservable.current();
    }
}

export default UserStore;