import React, {
    FunctionComponent,
    useMemo,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { styled } from '../../common-styles';
import CreateAppKeyNameInput from './CreateAppKeyNameInput';
import {
    PropertyGrid,
    GridColumnOne,
    GridColumnTwo,
    GridColumnThree,
    GridColumn,
} from '../sections/common-styles';
import {
    PropertyTitle,
    GridSectionSeperator,
    PropertySubtitle,
    HighlightedText,
} from '../BasicElements';
import { MultipleSelection, Select, TextInput, UrlInput } from '../AppInputs';
import { MultipleSelectionItemProps } from '../AppInputs/inputs/MultipleSelection/MultipleSelectionItem';
import { observer } from 'mobx-react-lite';
import useCreateAppKeyStore from '../../hooks/useCreateAppKeyStore';
import { requireResources } from '../../utils';
import { SelectItemProps } from '../AppInputs/inputs/Select/SelectItem';
import CreateFormSaveButton from './CreateFormSaveButton';
import useUserStore from '../../hooks/useUserStore';
import { useAnalytics } from 'react-shisell';
import { GroupDetails } from '../../types';
import { AvailableQueue } from '../../stores/BackofficeClientStore/BackofficeClientStore';

const [phoneIcon, browserIcon] = requireResources(['phone.svg', 'browser.svg']);

const Container = styled.div`
    padding-top: 30px;
    padding-bottom: 400px;
`;

const DisclaimerText = styled.p`
    font-size: 1.6rem;
    line-height: 2.4rem;
`;

const FormContainer = styled.div`
    margin-top: 30px;
`;

const NameInputContainer = styled.div`
    display: flex;
    margin-bottom: 30px;
`;

const SaveButtonGrid = styled(GridColumn)`
    justify-content: center;
`;

const InputContainer = styled.div`
    width: 330px;
`;

const hostTypeSelectionItems: MultipleSelectionItemProps[] = [
    {
        value: 'web',
        displayName: 'Web SDK',
        iconSrc: browserIcon,
    },
    {
        value: 'mobile',
        displayName: 'Mobile SDK',
        iconSrc: phoneIcon,
    },
];

const environmentSelectionItems: MultipleSelectionItemProps[] = [
    {
        value: 'production',
        displayName: 'Production',
    },
    {
        value: 'development',
        displayName: 'Development',
    },
];

const stringComparer = (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase());
const availableQueueComparer = (a: AvailableQueue, b: AvailableQueue) => stringComparer(a.queue, b.queue);
const groupDetailsComparer = (a: GroupDetails, b: GroupDetails) => stringComparer(a.name,b.name);

const getExpertiseDefaultSelectedValue = (
    availableExpertise: SelectItemProps[]
): string => {
    if (availableExpertise.length == 0) return '';

    const generalConversationExpertise = availableExpertise.filter(
        (x) => x.value == 'general-conversation'
    );
    return generalConversationExpertise.length == 1
        ? generalConversationExpertise[0].value
        : availableExpertise[0].value;
};

const getQueuesNames = (availableQueues: AvailableQueue[]): SelectItemProps[] =>
    availableQueues
        .sort(availableQueueComparer)
        .map((queue) => ({ value: queue.queue, displayName: queue.queue }));

const getExpertiseForQueue = (
    availableQueues: AvailableQueue[],
    queueName: string
): SelectItemProps[] =>
    availableQueues
        .filter((x) => x.queue === queueName)
        .flatMap((x) => x.expertise)
        .sort(stringComparer)
        .map((expertise) => ({ value: expertise, displayName: expertise }));

const CreateAppKeyForm: FunctionComponent = () => {
    const {
        formData: {
            hostType,
            hostUrl,
            expertise,
            partnerName,
            groupId,
            environment,
            newGroupName,
        },
        getAvailableQueues,
    } = useCreateAppKeyStore();
    const { userGroups, _userGroupsLazyObservable } = useUserStore();
    const [availableQueues, setAvailableQueues] = useState<AvailableQueue[]>(
        []
    );
    const analytics = useAnalytics();

    const queueOptions = useMemo(() => getQueuesNames(availableQueues), [
        availableQueues,
    ]);

    const expertiseOptions: SelectItemProps[] = useMemo(() => {
        const availableExpertise = getExpertiseForQueue(
            availableQueues,
            partnerName.value
        );

        expertise.setValue(
            getExpertiseDefaultSelectedValue(availableExpertise)
        );

        return availableExpertise;
    }, [availableQueues, partnerName.value]);

    const groupOptions: SelectItemProps[] = useMemo(
        () =>
            userGroups
                .sort(groupDetailsComparer)
                .map((group) => ({
                    displayName: group.name,
                    value: group.id,
                })),
        [userGroups]
    );

    const handleGroupChange = useCallback(
        (value: string, isCustomValue?: boolean) => {
            if (isCustomValue) {
                newGroupName.setValue(value);
                return;
            }

            newGroupName.setValue('');
            groupId.setValue(parseInt(value));
        },
        [groupId, newGroupName]
    );

    useEffect(() => {
        if (userGroups.length > 0) {
            _userGroupsLazyObservable.refresh();
        }
        analytics.dispatcher.withExtra('Page', 'Create').dispatch('View');
    }, []);

    useEffect(() => {
        async function asyncGetAvailableQueues() {
            setAvailableQueues(await getAvailableQueues());
        }
        asyncGetAvailableQueues();
    }, []);

    return (
        <Container data-cy='create-app-key-form'>
            <DisclaimerText>
                <HighlightedText>
                    First, let's deal with the essentials.
                </HighlightedText>
                <br />
                If you're not sure about any of the fields, let us know and
                we'll do our best to help.
            </DisclaimerText>
            <FormContainer>
                <NameInputContainer>
                    <CreateAppKeyNameInput />
                </NameInputContainer>
                <PropertyGrid>
                    <GridColumnOne>
                        <PropertyTitle>Type</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo data-cy='host-type-container'>
                        <MultipleSelection
                            items={hostTypeSelectionItems}
                            value={hostType.value}
                            onChange={hostType.setValue}
                        />
                    </GridColumnTwo>
                    <GridColumnOne>
                        <PropertyTitle>Environment</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo data-cy='environment-container'>
                        <MultipleSelection
                            items={environmentSelectionItems}
                            value={environment.value}
                            onChange={environment.setValue}
                        />
                    </GridColumnTwo>
                    <GridColumnOne>
                        <PropertyTitle>Description</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo>Website URL</GridColumnTwo>
                    <GridColumnThree data-cy='website-url'>
                        <UrlInput
                            placeholder='https://'
                            value={hostUrl.value}
                            state={hostUrl.state}
                            onChange={hostUrl.setValue}
                            enableReset={false}
                        />
                    </GridColumnThree>
                    <GridSectionSeperator />
                    <GridColumnOne>
                        <PropertyTitle>Routing info</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo>
                        <PropertySubtitle>Partner's Name</PropertySubtitle>
                    </GridColumnTwo>
                    <GridColumnThree>
                        <InputContainer data-cy='partner-container'>
                            <Select
                                items={queueOptions}
                                value={partnerName.value}
                                onChange={partnerName.setValue}
                                placeholder='Choose partner name'
                            />
                        </InputContainer>
                    </GridColumnThree>
                    <GridColumnTwo>
                        <PropertySubtitle>Queue/Pool Name</PropertySubtitle>
                    </GridColumnTwo>
                    <GridColumnThree>
                        <InputContainer data-cy='partner-name-container'>
                            <TextInput
                                value={partnerName.value}
                                disabled={true}
                            />
                        </InputContainer>
                    </GridColumnThree>
                    <GridColumnOne>
                        <PropertyTitle>Expert Skill</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo>
                        <PropertySubtitle>Expertise</PropertySubtitle>
                    </GridColumnTwo>
                    <GridColumnThree>
                        <InputContainer data-cy='expertise-container'>
                            <Select
                                items={expertiseOptions}
                                value={expertise.value}
                                onChange={expertise.setValue}
                                placeholder='Choose expertise'
                            />
                        </InputContainer>
                    </GridColumnThree>
                    <GridSectionSeperator />
                    <GridColumnOne>
                        <PropertyTitle>Permissions</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo>
                        <PropertySubtitle>Group</PropertySubtitle>
                    </GridColumnTwo>
                    <GridColumnThree>
                        <InputContainer data-cy='group-container'>
                            <Select
                                items={groupOptions}
                                value={newGroupName.value || groupId.value}
                                onChange={handleGroupChange}
                                placeholder='Choose group'
                                customValueOn={true}
                                customValueSettings={{
                                    addText: 'Create new group',
                                    submitText: 'Create group',
                                }}
                            />
                        </InputContainer>
                    </GridColumnThree>
                    <GridColumnOne />
                    <GridColumnOne />
                    <SaveButtonGrid start={1} end={4}>
                        <CreateFormSaveButton />
                    </SaveButtonGrid>
                </PropertyGrid>
            </FormContainer>
        </Container>
    );
};

export default observer(CreateAppKeyForm);
