import { css } from '../../common-styles';

export const menuItemStyle = css`
    display: flex;
    align-items: center;
    height: 80px;
    padding-right: 5px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.white};
    max-width: 100%;
    
    &:hover {
        font-weight: 600;
    }

    &:not(:last-child) {
        border-bottom: 1px solid #d5d6Da;
    }

    &:hover:not(.selected) {
        .app-key-link-icon-wrapper  {
            transform: translate3d(0, 0, 0) rotate(-16deg);
        }
    }
`;