import React, { FunctionComponent, useCallback } from 'react';
import { styled, css } from '../../../../common-styles';

const StyledListItem = styled.li<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    min-width: 110px;
    height: 36px;

    background-color: #fff;

    ${({ theme, isSelected }) => isSelected && css`
        background-color: ${theme.colors.purple};
    `}

    padding: 10px 0;
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
    border-left: unset;

    &:first-child {
        border-left: 1px solid ${({ theme }) => theme.colors.borderGray};
        border-radius: 4px 0 0 4px;
    }

    &:last-child {
        border-radius: 0 4px 4px 0;
    }
`;

const Text = styled.span<{ isSelected: boolean }>`
    color: ${({ theme }) => theme.colors.gray};
    font-weight: 600;
    font-size: 1.4rem;

    ${({ isSelected }) => isSelected && css`
        color: #fff;
    `}
`;

const IconWrapper = styled.div<{ isSelected: boolean }>`
    display: flex;
    margin-right: 12px;
    ${({ isSelected }) => isSelected && css`
       img {
            filter: brightness(0) invert(1);
       }
    `}
`;

export type MultipleSelectionItemProps = {
    value: any;
    displayName?: string;
    iconSrc?: string;
}

type MultipleSelectionItemInnerProps = {
    onClick?: (value: any) => void;
    isSelected: boolean;
} & MultipleSelectionItemProps

const MultipleSelectionItem: FunctionComponent<MultipleSelectionItemInnerProps> = ({
    value,
    displayName,
    iconSrc,
    isSelected,
    onClick
}) => {
    const handleItemClick = useCallback(() => {
        onClick?.(value);
    }, [onClick]);

    return (
        <StyledListItem isSelected={isSelected} onClick={handleItemClick} data-cy='multiple-selection-item'>
            {iconSrc && (
                <IconWrapper isSelected={isSelected}>
                    <img src={iconSrc} />
                </IconWrapper>
            )}
            <Text isSelected={isSelected}>{displayName || value}</Text>
        </StyledListItem>
    )
}

export default MultipleSelectionItem;