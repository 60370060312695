import React, { FunctionComponent } from 'react';
import { styled } from '../../../../common-styles';
import SelectItem, { SelectItemProps } from './SelectItem';

const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    background-color: #fff;
    max-height: 250px;
    overflow-y: scroll;
    border-radius: 4px;
`;

const NoResultsLabel = styled.span`
    align-self: flex-start;
    color: ${({ theme }) => theme.colors.gray};
    opacity: .7;
`;

type SelectListProps = {
    items: SelectItemProps[];
    onItemClick: (value: any) => void;
    selectedValue: string;
}

const SelectList: FunctionComponent<SelectListProps> = ({ items, onItemClick, selectedValue }) => (
    <StyledList data-cy='select-list'>
        {items.length === 0 ? (
            <NoResultsLabel>No results</NoResultsLabel>
        ) : (
                items.map((item, index) =>
                    <SelectItem
                        {...item}
                        isSelected={item.value === selectedValue}
                        onClick={onItemClick}
                        key={index}
                    />
                )
            )}
    </StyledList>
)

export default SelectList;