import React, { FunctionComponent, useMemo } from 'react';
import { styled } from '../../../common-styles';
import OpenLockIcon from '../../../svg-icons/OpenLockIcon';
import ClosedLockIcon from '../../../svg-icons/ClosedLockIcon';
import useTheme from '../../../hooks/useTheme';

const LOCK_OPEN_TEXT = 'Lock';
const LOCK_CLOSED_TEXT = 'Unlock';
const LOCK_TIP_TEXT = 'If you’re not sure what this does, please talk to a development team 💜';

const StyledButton = styled.button`
    cursor: pointer;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.appBlack};

    display: flex;
    align-items: center;
    justify-content: center;


    &:hover {
        .lock-icon {
            &:after {
                content: '';
            }
        }
    }

    > span {
        width: 44px;
    }

    &:hover {
        > span {
            text-decoration: underline;
        }
    }
`;

const LockIconContainer = styled.div<{ isOpen?: boolean }>`
    display: flex;
    position: relative;

    &:after {
        background-color: ${({ theme }) => theme.colors.purple};
        opacity: .1;
        width: 30px;
        height: 30px;
        border-radius:50%;
        transform: translate3d(-50%, 0, 0);

        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;
        right: 0;
        margin: auto;
    }
`;

type LockProps = {
    isOpen?: boolean
    onClick?: VoidFunction;
}

const Lock: FunctionComponent<LockProps> = ({ isOpen, onClick }) => {
    const { colors } = useTheme();
    const text = useMemo(() => isOpen ? LOCK_OPEN_TEXT : LOCK_CLOSED_TEXT, [isOpen]);

    return (
        <StyledButton onClick={onClick}>
            <span>{text}</span>
            <LockIconContainer className="lock-icon" data-tip={LOCK_TIP_TEXT} data-for="lock">
                {isOpen ? <ClosedLockIcon fillColor={colors.appBlack} /> : <OpenLockIcon fillColor={colors.purple} />}
            </LockIconContainer>
        </StyledButton>
    )
}

export default Lock;