import React, { useMemo, useCallback, useState } from 'react';
import { styled, css } from '../../../common-styles';
import { requireResource } from '../../../utils';
import GroupFilterMarkedText from '../GroupFilterMarkedText';
import useUserManagementStore from '../../../hooks/useUserManagementStore';
import { observer } from 'mobx-react-lite';
import useTextMapping from '../../../hooks/useTextMapping';
import useGroupManagementStore from '../GroupManagementStore/useGroupManagementStore';
import MobileIndicatorIcon from '../../../svg-icons/MobileIndicatorIcon';
import DesktopIndicatorIcon from '../../../svg-icons/DesktopIndicatorIcon';
import useTheme from '../../../hooks/useTheme';
const resetIcon = requireResource('remove-button.svg');

const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    height: 80px;
    padding: 0 16px;

    &:not(:last-child) {
        > div {
            border-bottom: 1px solid #d5d6da;
        }
    }
`;

const ContentContainer = styled.div<{ isRemoved: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 57px;


    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        height: 1px;
        width: 0px;
        background-color: ${({ theme }) => theme.colors.purple};
        transition: width .4s;
    }

    ${({ isRemoved }) => isRemoved && css`
        &:after {
            width: 90%;
        }
    `}
`;

const DetailsContainer = styled.div<{ isRemoved: boolean }>`
    display: flex;
    align-items: center;

    transition: opacity .4s;

    ${({ isRemoved }) => isRemoved && css`
        opacity: 0;
    `}
`;

const HostTypeIconContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 15px;
    margin-right: 16px;
`;

const ResetButton = styled.button`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 24px;
    margin: auto 0;
    display: flex;
    cursor: pointer;
`;

const AppKeyName = styled.div`
    line-height: 25px;
`;

const AppKey = styled.div`
    font-size: 10px;
`;

interface Props {
    appKeyId: number;
}


const AddedAppKeyItem: React.FunctionComponent<Props> = ({ appKeyId }) => {
    const [isRemoved, setIsRemoved] = useState(false);
    const { formData: { addedAppKeys } } = useGroupManagementStore();
    const theme = useTheme();
    const { appKeys } = useUserManagementStore();
    const appKeyDetails = useMemo(() => appKeys.find(({ id }) => appKeyId === id)!, [appKeys, appKeyId]);
    const HostTypeIconComponent = useMemo(() =>
        appKeyDetails.hostType === 'mobile' ? MobileIndicatorIcon : DesktopIndicatorIcon, [appKeyDetails.hostType]);
    const environmentDisplayText = useTextMapping('appKeyEnvironment', appKeyDetails.environment);
    const handleResetButtonClick = useCallback(() => {
        setIsRemoved(true);
    }, [setIsRemoved]);

    const handleItemContainerTransitionEnd = useCallback(() => {
        if (!isRemoved) return;

        addedAppKeys.remove(appKeyId);
    }, [isRemoved, addedAppKeys, appKeyId]);

    return (
        <ItemContainer onTransitionEnd={handleItemContainerTransitionEnd}>
            <ContentContainer isRemoved={isRemoved}>
                <DetailsContainer isRemoved={isRemoved}>
                    <HostTypeIconContainer className='host-type-icon-container'>
                        <HostTypeIconComponent fillColor={theme.colors.appBlack} />
                    </HostTypeIconContainer>
                    <div>
                        <AppKeyName>
                            <GroupFilterMarkedText text={appKeyDetails.displayName} />{' '}
                            <span>[{environmentDisplayText}]</span>
                        </AppKeyName>
                        <AppKey>
                            <GroupFilterMarkedText text={appKeyDetails.appKey} />
                        </AppKey>
                    </div>
                </DetailsContainer>
            </ContentContainer>
            {!isRemoved && (
                <ResetButton onClick={handleResetButtonClick}>
                    <img src={resetIcon} />
                </ResetButton>
            )}
        </ItemContainer>
    );
}

export default observer(AddedAppKeyItem);