import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { styled, css } from '../../common-styles';
import AppKeyLink from './AppKeyLink';
import FloatingLine from '../FloatingLine';
import useAppDataStore from '../../hooks/useAppDataStore';
import { observer } from 'mobx-react-lite';
import { requireResource } from '../../utils';
import { FadeInContainer } from '../BasicElements';
import { useAnalytics } from 'react-shisell';
import CreateAppKeyItem from './CreateAppKeyItem';
import useCreateAppKeyStore from '../../hooks/useCreateAppKeyStore';
import useUserStore from '../../hooks/useUserStore';

const listPlaceholderImage = requireResource('list-empty-state-placeholder.svg')

const SIDE_MENU_WIDTH = '330px';

const SideMenuContainer = styled.div<{ faded?: boolean; }>`
    position: sticky;
    width: ${SIDE_MENU_WIDTH};
    z-index: 1;
    top: ${({ theme }) => theme.sizes.headerHeight};
    overflow-y: auto;
    height: ${({ theme }) => `calc(100vh - ${theme.sizes.headerHeight})`};

    border-right: 1px solid #d5d6Da;

    transition: opacity 1s;
    ${props => props.faded && css`
        opacity: .2;
    `}
`;

const PlaceholderContainer = styled.div`
    height: 100%;
    background-image:url(${listPlaceholderImage});
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
`;

const AppKeyLinksContainer = styled(FadeInContainer)`
    position: relative;
`;

const SideMenu: FunctionComponent = () => {
    const { sideBarAppkeys, selectedAppKey, selectAppKey, initialized } = useAppDataStore();
    const { disableForm: disableCreateAppKeyForm, canExitCreateAppKeyForm } = useCreateAppKeyStore();
    const { userDetails } = useUserStore();
    const selectedAppKeyIndex = useMemo(() => sideBarAppkeys.findIndex(item => item.appKey === selectedAppKey), [sideBarAppkeys, selectedAppKey])
    const analytics = useAnalytics();

    const handleAppKeySelection = useCallback(async (appKey: string) => {
        if (appKey === selectedAppKey) return;

        const canExitCreateForm = await canExitCreateAppKeyForm();
        if (!canExitCreateForm) return;
       
        disableCreateAppKeyForm();
        await selectAppKey(appKey);
        window.scrollTo(0, 0);

        const selectedAppKeyDetails = sideBarAppkeys.find(item => item.appKey === appKey);

        analytics.dispatcher
            .withExtra('ActionId', 'SideMenuAppKeyItem')
            .withExtra('AppKey', selectedAppKeyDetails?.appKey)
            .withExtra('FriendlyName', selectedAppKeyDetails?.name)
            .dispatch('Click')

    }, [selectedAppKey, sideBarAppkeys, selectAppKey, analytics, canExitCreateAppKeyForm, disableCreateAppKeyForm]);

    const appKeyLinks = useMemo(() =>
        sideBarAppkeys.map((appKeyItem) => (
            <AppKeyLink
                key={appKeyItem.appKey}
                friendlyName={appKeyItem.name}
                appKey={appKeyItem.appKey}
                isSelected={selectedAppKey === appKeyItem.appKey}
                onSelectAppKey={handleAppKeySelection}
                hostType={appKeyItem.hostType}
                environment={appKeyItem.environment}
            />
        )), [sideBarAppkeys, selectedAppKey, handleAppKeySelection])

    return (
        <SideMenuContainer faded={sideBarAppkeys.length === 0 && initialized}>
            {initialized && userDetails?.role === 'admin' && <CreateAppKeyItem />}
            {
                sideBarAppkeys.length === 0 ?
                    (
                        <PlaceholderContainer />
                    ) :
                    (
                        <AppKeyLinksContainer>
                            <FloatingLine currentIndex={selectedAppKeyIndex} />
                            {appKeyLinks}
                        </AppKeyLinksContainer>
                    )
            }
        </SideMenuContainer >
    );
}

export default observer(SideMenu);