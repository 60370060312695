import { observable, action, reaction } from "mobx";

class ConfirmationPopupMessageStore {
    @observable public shouldShowPopup = false;
    @observable private isConfirmed: boolean | undefined;

    @action
    showPopup = async (): Promise<boolean> => {
        this.shouldShowPopup = true;
        this.isConfirmed = undefined;

        return new Promise((resolve, _) => {
            const disposer = reaction(() => this.isConfirmed, triggeredValue => {
                disposer();
                resolve(triggeredValue);
            })
        });
    };

    @action
    hidePopup = async (isConfirmed: boolean) => {
        this.isConfirmed = isConfirmed;
        this.shouldShowPopup = false;
    };
}

export default ConfirmationPopupMessageStore;
