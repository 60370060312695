import React, { FunctionComponent } from 'react';
import { styled } from '../../../common-styles';
import { requireResource } from '../../../utils';
const checkMarkIcon = requireResource('checkmark.svg');
import classNames from 'classnames';


const Container = styled.div`
    display: flex;
    align-items: center;

    span {
        margin-left: 20px;
    }
`;

const StyledButton = styled.button`
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
    border-radius: 4px;
    width: 16px;
    height: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

type ResetButtonProps = {
    onClick?: VoidFunction;
    checked?: boolean;
    text?: string;
}

const Checkbox: FunctionComponent<ResetButtonProps> = ({ onClick, text, checked }) => (
    <Container>
        <StyledButton onClick={onClick} data-cy="checkbox" className = {classNames({"checked": checked })}> 
            {checked && (
                <img src={checkMarkIcon} />
            )}
        </StyledButton>
        {text && (
            <span>{text}</span>
        )}
    </Container>
);

export default Checkbox;