import React, { FunctionComponent, useCallback, useState, useRef, useMemo } from 'react';
import { styled } from '../../../../common-styles';
import useClickOutsideElement from '../../../../hooks/useClickOutsideElement';
import { AppKeySelectItemProps } from './AppKeySelectItem';
import SelectBox from '../Select/SelectBox';
import AppKeySelectList from './AppKeySelectList';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SelectListContainer = styled.div`
    position: absolute;
    top: 62px;
    left: 0;
    min-width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
    border-radius: 4px;
    background-color: #fff;
    z-index: 1;
`;

const StyledSelectBox = styled(SelectBox)`
    height: 60px;
`;

export type AppKeySelectProps = {
    items: AppKeySelectItemProps[];
    selectedAppKeyId?: number;
    onChange?: (appKeyId: number, isCustomValue?: boolean) => void;
    placeholder?: string;
    customValueSettings?: {
        addText: string;
        submitText: string;
    }
    customValueOn?: boolean;
}

const AppKeySelect: FunctionComponent<AppKeySelectProps> = ({ items, selectedAppKeyId, onChange, placeholder }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const filteredItems = useMemo(() => {
        const _searchTerm = searchTerm.toLowerCase().trim();
        if (_searchTerm.length === 0) return items;

        return items.filter(item =>
            item.displayName.toLowerCase().includes(_searchTerm) ||
            item.appKey.toLowerCase().includes(_searchTerm));
    }, [searchTerm, items]);

    const currentItem = useMemo(() => items.find(item => item.id === selectedAppKeyId), [items, selectedAppKeyId]);

    const handleItemClick = useCallback((appKeyId: number) => {
        onChange?.(appKeyId);
        setIsDropdownOpen(false);
    }, [onChange, setIsDropdownOpen]);

    const handleSelectBoxClick = useCallback(() => {
        setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen, setIsDropdownOpen]);

    const handleOutsideClick = useCallback(() => {
        setIsDropdownOpen(false);
    }, [setIsDropdownOpen]);

    const handleSearchTermChange = useCallback((value: string) => {
        setSearchTerm(value);
    }, [setSearchTerm]);

    useClickOutsideElement(containerRef, handleOutsideClick);

    return (
        <Container ref={containerRef}>
            <StyledSelectBox
                currentValue={currentItem?.displayName}
                isDropdownOpen={isDropdownOpen}
                onClick={handleSelectBoxClick}
                placeholder={placeholder}
                onSearchTermChange={handleSearchTermChange}
            />
            {isDropdownOpen && (
                <SelectListContainer>
                    <AppKeySelectList onItemClick={handleItemClick} selectedValue={selectedAppKeyId} items={filteredItems} />
                </SelectListContainer>
            )}
        </Container>
    )
}

export default AppKeySelect;