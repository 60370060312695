import React, { FunctionComponent, useCallback, useState, useEffect } from 'react';
import { InputProps } from '../types';
import Input from '../base/Input';
import { requireResource } from '../../../utils';
import { styled, keyframes } from '../../../common-styles';
const copyToClipboardIcon = requireResource('copy-string-icon.svg');

const showToastAnimation = keyframes`
    0%, 100% {
        opacity: 0;
        visibility: hidden;
        transform: translate3d(0, -30px, 0);
    }

    30%, 70% {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, -38px, 0);
    }
`;

const CopyToClipboardButton = styled.button`
    cursor: pointer;
    display: flex;
`;

const copyTextToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)
}
const ToastMessageContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px 13px;
    background-color: ${({ theme }) => theme.colors.appBlack};
    border-radius: 4px;

    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -30px, 0);

    animation-name: ${showToastAnimation};
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
`;

const ToastMessageText = styled.span`
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.4rem;
`;

type CopyTextInputProps = {
    toastText?: string;
    onCopy?: VoidFunction;
} & InputProps;

const CopyTextInput: FunctionComponent<CopyTextInputProps> = (props) => {
    const { value, onCopy, toastText = 'Text copied!' } = props;

    const [showToast, setShowToast] = useState(false);

    const handleCopyButtonClick = useCallback(async () => {
        if (!value) return;
        setShowToast(false);
        await copyTextToClipboard(value);
        setShowToast(true);

        onCopy?.();
    }, [value, onCopy]);

    const handleToastAnimationEnd = useCallback(() => {
        setShowToast(false);
    }, [setShowToast]);

    return (
        <Input {...props} childrenDirection="right">
            {showToast && (
                <ToastMessageContainer onAnimationEnd={handleToastAnimationEnd}>
                    <ToastMessageText>{toastText}</ToastMessageText>
                </ToastMessageContainer>
            )}
            <CopyToClipboardButton onClick={handleCopyButtonClick}>
                <img src={copyToClipboardIcon} />
            </CopyToClipboardButton>
        </Input>
    );
}

export default CopyTextInput;