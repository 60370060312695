import PropertyModel from './PropertyModel';
import { action } from 'mobx';

export default class BooleanPropertyModel extends PropertyModel<boolean> {
    constructor(backendKey: string, value: boolean) {
        super({
            backendKey,
            value
        })
        this.toggleValue = this.toggleValue.bind(this);
    }

    @action
    toggleValue = () => {
        this.value = !this.value;
    }
}