import React, { FunctionComponent } from 'react';
import { styled, css } from "../../common-styles";
import { GridColumn } from './common-styles';
import Snackbar from '../Snackbar';
import useSnackbarStore from '../../hooks/useSnackbarStore';
import { observer } from 'mobx-react-lite';

const TOP_OFFSET = '10px';

const StyledColumn = styled(GridColumn) <{ show: boolean }>`
    margin-top: ${TOP_OFFSET};
    position: sticky;
    top: ${({ theme }) => `calc(${theme.sizes.headerHeight} + ${TOP_OFFSET})`};
    left: 0;
    width: 100%;
    z-index: 100;
    margin: 0;
    transition: visibility 1s;
    visibility: ${({ show }) => show ? 'visible' : 'hidden'};
`;

const SnackbarSection: FunctionComponent = () => {
    const { isShown } = useSnackbarStore();

    return (
        <StyledColumn start={1} end={4} show={isShown}>
            <Snackbar />
        </StyledColumn>
    )
}

export default observer(SnackbarSection);