import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { styled } from '../common-styles';
import { requireResource } from '../utils';
import useAuthenticationStore from '../hooks/useAuthenticationStore';
import { observer } from 'mobx-react-lite';
import useAppDataStore from '../hooks/useAppDataStore';
import { useAnalytics } from 'react-shisell';
import { externalLinks } from '../consts';
import useUserStore from '../hooks/useUserStore';
import { DesktopHeader, HeaderLink, GroupedLinks } from '@npmsoluto/ae-hub-components-header';
const sdkLogo = requireResource('sdk-logo.svg');

const HEADER_HEIGHT_IN_PX = 80;

const UserInfoContainer = styled.div`
    margin-left: auto;
    color: ${({ theme }) => theme.colors.gray};
`;

const LogoutButton = styled.button`
    cursor: pointer;
    outline: none;
    color: inherit;
    text-decoration: underline;
`;

interface Props {
    isLoginOpen?: boolean;
}

const backofficeAdminLinksGroup: GroupedLinks = {
    title: "Backoffice",
    testId: "dropdown-navigation-button",
    links: [{
        displayName: "AppKeys List",
        testId: "dropdown-navigation-link",
        href: "/"
    },
    {
        displayName: 'Permission Management',
        testId: "dropdown-navigation-link",
        href: '/admin/userManagement'
    }]
}

const docsLink: HeaderLink = {
    displayName: "Documentation",
    href: externalLinks.docs,
    target: "_blank"
}

const HeaderContainer = styled.div`
    padding-top: ${HEADER_HEIGHT_IN_PX}px;
`;

const StyledDesktopHeader = styled(DesktopHeader)`
    height: ${HEADER_HEIGHT_IN_PX}px;
`;

const Heading: FunctionComponent<Props> = () => {
    const { logOut, isLoggedIn, currentUser } = useAuthenticationStore();
    const { appKeyDetails } = useAppDataStore();
    const { userDetails } = useUserStore();
    const analytics = useAnalytics();

    const handleLogout = useCallback(() => {
        analytics.dispatcher
            .withExtra('ActionId', 'Logout')
            .withExtra('AppKey', appKeyDetails?.appKey?.value)
            .withExtra('FriendlyName', appKeyDetails?.displayName?.value)
            .dispatch('Click')

        logOut?.();
    }, [analytics, logOut, appKeyDetails]);

    const links = useMemo(() =>
        userDetails?.role === 'admin' ? [backofficeAdminLinksGroup, docsLink] : [docsLink]
        , [userDetails]);

    return (
        <HeaderContainer>
            <StyledDesktopHeader logoSrc={sdkLogo} title="Anywhere Expert SDK" links={links} logoClickUrl="https://sdk.anywhere.expert/">
                {isLoggedIn && (
                    <UserInfoContainer>
                        <span>
                            {currentUser?.email}
                        </span>&nbsp;
                        (<LogoutButton onClick={handleLogout}>Log out</LogoutButton>)
                    </UserInfoContainer>
                )}
            </StyledDesktopHeader>
        </HeaderContainer>

    );
};

export default observer(Heading);
