import React, { FunctionComponent } from 'react';
import { GridColumnOne, GridColumnTwo, GridColumnThree, PropertyTitle, PropertySubtitle } from './common-styles';
import { styled } from '../../common-styles';
import { ColorInput, UrlInput } from '../AppInputs';
import SdkTextBoxMock from '../../svg-icons/SdkTextBoxMock';
import useTheme from '../../hooks/useTheme';
import useAppDataStore from '../../hooks/useAppDataStore';
import { observer } from 'mobx-react-lite';
import { GridSectionSeperator } from '../BasicElements';
import InputErrorMessageColumn from '../InputErrorMessageColumn';
import usePremadeAnalyticsReportProxy from '../../hooks/usePremadeAnalyticsReportProxy';

const AutoHeightGridColumnThree = styled(GridColumnThree)`
    height: initial;
`;

const ThemeSection: FunctionComponent = () => {
    const { appKeyDetails } = useAppDataStore();
    const theme = useTheme();
    const { analyticsReportOpenUrlProxy, analyticsReportResetInputProxy } = usePremadeAnalyticsReportProxy();

    if (!appKeyDetails) return null;

    const { color, fabioIconUrl } = appKeyDetails;

    return (
        <>
            <GridColumnOne>
                <PropertyTitle>Theme</PropertyTitle>
            </GridColumnOne>
            <GridColumnTwo>
                <PropertySubtitle>Color</PropertySubtitle>
            </GridColumnTwo>
            <GridColumnThree>
                <ColorInput onChange={color.setValue} value={color.value} state={color.state} onReset={analyticsReportResetInputProxy(color.reset)} />
            </GridColumnThree>
            <InputErrorMessageColumn show={color.state === 'invalid'} columnStart={3} columnEnd={4} />
            <AutoHeightGridColumnThree fromStart>
                <SdkTextBoxMock fillColor={(color.state !== 'invalid' && color.value) ? color.value : theme.colors.lightgray} />
            </AutoHeightGridColumnThree>
            <GridColumnTwo>
                <PropertySubtitle>FAB Icon URL</PropertySubtitle>
            </GridColumnTwo>
            <GridColumnThree data-cy="fab-icon-url">
                <UrlInput
                    value={fabioIconUrl.value}
                    state={fabioIconUrl.state}
                    onChange={fabioIconUrl.setValue}
                    onReset={analyticsReportResetInputProxy(fabioIconUrl.reset)}
                    onNavigationClick={analyticsReportOpenUrlProxy()}
                />
            </GridColumnThree>
            <GridSectionSeperator />
        </>
    );
}

export default observer(ThemeSection);