import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { styled } from '../../common-styles';
import DesktopIndicatorIcon from '../../svg-icons/DesktopIndicatorIcon';
import MobileIndicatorIcon from '../../svg-icons/MobileIndicatorIcon';
import useTheme from '../../hooks/useTheme';
import classNames from 'classnames';
import { menuItemStyle } from './common';

type AppKeyLinkProps = {
    friendlyName: string;
    appKey: string;
    isSelected: boolean;
    onSelectAppKey?: (appKey: string) => void;
    hostType: 'web' | 'mobile';
    environment: 'production' | 'development';
    testId?: string;
};

const AppKeyLinkContainer = styled.div`
    ${menuItemStyle}
`;

const Title = styled.h3<{ isSelected: boolean }>`
    font-size: 1.6rem;
    font-weight: ${({ isSelected }) => (isSelected ? '700' : 'inherit')};
    color: ${({ isSelected, theme }) =>
        isSelected ? theme.colors.purple : theme.colors.appBlack};

    transition: color .4s;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const AppKey = styled.h4`
    font-size: 1rem;
    line-height: 2;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.colors.gray};
    font-weight: normal;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const AppKeyLinkWrapper = styled.div`
    flex-direction: column;
    display: flex;
    overflow: hidden;
    width: 100%;
`;

const IconWrapper = styled.div`
    margin: 23px 20px auto;
    will-change: transform;
    transform: translate3d(0, 0, 0) rotate(0);
    transition: transform .4s;
    width: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const appKeyTypeToIconMap = {
    'web': DesktopIndicatorIcon,
    'mobile': MobileIndicatorIcon
}

const getEnvironmentSuffix = (environment: 'production' | 'development'): string => {
    if (environment === 'production') return ' [Prod]';
    if (environment === 'development') return ' [Dev]';

    return '';
}

const AppKeyLink: FunctionComponent<AppKeyLinkProps> = ({
    friendlyName,
    appKey,
    isSelected,
    onSelectAppKey,
    hostType,
    environment,
    testId
}) => {
    const theme = useTheme();
    const IconComponent = useMemo(() => appKeyTypeToIconMap[hostType], [hostType]);
    const iconFillColor = useMemo(() => isSelected ? theme.colors.purple : theme.colors.appBlack, [isSelected, theme]);
    const environmentSuffix = useMemo(() => getEnvironmentSuffix(environment), [environment]);

    const handleContainerClick = useCallback(() => {
        onSelectAppKey?.(appKey);
    }, [appKey, onSelectAppKey]);

    return (
        <AppKeyLinkContainer
            key={appKey}
            onClick={handleContainerClick}
            className={classNames(
                { 'selected': isSelected }
            )}
            data-cy={testId || 'side-menu-appkey-link'}
        >
            <IconWrapper className={classNames(
                'app-key-link-icon-wrapper',
                { 'selected': isSelected }
            )}>
                <IconComponent fillColor={iconFillColor} />
            </IconWrapper>
            <AppKeyLinkWrapper>
                <Title isSelected={isSelected} data-cy='side-menu-appkey-name'>{friendlyName}{environmentSuffix}</Title>
                <AppKey data-cy='side-menu-appkey'>{appKey}</AppKey>
            </AppKeyLinkWrapper>
        </AppKeyLinkContainer>
    );
}

export default AppKeyLink;
