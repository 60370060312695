import React, { FunctionComponent, useCallback } from 'react';
import SideMenuItem from './SideMenuItem';
import { requireResource } from '../../utils';
import { observer } from 'mobx-react-lite';
import useCreateAppKeyStore from '../../hooks/useCreateAppKeyStore';
import AppKeyLink from './AppKeyLink';

const plusIcon = requireResource('plus.png');

const CreateAppKeyItem: FunctionComponent = () => {
    const { activateForm, sideMenuItemTitle, isFormActive, formData: { hostType, environment } } = useCreateAppKeyStore();

    const handleCreateNewAppKeyItemClick = useCallback(() => {
        activateForm();
    }, [activateForm])

    return (
        <>
            {isFormActive ? (
                <AppKeyLink
                    appKey="N/A"
                    friendlyName={sideMenuItemTitle}
                    isSelected={true}
                    hostType={hostType.value}
                    environment={environment.value}
                    testId='create-app-key-link-item'
                />
            ) : (
                    <SideMenuItem
                        text={sideMenuItemTitle}
                        iconSrc={plusIcon}
                        onClick={handleCreateNewAppKeyItemClick}
                        testId='create-app-key-side-menu-item'
                    />
                )}
        </>
    )
}

export default observer(CreateAppKeyItem);