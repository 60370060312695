import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '../../common-styles';
import {
    PropertyGrid,
    GridColumnOne,
    GridColumnTwo,
    GridColumnThree,
    TypeValueText,
    GridColumn
} from '../sections/common-styles';
import {
    HighlightedText,
    PropertyTitle,
    PropertySubtitle,
    PropertyValue,
    GridSectionSeperator,
    HighlightedButton
} from '../BasicElements';
import useCreateAppKeyStore from '../../hooks/useCreateAppKeyStore';
import DesktopIndicatorIcon from '../../svg-icons/DesktopIndicatorIcon';
import MobileIndicatorIcon from '../../svg-icons/MobileIndicatorIcon';
import useTheme from '../../hooks/useTheme';
import useUserStore from '../../hooks/useUserStore';
import { useAnalytics } from 'react-shisell';
import Popup from '../Popup';

const Title = styled.h2`
    font-size: 3.2rem;
    font-weight: 700;
`;

const FormButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const TruncateText = styled.span`
    width: 310px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const MOBILE_HOST_TEXT = 'Mobile SDK';
const WEB_HOST_TEXT = 'Web SDK';

const PRODUCTION_ENV_TEXT = 'Production';
const DEVELOPMENT_ENV_TEXT = 'Development';

const CreateFormReviewPopup: FunctionComponent = () => {
    const {
        formData: {
            appKeyName,
            expertise,
            hostType,
            partnerName,
            groupId,
            environment,
            newGroupName,
            hostUrl
        },
        hideFormReview,
        submitForm
    } = useCreateAppKeyStore();
    const { userGroups } = useUserStore();
    const analytics = useAnalytics();

    const groupName = useMemo(() => {
        if (newGroupName.value?.length > 0) return newGroupName.value;

        return userGroups.find(group => group.id === groupId.value)?.name;
    }, [userGroups, newGroupName, groupId]);

    const { colors } = useTheme();
    const HostTypeIconComponent = useMemo(
        () =>
            hostType.value === 'mobile'
                ? MobileIndicatorIcon
                : DesktopIndicatorIcon,
        [hostType.value]
    );
    const hostTypeText = useMemo(
        () => (hostType.value === 'mobile' ? MOBILE_HOST_TEXT : WEB_HOST_TEXT),
        [hostType.value]
    );
    const environmentText = useMemo(
        () =>
            environment.value === 'production'
                ? PRODUCTION_ENV_TEXT
                : DEVELOPMENT_ENV_TEXT,
        [environment.value]
    );

    const webSiteUrl = useMemo(
        () =>  hostUrl.value ? hostUrl.value : 'https://',
        [hostUrl.value]
    );

    const handleSubmit = useCallback(async () => {
        analytics.dispatcher
            .withExtra('ActionId', 'CreateAppKeySubmitButton')
            .dispatch('Click');

        hideFormReview();

        const createdAppKey = await submitForm();

        if (createdAppKey && createdAppKey.length > 0) {
            analytics.dispatcher
                .withExtra('AppKey', createdAppKey)
                .withExtra('FriendlyName', appKeyName.value)
                .withExtra('HostType', hostTypeText)
                .withExtra('Partner', partnerName.value)
                .withExtra('Pool', partnerName.value)
                .withExtra('Expertise', expertise.value)
                .withExtra('Group', groupName)
                .withExtra('WebSiteUrl',hostUrl.value)
                .dispatch('AppKeyCreated');
        }
    }, [hideFormReview, submitForm, analytics, appKeyName, hostTypeText, environmentText, partnerName, hostUrl, expertise, groupName]);

    return (
        <Popup onClose={hideFormReview}>
            <div data-cy='create-app-key-form-review'>
                <HighlightedText>
                    Please review the properties before creating this key.
                    <br />
                    Future editing of these will only be possible by the AE SDK
                    team.
                </HighlightedText>
                <PropertyGrid>
                    <GridColumnOne />
                    <GridColumn start={1} end={4}>
                        <Title>{appKeyName.value}</Title>
                    </GridColumn>
                    <GridColumnOne>
                        <PropertyTitle>Type</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo>
                        <HostTypeIconComponent fillColor={colors.appBlack} />
                        <TypeValueText data-cy='review-host-type'>
                            {hostTypeText}
                        </TypeValueText>
                    </GridColumnTwo>
                    <GridColumnOne>
                        <PropertyTitle>Environment</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo>
                        <PropertySubtitle data-cy='review-environment'>{environmentText}</PropertySubtitle>
                    </GridColumnTwo>
                    <GridColumnOne>
                        <PropertyTitle>Description</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo>
                        <PropertySubtitle>Website URL</PropertySubtitle>
                    </GridColumnTwo>
                    <GridColumnThree>
                        <PropertyValue data-cy='review-website-url'>
                            <TruncateText>{webSiteUrl}</TruncateText>
                        </PropertyValue>
                    </GridColumnThree>
                    <GridSectionSeperator />
                    <GridColumnOne>
                        <PropertyTitle>Routing Info</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo>
                        <PropertySubtitle>Partner's Name</PropertySubtitle>
                    </GridColumnTwo>
                    <GridColumnThree>
                        <PropertyValue data-cy='review-partner'>{partnerName.value}</PropertyValue>
                    </GridColumnThree>
                    <GridColumnTwo>
                        <PropertySubtitle>Queue/Pool Name</PropertySubtitle>
                    </GridColumnTwo>
                    <GridColumnThree>
                        <PropertyValue data-cy='review-pool'>{partnerName.value}</PropertyValue>
                    </GridColumnThree>
                    <GridColumnOne>
                        <PropertyTitle>Expert Skill</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo>
                        <PropertySubtitle>Expertise</PropertySubtitle>
                    </GridColumnTwo>
                    <GridColumnThree>
                        <PropertyValue data-cy='review-expertise'>{expertise.value}</PropertyValue>
                    </GridColumnThree>
                    <GridSectionSeperator />
                    <GridColumnOne>
                        <PropertyTitle>Permissions</PropertyTitle>
                    </GridColumnOne>
                    <GridColumnTwo>
                        <PropertySubtitle>Group</PropertySubtitle>
                    </GridColumnTwo>
                    <GridColumnThree>
                        <PropertyValue data-cy='review-group'>{groupName}</PropertyValue>
                    </GridColumnThree>
                    <GridColumnOne />
                    <GridColumn start={1} end={4}>
                        <FormButtonsContainer>
                            <HighlightedButton onClick={handleSubmit}>
                                👌 Create key
                        </HighlightedButton>
                            <HighlightedButton data-cy="back-to-form-button" isHollow onClick={hideFormReview}>
                                Back to form
                        </HighlightedButton>
                        </FormButtonsContainer>
                    </GridColumn>
                </PropertyGrid>
            </div>
        </Popup>
    );
};

export default observer(CreateFormReviewPopup);
