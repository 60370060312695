import { ArrayPropertyModel } from '../../../stores/models';
import { computed, action, observable } from 'mobx';
import { UserManagementStore } from '../../../stores';
import { NewUser, AddedUser, UpsertGroupRequest, GroupMember, AddedMember } from '../../../types';
import { BackofficeClientStore } from '../../../stores/BackofficeClientStore';
import PropertyModel from '../../../stores/models/PropertyModel';

type GroupManagementFormProperties = {
    newGroupName: PropertyModel<string | undefined>;
    addedAppKeys: ArrayPropertyModel<number>;
    removedAppKeys: ArrayPropertyModel<number>;
    addedMembers: ArrayPropertyModel<AddedMember>;
    removedUsers: ArrayPropertyModel<number>;
}

type GroupManagementMode = 'edit' | 'create';

class GroupManagementStore {
    groupId?: number;
    @observable formData: GroupManagementFormProperties;
    userManagementStore: UserManagementStore;
    backofficeClientStore: BackofficeClientStore;
    @observable isSubmitting = false;
    @observable addMemberErrorMessage = '';

    constructor(backofficeClientStore: BackofficeClientStore, userManagementStore: UserManagementStore, groupId?: number) {
        this.groupId = groupId;
        this.formData = this.getFormDefaultValue();
        this.backofficeClientStore = backofficeClientStore;
        this.userManagementStore = userManagementStore;
    }

    
    @action
    setAddMemberErrorMessage =(value: string) => {
        this.addMemberErrorMessage = value;
    }

    @action
    reset = () => {
        this.formData = this.getFormDefaultValue();
    }

    private getFormDefaultValue = (): GroupManagementFormProperties => ({
        newGroupName: new PropertyModel<string | undefined>({
            value: undefined
        }),
        addedAppKeys: new ArrayPropertyModel<number>(),
        removedAppKeys: new ArrayPropertyModel<number>(),
        addedMembers: new ArrayPropertyModel<AddedMember>(),
        removedUsers: new ArrayPropertyModel<number>()
    })

    @action
    private getPartialSubmissionPayload = (): UpsertGroupRequest => {

        const addedUsers: AddedUser[] = this.formData.addedMembers.value.filter(({ type }) => type === 'added').map(user => ({ 'id': user.id!, 'groupRole': user.groupRole }));
        const newUsers: NewUser[] = this.formData.addedMembers.value.filter(({ type }) => type === 'new').map(user => ({ 'email': user.email!, 'groupRole': user.groupRole }));


        return {
            addedAppKeys: this.formData.addedAppKeys.value,
            addedUsers: addedUsers,
            newUsers: newUsers,
            removedAppKeys: this.formData.removedAppKeys.value,
            removedUsers: this.formData.removedUsers.value,
        }
    }

    @action
    private async creationSubmission() {
        if (!this.formData.newGroupName.value) {
            throw 'Must enter new group name';
        }

        const request: UpsertGroupRequest = {
            newGroupName: this.formData.newGroupName.value,
            ...this.getPartialSubmissionPayload()
        }

        await this.backofficeClientStore.upsertManagementGroup(request)
    }

    @action
    private async updateSubmission() {
        if (!this.groupId) {
            throw 'Invalid group id';
        }

        const request: UpsertGroupRequest = {
            groupId: this.groupId,
            ...this.getPartialSubmissionPayload()
        }

        await this.backofficeClientStore.upsertManagementGroup(request)
    }

    @action
    submit = async (): Promise<{ success: boolean }> => {
        this.isSubmitting = true;
        try {
            if (this.mode === 'create') {
                await this.creationSubmission();
            } else if (this.mode === 'edit') {
                await this.updateSubmission();
            }

            await this.userManagementStore.refreshWithoutLoading();
            this.reset();
            this.isSubmitting = false;
            return {
                success: true
            }
        } catch (e) {
            alert('Something went wrong, please try again or contact us.');

            this.isSubmitting = false;
            return {
                success: false
            }
        }
    }

    @computed
    get doesNewGroupNameAlreadyExist(): boolean {
        if (!this.formData.newGroupName.value) return false;

        const _groupName = this.formData.newGroupName.value.trim().toLowerCase();
        const groupAlreadyExists = this.userManagementStore.groups.some(group => group.name.trim().toLowerCase() === _groupName);

        return groupAlreadyExists;
    }

    @computed
    get didFormChange(): boolean {
        const { newGroupName, removedUsers, addedAppKeys, addedMembers, removedAppKeys } = this.formData;

        if (newGroupName.value && newGroupName.value.length > 0) return true;
        if (removedUsers.value.length > 0) return true;
        if (addedMembers.value.length > 0) return true;
        if (addedAppKeys.value.length > 0) return true;
        if (removedAppKeys.value.length > 0) return true;

        return false;
    }

    @computed
    get isFormValid(): boolean {
        if (!this.didFormChange) return false;
        if (this.addMemberErrorMessage.length > 0) return false;

        if (this.mode === 'create') {
            if (!this.formData.newGroupName.value) {
                return false;
            }

            const _groupName = this.formData.newGroupName.value.trim().toLowerCase();

            if (_groupName.length < 2) {
                return false;
            }

            if (this.doesNewGroupNameAlreadyExist) {
                return false;
            }
        }

        return true;
    }

    @computed
    get mode(): GroupManagementMode {
        return typeof this.groupId !== 'undefined' ? 'edit' : 'create';
    }

    @computed
    get groupDetails() {
        return this.userManagementStore.groups.find(group => group.id === this.groupId);
    }

    @computed
    get groupAppKeys() {
        return this.userManagementStore.appKeys
            .filter(({ id }) => this.groupDetails?.appKeys.includes(id))
            .sort((a, b) => a.displayName > b.displayName ? 1 : (b.displayName > a.displayName ? -1 : 0));
    }

    @computed
    get adminId() {
        return this.userManagementStore?.userDetails?.id;
    }

    @computed
    get groupUsers() {
        const adminMember: GroupMember[] = this.userManagementStore.userDetails
            ? [
                {
                    id: this.userManagementStore.userDetails.id,
                    email: this.userManagementStore.userDetails.email,
                    role: 'admin',
                    groupRole: 'admin'
                }
            ]
            : [];
        if (!!this.groupId) {
            return adminMember.concat(
                this.userManagementStore.users
                    .filter(user => user.id != this.userManagementStore.userDetails?.id)
                    .filter(
                        user => user.groups.filter(group => group.id === this.groupId).length > 0
                    )
                    .map<GroupMember>(user => ({
                        id: user.id,
                        email: user.email,
                        role: user.role,
                        groupRole: 'admin'
                    }))
            ).sort((a, b) => a.email > b.email ? 1 : (b.email > a.email ? -1 : 0));
        }

        return adminMember;
    }

    @computed
    get availableAppKeys() {
        return this.userManagementStore.appKeys.filter(({ id }) => !this.groupDetails?.appKeys.includes(id) && !this.formData.addedAppKeys.value.includes(id));
    }

    @computed
    get availableUsers() {
        return this.userManagementStore.users.filter(user => !user.groups.some(group => group.id == this.groupDetails?.id))
            .filter(user => user.id !== this.userManagementStore.userDetails?.id)
            .filter(user => !this.formData.addedMembers.value.some(addedMember => addedMember.id == user.id))
    }
}

export default GroupManagementStore;