import React, { FunctionComponent } from 'react';
import { styled } from '../../../../../common-styles';
import { requireResource } from '../../../../../utils';
const plusIcon = requireResource('plus.png');

const Container = styled.div`
    cursor: pointer;
    height: 50px;
    padding: 0 16px;
    display: flex;
    align-items: center;
`;

const Text = styled.span`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.appBlack};
`;

const IconContainer = styled.div`
    display: flex;
    margin-right: 16px;
`;

type CustomSelectionButtonProps = {
    onClick?: VoidFunction;
    text: string;
}

const CustomSelectionButton: FunctionComponent<CustomSelectionButtonProps> = ({ onClick, text }) => (
    <Container onClick={onClick} data-cy="custom-select-button">
        <IconContainer>
            <img src={plusIcon} alt='plus icon' />
        </IconContainer>
        <Text>{text}</Text>
    </Container>
)

export default CustomSelectionButton;