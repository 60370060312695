import React, { FunctionComponent } from "react";
import { observer } from 'mobx-react-lite';
import EditAppKeyForm from './EditAppKeyForm';

import useCreateAppKeyStore from '../hooks/useCreateAppKeyStore';
import CreateAppKeyForm from './CreateAppKeyForm';

const AppKeyDetails: FunctionComponent = () => {
  const createAppKeyStore = useCreateAppKeyStore();

  if (createAppKeyStore.isFormActive) {
    return (
      <CreateAppKeyForm />
    )
  }

  return (
    <EditAppKeyForm />
  )
}


export default observer(AppKeyDetails);
