import React, { ComponentType, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {
    createGlobalStyle,
    ThemeProvider,
    variables,
    assets,
    css
} from './common-styles';
import { Theme } from './theme';
import { MainPage, UserManagement } from './pages';
import { AuthenticationStore, AuthenticationStoreProvider, UserStore, UserStoreProvider } from './stores';
import TweekProvider from './providers/TweekProvider';
import { BackofficeClientStore, BackofficeClientStoreProvider } from './stores/BackofficeClientStore';
import { observer } from 'mobx-react-lite';
import initiateSdk from './utils/initiateSdk';

const { colors, sizes } = variables;
const theme: Theme = {
    colors,
    sizes,
    assets
};

const reactTooltipStyle = css`
    .__react_component_tooltip.type-dark {
        max-width: 190px;
        text-align: left;
        line-height: 1.25;
        border-radius: 4px;
        color: ${({ theme }) => theme.colors.white} !important;
        background-color: ${({ theme }) => theme.colors.appBlack} !important;

        &:after {
            border-top-color: ${({ theme }) => theme.colors.appBlack} !important;
            border-top-width: 8px !important;
        }

        &.place-top {
            &:after {
                bottom: -5px !important;
            }
        }
    }
`;

const GlobalStyle = createGlobalStyle`
    body {
        line-height: 1.25;
        font-size: 1.6rem;
        overflow-y: scroll;
        color: ${({ theme }) => theme.colors.appBlack};
    }

    mark {
        background-color: ${({ theme }) => theme.colors.lightgreen};
        padding: 2px 0;
    }

    ${reactTooltipStyle}
`;

const App: ComponentType = () => {
    const authenticationStore = useMemo(() => new AuthenticationStore, []);
    const backofficeClientStore = useMemo(() => new BackofficeClientStore(authenticationStore), [authenticationStore])
    const userStore = useMemo(() => new UserStore(backofficeClientStore), [backofficeClientStore]);

    useEffect(() => {
        authenticationStore.initialize();
    }, [authenticationStore]);

    useEffect(() => {
        if (!authenticationStore.isLoggedIn) return;

        initiateSdk();
    }, [authenticationStore.isLoggedIn])

    useEffect(() => {
        if (!authenticationStore.isLoggedIn) return;

        userStore.refreshUserDetails();
    }, [userStore, authenticationStore.isLoggedIn]);

    return (
        <TweekProvider>
            <ThemeProvider theme={theme}>
                <AuthenticationStoreProvider value={authenticationStore}>
                    <BackofficeClientStoreProvider value={backofficeClientStore}>
                        <UserStoreProvider value={userStore}>
                            <Router>
                                <GlobalStyle />
                                <Route path="/" exact={true} component={MainPage} />
                                <Route path="/admin/userManagement" exact={true} component={UserManagement} />
                            </Router>
                        </UserStoreProvider>
                    </BackofficeClientStoreProvider>
                </AuthenticationStoreProvider>
            </ThemeProvider>
        </TweekProvider>
    );
}

export default observer(App);
