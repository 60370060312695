import userGroupRoleMapping from '../mappings/userGroupRoleMap.json';
import appKeyEnvironmentMap from '../mappings/appKeyEnvironmentMap.json';

export type Mapping = 'userGroupRole' | 'appKeyEnvironment';

const mappingTypeToMapObject: { [key in Mapping]: object; } = {
    'userGroupRole': userGroupRoleMapping,
    'appKeyEnvironment': appKeyEnvironmentMap
}

export default (mappingType: Mapping, value: string) => {
    const mappingObject = mappingTypeToMapObject[mappingType];
    if (!mappingObject) return value;

    return mappingObject[value] || value;
}