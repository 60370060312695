import React from 'react';
import {
    CollapsibleGroupHeader,
    Props as CollapsibleGroupHeaderProps
} from './CollapsibleGroupHeader';
import { CreateNewGroupHeader } from './CreateNewGroupHeader';

interface Props extends CollapsibleGroupHeaderProps {
    isCreateNewGroup?: boolean;
}

const GroupHeader: React.FunctionComponent<Props> = ({
    isCreateNewGroup = false,
    isOpen,
    groupName,
    numberOfMembers
}) => (
    <>
        {isCreateNewGroup ? (
            <CreateNewGroupHeader groupName={groupName} />
        ) : (
            <CollapsibleGroupHeader
                isOpen={isOpen}
                groupName={groupName}
                numberOfMembers={numberOfMembers}
            />
        )}
    </>
);

export default GroupHeader;