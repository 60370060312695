import React, { FunctionComponent, useMemo } from 'react';
import { PropertyTitle, GridColumn, TypeValueText, GridColumnOne, GridColumnTwo, PropertySubtitle, ValueText, GridColumnThree } from './common-styles';
import useAppDataStore from '../../hooks/useAppDataStore';

import { GridSectionSeperator } from '../BasicElements';
import { UrlInput, CopyTextInput, MultilineInput } from '../AppInputs';
import { observer } from 'mobx-react-lite';
import MobileIndicatorIcon from '../../svg-icons/MobileIndicatorIcon';
import DesktopIndicatorIcon from '../../svg-icons/DesktopIndicatorIcon';
import useTheme from '../../hooks/useTheme';
import { styled } from '../../common-styles';
import usePremadeAnalyticsReportProxy from '../../hooks/usePremadeAnalyticsReportProxy';

const MOBILE_HOST_TEXT = 'Mobile SDK';
const WEB_HOST_TEXT = 'Web SDK';

const VerticalCenterContainer = styled.div`
    display: flex;
    align-items: center;
`;

const AppKeySection: FunctionComponent = () => {
    const { appKeyDetails } = useAppDataStore();
    const { analyticsReportOpenUrlProxy, analyticsReportCopyIdProxy, analyticsReportResetInputProxy } = usePremadeAnalyticsReportProxy();
    const { colors } = useTheme();

    const { appKey, hostUrl, notes, hostType } = appKeyDetails!;

    const IconComponent = useMemo(() => hostType.value === 'mobile' ? MobileIndicatorIcon : DesktopIndicatorIcon, [hostType.value]);
    const hostTypeText = useMemo(() => hostType.value === 'mobile' ? MOBILE_HOST_TEXT : WEB_HOST_TEXT, [hostType.value]);

    if (!appKeyDetails) return null;

    return (
        <>
            <GridColumnOne>
                <PropertyTitle>AppKey GUID</PropertyTitle>
            </GridColumnOne>
            <GridColumn start={2} end={4}>
                <CopyTextInput
                    value={appKey.value}
                    disabled={true}
                    toastText="AppKey copied to clipboard"
                    onCopy={analyticsReportCopyIdProxy()}
                />
            </GridColumn>
            <GridColumnOne>
                <PropertyTitle>Type</PropertyTitle>
            </GridColumnOne>
            <GridColumnTwo>
                <VerticalCenterContainer>
                    <IconComponent fillColor={colors.appBlack} />
                    <TypeValueText data-cy="host-type">{hostTypeText}</TypeValueText>
                </VerticalCenterContainer>
            </GridColumnTwo>
            <GridColumnOne>
                <PropertyTitle>Description</PropertyTitle>
            </GridColumnOne>
            <GridColumnTwo>
                <PropertySubtitle>Website URL</PropertySubtitle>
            </GridColumnTwo>
            <GridColumnThree>
            <UrlInput
                    placeholder="https://"
                    value={hostUrl.value}
                    state={hostUrl.state}
                    onChange={hostUrl.setValue}
                    onReset={analyticsReportResetInputProxy(hostUrl.reset)}
                    onNavigationClick={analyticsReportOpenUrlProxy()}
                />
            </GridColumnThree>
            <GridColumnTwo fromRelativeStart>
                <PropertySubtitle>Notes</PropertySubtitle>
            </GridColumnTwo>
            <GridColumnThree data-cy="notes">
                <MultilineInput
                    onChange={notes.setValue}
                    state={notes.state}
                    onReset={analyticsReportResetInputProxy(notes.reset)}
                    value={notes.value}
                    placeholder="Add notes (optional)"
                />
            </GridColumnThree>
            <GridSectionSeperator />
        </>
    )
}

export default observer(AppKeySection);