import React, { FunctionComponent } from 'react';
import { styled } from '../../../../common-styles';
import AppKeySelectItem, { AppKeySelectItemProps } from './AppKeySelectItem';

const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    background-color: #fff;
    max-height: 250px;
    overflow-y: scroll;
    border-radius: 4px;
`;

const NoResultsLabel = styled.span`
    align-self: flex-start;
    color: ${({ theme }) => theme.colors.gray};
    opacity: .7;
`;

type AppKeySelectListProps = {
    items: AppKeySelectItemProps[];
    onItemClick: (value: number) => void;
    selectedValue?: number;
}

const AppKeySelectList: FunctionComponent<AppKeySelectListProps> = ({ items, onItemClick, selectedValue }) => (
    <StyledList>
        {items.length === 0 ? (
            <NoResultsLabel>No results</NoResultsLabel>
        ) : (
                items.map((item, index) =>
                    <AppKeySelectItem
                        {...item}
                        isSelected={item.id === selectedValue}
                        onClick={onItemClick}
                        key={index}
                    />
                )
            )}
    </StyledList>
)

export default AppKeySelectList;