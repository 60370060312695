import React, { FunctionComponent } from 'react';
import { styled } from '../../common-styles';
import { requireResource } from '../../utils';

const desktopKingIcon = requireResource('desktop-king.svg');

const MAIN_TEXT = 'Backoffice is supported on desktop only.';

const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    width: 100vw;
    height: 100vh;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    padding: 141px 20px 0;
`;

const IconContainer = styled.div`
    margin-bottom: 36px;
`;

const Text = styled.h2`
    color: ${({ theme }) => theme.colors.appBlack};
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1;
`;

const MobileNotSupported: FunctionComponent = () => (
    <Container>
        <IconContainer>
            <img src={desktopKingIcon} alt="desktop king icon" draggable={false} />
        </IconContainer>
        <Text>{MAIN_TEXT}</Text>
    </Container>
)

export default MobileNotSupported;