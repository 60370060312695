import { useAnalytics } from 'react-shisell';
import useAppDataStore from './useAppDataStore';

type UseAnalyticsReportProxyReturnType = (callback?: VoidFunction) => VoidFunction;

const useAnalyticsReportProxy = (actionId: string): UseAnalyticsReportProxyReturnType => {
    const analytics = useAnalytics();
    const { appKeyDetails } = useAppDataStore();
    const { appKey, displayName } = appKeyDetails!;

    return (callback?: VoidFunction) => () => {
        analytics.dispatcher
            .withExtra('ActionId', actionId)
            .withExtra('AppKey', appKey?.value)
            .withExtra('FriendlyName', displayName?.value)
            .dispatch('Click');

        callback?.();
    };
}

export default useAnalyticsReportProxy;