import React, { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';

const TOOLTIP_SHOW_DELAY = 600;

type TooltipProps = {
    showFor?: string;
}

const Tooltip: FunctionComponent<TooltipProps> = ({ showFor }) => (
    <ReactTooltip place="top" type="dark" effect="solid" delayShow={TOOLTIP_SHOW_DELAY} id={showFor} />
)

export default Tooltip;