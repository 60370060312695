import React, { useState, useCallback, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { styled, css } from '../../common-styles';

const Container = styled.div`
    max-height: 300px;
    overflow-y: auto;
`;

const isTargetScrolledToBottom = (target: any) =>
    target.clientHeight + target.scrollTop === target.scrollHeight;
const isTargetScrolledToTop = (target: any) => target.scrollTop === 0;

const RelativeContainer = styled.div`
    position: relative;
`;

const GrayOverlay = css`
    background: linear-gradient(
        360deg,
        rgba(247, 247, 247) 0%,
        rgba(247, 247, 247, 0.4) 49%
    );
`;

const WhiteOverlay = css`
    background: linear-gradient(
        360deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.4) 49%
    );
`;

const BottomGradientOverlay = styled.div<{
    isVisible: boolean;
    background: any;
    height: string;
    width: string;
}>`
    opacity: ${props => (props.isVisible ? 1 : 0)};
    transition: opacity 400ms;
    pointer-events: none;
    ${props => props.background}

    position: absolute;
    top: ${props => `-${props.height}`};
    height: ${props => props.height};
    width: ${props => props.width};
`;

const TopGradientOverlay = styled(BottomGradientOverlay)`
    top: 0px;
    transform: rotate(180deg);
`;

export enum OverlayColors {
    GrayOverlay,
    WhiteOverlay
}

export type ScrollerRefHandler = {
    scrollToBottom: VoidFunction;
}

type ScrollerProps = {
    overlayColor: OverlayColors;
    overlay: { height: string; width: string };
}

type ScrollerComponentProps = React.PropsWithChildren<ScrollerProps>;

const Scroller: React.RefForwardingComponent<ScrollerRefHandler, ScrollerComponentProps> = ({
    children,
    overlayColor,
    overlay,
}, ref) => {
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    const [isScrolledToTop, setIsScrolledToTop] = useState(true);

    const scrollerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => ({
        scrollToBottom: () => {
            setTimeout(() => {
                if (!scrollerRef.current) return;
                scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight;
            }, 0);
        }
    }));

    const onAppKeyScroll = useCallback(
        target => {
            const isCurrentlyScrolledToBottom = isTargetScrolledToBottom(
                target
            );
            const isCurrentlyScrolledToTop = isTargetScrolledToTop(target);

            if (isScrolledToBottom != isCurrentlyScrolledToBottom) {
                setIsScrolledToBottom(isCurrentlyScrolledToBottom);
            }

            if (isScrolledToTop != isCurrentlyScrolledToTop) {
                setIsScrolledToTop(isCurrentlyScrolledToTop);
            }
        },
        [
            isScrolledToBottom,
            setIsScrolledToBottom,
            isScrolledToTop,
            setIsScrolledToTop
        ]
    );

    useEffect(() => {
        if (scrollerRef.current) {
            onAppKeyScroll(scrollerRef.current);
        }
    }, [scrollerRef, onAppKeyScroll]);
   
    const selectedOverlayColor =
        overlayColor === OverlayColors.GrayOverlay ? GrayOverlay : WhiteOverlay;

    return (
        <>
            <RelativeContainer>
                <TopGradientOverlay
                    isVisible={!isScrolledToTop}
                    background={selectedOverlayColor}
                    height={overlay.height}
                    width={overlay.width}
                />
            </RelativeContainer>
            <Container
                onScroll={event => onAppKeyScroll(event.currentTarget)}
                ref={scrollerRef}
            >
                {children}
            </Container>
            <RelativeContainer>
                <BottomGradientOverlay
                    isVisible={!isScrolledToBottom}
                    background={selectedOverlayColor}
                    height={overlay.height}
                    width={overlay.width}
                />
            </RelativeContainer>
        </>
    );
};

export default forwardRef<ScrollerRefHandler, ScrollerComponentProps>(Scroller);