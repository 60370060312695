import React, {
  FunctionComponent,
  useMemo,
  useCallback,
  useState,
  useEffect
} from 'react';
import {  GroupMember } from '../../../types';
import { styled, css } from '../../../common-styles';
import useGroupManagementStore from '../GroupManagementStore/useGroupManagementStore';
import { Select } from '../../AppInputs';
import { SelectItemProps } from '../../AppInputs/inputs/Select/SelectItem';
import textMapper from '../../../utils/textMapper';
import { observer } from 'mobx-react-lite';
import { NewUser, AddedUser, AddedMember } from '../../../types/GroupEntities';
import InputErrorMessage from '../../InputErrorMessage';

const NewUserErrorTypes = {
  userAlreadyInGroup: 'This user is already in this group.',
  notAnEmail: 'This is not an email address. Give it another try.',
  wrongEmailDomain:'This email address does not exist in the directory.',
  emailExistsInDropDownList: 'This user already listed. Pick it from the drop down.'
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorMessageContainer = styled.div`
    width: 362px;
    margin-top: 8px;
    margin-left: 55px;
`;

const UserSelectContainer = styled.div<{ isHidden?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 55px;
  margin-top: 4px;
  width: 362px;

  transition: opacity 300ms;
  height: 20px;
  ${props =>
    props.isHidden &&
    css`
      display: none;
    `}
`;

type UsersDropdownProps = {
  isHidden: boolean;
  onComplete: (success: boolean, user?: AddedMember) => void;
  setIsDropDownOpen: (dropDownOpen: boolean) => void;
  groupMembers: GroupMember[];
  getEmailForUserId:(userId: number)=> string;
  newUserEmail: string|undefined;
  setNewUserEmail:(value: string|undefined)=>void;
};

const UsersDropdown: FunctionComponent<UsersDropdownProps> = ({
  isHidden,
  onComplete,
  setIsDropDownOpen,
  groupMembers,
  getEmailForUserId,
  newUserEmail,
  setNewUserEmail
}) => {
  const { availableUsers, setAddMemberErrorMessage, addMemberErrorMessage,  formData: { addedMembers }} = useGroupManagementStore();

  const [userId, setUserId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (availableUsers?.length > 0) {
      setUserId(undefined);
      setNewUserEmail(undefined);
    }
  }, [availableUsers]);

 

  const emailWithDomainCheckPattern = useMemo(() => new RegExp(
    '^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(soluto|asurion).com$','i'
  ),[]);
  const emailPattern = useMemo(() => new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-]+$'),[]);

  const dropdownUsers = useMemo(() => {
    return availableUsers?.length > 0
      ? availableUsers.map<SelectItemProps>(user => ({
          value: user.id,
          displayName: user.email
        }))
      : [];
  }, [availableUsers?.length]);

  const validateNewUserEmail = useCallback(
    (email: string): string => {

      if (!emailPattern.test(email)) {
        return NewUserErrorTypes.notAnEmail;
      }
      if (!emailWithDomainCheckPattern.test(email)) {
        return NewUserErrorTypes.wrongEmailDomain;
      }
      if (
        availableUsers.some(
          user =>
            user.email.toLocaleLowerCase() === email.trim().toLocaleLowerCase()
        )
      ) {
        return NewUserErrorTypes.emailExistsInDropDownList;
      }
      if (
        groupMembers.some(
          member =>
            member.email.toLocaleLowerCase() === email.trim().toLocaleLowerCase()
        ) ||
        addedMembers.value.some(addedMember => addedMember.type === 'new' && addedMember.email?.toLocaleLowerCase() === email.trim().toLocaleLowerCase() ||
                                               addedMember.type === 'added' && getEmailForUserId(addedMember.id!)?.toLocaleLowerCase() === email.trim().toLocaleLowerCase())
        
      
      ) {
        return NewUserErrorTypes.userAlreadyInGroup;
      }
     
      setAddMemberErrorMessage('');
      return '';
    },
    [groupMembers, availableUsers, addedMembers.value]
  );

  const handleUserChange = useCallback(
    (value: string, isCustomValue?: boolean) => {
      if (isCustomValue) {
        const errorMessage = validateNewUserEmail(value);
        if (errorMessage.length > 0) {
          setAddMemberErrorMessage(errorMessage);
        }
        setNewUserEmail(value);
        if (errorMessage.length == 0) {
          onComplete(true, { email: value, groupRole: 'admin', type:'new' });
          return;
        }
        onComplete(false);
        return;
      }
      setAddMemberErrorMessage('');
      setNewUserEmail(undefined);
      setUserId(parseInt(value));
      onComplete(true, { id: parseInt(value), groupRole: 'admin', type:'added' });
    },
    [setUserId, setNewUserEmail, setAddMemberErrorMessage, onComplete]
  );

  return (
    <Container>
      <UserSelectContainer isHidden={isHidden}>
          <Select
            items={dropdownUsers}
            value={newUserEmail || userId}
            onChange={handleUserChange}
            placeholder="Enter new user's email"
            customValueOn={true}
            customValueSettings={{
              addText: 'Create new user',
              submitText: 'Create user'
            }}
            setIsDropDownOpen={setIsDropDownOpen}
          />
      </UserSelectContainer>
      <ErrorMessageContainer>
        <InputErrorMessage
          show={addMemberErrorMessage.length > 0}
          message={`☝${addMemberErrorMessage}`}
        />
      </ErrorMessageContainer>
    </Container>
  );
};

export default observer(UsersDropdown);