import React, { FunctionComponent, useMemo } from 'react';
import { styled } from "../../common-styles";
import { GridColumn } from './common-styles';
import useAppDataStore from '../../hooks/useAppDataStore';
import { observer } from 'mobx-react-lite';
import EditFormSaveButton from '../EditAppKeyForm/EditFormSaveButton';

const AppKeyFriendlyNameTitle = styled.div`
  font-size: 3.2rem;
  font-weight: 700;
`;

const AppKeyGridTitleWrapper = styled(GridColumn)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const getEnvironmentSuffix = (environment: 'production' | 'development'): string => {
  if (environment === 'production') return ' [Prod]';
  if (environment === 'development') return ' [Dev]';

  return '';
}

const HeadingSection: FunctionComponent = () => {
  const { appKeyDetails } = useAppDataStore();
  const { environment, displayName } = appKeyDetails!;

  const environmentSuffix = useMemo(() => getEnvironmentSuffix(environment?.value), [environment]);

  if (!appKeyDetails) return null;

  return (
    <AppKeyGridTitleWrapper start={1} end={4}>
      <AppKeyFriendlyNameTitle data-cy="app-key-friendly-name-title">
        {displayName.value}{environmentSuffix}
      </AppKeyFriendlyNameTitle>
      <EditFormSaveButton />
    </AppKeyGridTitleWrapper>
  )
}

export default observer(HeadingSection);