import PropertyModel from './PropertyModel';
import { Predicate } from '../AppDataStore';

const generateMinLengthValidation = (minLength: number) => (str: string) => str?.length >= minLength;
const generateMaxLengthValidation = (maxLength: number) => (str: string) => str?.length <= maxLength;

type StringValidationsProps = {
    mandatory?: boolean;
    maxLength?: number;
    minLength?: number;
}

const getValidations = (validationSettings?: StringValidationsProps): Predicate[] => {
    if (!validationSettings) return [];

    let validations: Predicate[] = [];

    if (validationSettings.mandatory)
        validations.push(generateMinLengthValidation(1));
    if (typeof validationSettings.minLength === 'number')
        validations.push(generateMinLengthValidation(validationSettings.minLength));
    if (typeof validationSettings.maxLength === 'number')
        validations.push(generateMaxLengthValidation(validationSettings.maxLength));

    return validations;
}

export default class StringPropertyModel extends PropertyModel<string> {
    constructor(backendKey: string, value: string, validationSettings?: StringValidationsProps) {
        super({
            backendKey,
            value: value || '',
            validations: getValidations(validationSettings)
        })
    }
}