import React from 'react';
import { styled } from '../../../../common-styles';
import { requireResource } from '../../../../utils';
const plusSign = requireResource('plus.png');

const GroupName = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 20px;
    cursor: pointer;
`;

const PlusSign = styled.img`
    margin-right: 16px;
`;

interface Props {
    groupName: string;
    onClick?: () => void;
    className?: string;
}

export const CreateNewGroupHeader: React.FunctionComponent<Props> = ({
    groupName,
    onClick,
    className
}) => (
        <HeaderContainer onClick={onClick} className={className} data-cy='management-group-create-header'>
            <GroupName data-cy='management-group-create-title'>
                <PlusSign src={plusSign} /> {groupName}
            </GroupName>
        </HeaderContainer>
    );
