import React, { FunctionComponent, useMemo, useCallback } from "react";
import { SaveButtonStateEnum } from "../../stores/AppDataStore";
import useAppDataStore from '../../hooks/useAppDataStore';
import { observer } from 'mobx-react-lite';
import useSnackbarStore from '../../hooks/useSnackbarStore';
import { useAnalytics } from 'react-shisell';
import SaveButton from '../SaveButton';

const buttonStateToTextMap = {
    [SaveButtonStateEnum.ACTIVE]: 'Save Changes',
    [SaveButtonStateEnum.DISABLE]: 'Save Changes',
    [SaveButtonStateEnum.SAVING]: 'Saving...'
}

const EditFormSaveButton: FunctionComponent = () => {
    const { showMessage: showSnackbarMessage } = useSnackbarStore();
    const { saveData, saveButtonState, appKeyDetails } = useAppDataStore();
    const buttonText = useMemo(() => buttonStateToTextMap[saveButtonState], [saveButtonState]);
    const analytics = useAnalytics();

    const handleButtonClick = useCallback(() => {
        if (saveButtonState !== SaveButtonStateEnum.ACTIVE || !appKeyDetails) return;

        const { smsLoginEnabled, tokenIssuer, tokenUniqueClaim, appKey, displayName } = appKeyDetails;
        
        if (!smsLoginEnabled.value && !tokenIssuer.value && !tokenUniqueClaim.value) {
            showSnackbarMessage('Some authentication details are missing.', '', () => { });
            return;
        }

        saveData();

        analytics.dispatcher
            .withExtra('ActionId', 'SaveChanges')
            .withExtra('AppKey', appKey?.value)
            .withExtra('FriendlyName', displayName?.value)
            .dispatch('Click');

    }, [saveButtonState, saveData, showSnackbarMessage, analytics, appKeyDetails])

    return <SaveButton text={buttonText} onClick={handleButtonClick} state={saveButtonState} />
}

export default observer(EditFormSaveButton);
