import { computed, action, observable, reaction } from 'mobx';
import { lazyObservable } from 'mobx-utils';
import { BackofficeClientStore } from '../BackofficeClientStore';
import { UserManagementData, UserManagementAppKey, UserManagementGroup, UserManagementUser } from './types';
import { GroupAppKey, GroupMember, GroupDetails, UpsertGroupRequest, UserDetails } from '../../types';

const DEFAULT_USER_MANAGEMENT_DATA: UserManagementData = {
    appKeys: [],
    groups: [],
    users: []
}

class UserManagementStore {
    @observable groupsSearchTerm = '';
    @observable managementData: UserManagementData = DEFAULT_USER_MANAGEMENT_DATA;
    backofficeClientStore: BackofficeClientStore;
    userDetails: UserDetails | undefined;

    private readonly _userManagementDataLazyObservable = lazyObservable<UserManagementData>(async sink => {
        const data = await this.backofficeClientStore.fetchManagementData();
        sink(data || DEFAULT_USER_MANAGEMENT_DATA)
    }, DEFAULT_USER_MANAGEMENT_DATA)

    constructor(
        backofficeClientStore: BackofficeClientStore,
        userDetails: UserDetails | undefined
    ) {
        this.backofficeClientStore = backofficeClientStore;
        this.userDetails = userDetails;
        reaction(() => this._userManagementDataLazyObservable.current(), () => {
            this.managementData = this._userManagementDataLazyObservable.current();
        })
    }

    @action
    refresh = () => {
        this._userManagementDataLazyObservable.refresh();
    }

    @action
    setGroupsSearchTerm = (query: string) => {
        this.groupsSearchTerm = query;
    }

    @action
    refreshWithoutLoading = async () => {
        const newData = await this.backofficeClientStore.fetchManagementData();
        this.managementData = newData || DEFAULT_USER_MANAGEMENT_DATA;
    }

    @computed
    get isSearchTermValid() {
        return this.groupsSearchTerm.length > 1;
    }

    @computed
    get isFetching(): boolean {
        return this._userManagementDataLazyObservable.pending;
    }

    @computed
    get appKeys(): UserManagementAppKey[] {
        return this.managementData.appKeys;
    }

    @computed
    get groups(): UserManagementGroup[] {
        return this.managementData.groups;
    }

    @computed
    get users(): UserManagementUser[] {
        return this.managementData.users;
    }

    @action
    private getGroupAppKeysDetails = (groupAppKeys: number[]): GroupAppKey[] =>
        groupAppKeys.map<GroupAppKey>(appKeyId => {
            const { id, appKey, displayName, environment, hostType } = this.managementData.appKeys.find((appKey) => appKey.id === appKeyId)!;
            return {
                id,
                appKey,
                environment,
                hostType,
                friendlyName: displayName,
            } as GroupAppKey
        })


    @action
    getGroupUsersDetails = (groupId?: number): GroupMember[] => {
        const adminMember: GroupMember[] = this.userDetails
            ? [
                {
                    id: this.userDetails.id,
                    email: this.userDetails.email,
                    role: 'admin',
                    groupRole: 'admin'
                }
            ]
            : [];
        if (!!groupId) {
            return adminMember.concat(
                this.managementData.users
                    .filter(user => user.id != this.userDetails?.id)
                    .filter(
                        user => user.groups.filter(group => group.id === groupId).length > 0
                    )
                    .map<GroupMember>(user => ({
                        id: user.id,
                        email: user.email,
                        role: user.role,
                        groupRole: 'admin'
                    }))
            );
        }

        return adminMember;
    };

    @action
    private mapGroupToGroupDetails = (group: UserManagementGroup) => ({
        id: group.id,
        name: group.name,
        members: this.getGroupUsersDetails(group.id),
        appKeys: this.getGroupAppKeysDetails(group.appKeys)
    });

    @action
    private getGroupFilterMethod = (
        searchTerm: string
    ): ((group: UserManagementGroup) => boolean) => {
        const query = searchTerm.toLowerCase();

        return (group: UserManagementGroup) => {
            if (group.name.toLowerCase().includes(query)) return true;

            const groupAppKeys = this.getGroupAppKeysDetails(group.appKeys);
            if (
                groupAppKeys.some(
                    appKeyDetails =>
                        appKeyDetails.appKey.includes(query) ||
                        appKeyDetails.friendlyName.toLowerCase().includes(query)
                )
            )
                return true;

            const groupUsers = this.getGroupUsersDetails(group.id);
            if (
                groupUsers.some(userDetails =>
                    userDetails.email.toLowerCase().startsWith(query)
                )
            )
                return true;

            return false;
        };
    };

    @computed
    get groupsDetails(): GroupDetails[] {
        if (this.isSearchTermValid) {
            return this.managementData.groups
                .filter(this.getGroupFilterMethod(this.groupsSearchTerm))
                .map<GroupDetails>(this.mapGroupToGroupDetails);
        }

        return this.managementData.groups.map<GroupDetails>(
            this.mapGroupToGroupDetails
        );
    }
}

export default UserManagementStore;