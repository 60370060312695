import React, { FunctionComponent, useMemo, useEffect } from 'react';
import MainPageView from './MainPageView';
import {
    AppDataStore,
    AppDataStoreProvider,
    SnackbarStore,
    SnackbarStoreProvider,
    CreateAppKeyStore,
    CreateAppKeyStoreProvider,
    ConfirmationPopupMessageStore,
    ConfirmationPopupMessageStoreProvider
} from '../../stores';
import useAuthenticationStore from '../../hooks/useAuthenticationStore';
import { observer } from 'mobx-react-lite';
import { useAnalytics } from 'react-shisell';
import useEnrichedAnalytics from '../../hooks/useEnrichedAnalytics';
import useBackofficeClientStore from '../../hooks/useBackofficeClientStore';
import useUserStore from '../../hooks/useUserStore';

const MainPage: FunctionComponent = () => {
    const userStore = useUserStore();
    const backofficeClientStore = useBackofficeClientStore();

    const analyticsExtraData = useMemo(() => {
        let extraData = {};

        if (typeof userStore.userDetails?.id !== 'undefined') {
            extraData['userId'] = userStore.userDetails?.id;
        }

        return extraData;
    }, [userStore.userDetails]);

    useEnrichedAnalytics(analyticsExtraData);

    const { isLoggedIn } = useAuthenticationStore();
    const snackbarStore = useMemo(() => new SnackbarStore(), []);
    const confirmationPopupMessageStore = useMemo(() => new ConfirmationPopupMessageStore(), []);

    const analytics = useAnalytics();
    const appDataStore = useMemo(
        () => {
            return new AppDataStore(
                backofficeClientStore,
                snackbarStore,
                confirmationPopupMessageStore,
                userStore
            );
        },
        [backofficeClientStore, snackbarStore, confirmationPopupMessageStore, userStore]
    );
    const createAppKeyStore = useMemo(
        () => new CreateAppKeyStore(appDataStore, backofficeClientStore, confirmationPopupMessageStore),
        [appDataStore, backofficeClientStore, confirmationPopupMessageStore]);

    useEffect(() => {
        if (isLoggedIn) {
            appDataStore.initialize();
        }
    }, [isLoggedIn, appDataStore]);

    useEffect(() => {
        analytics.dispatcher.withExtra('Page', 'PreLoad').dispatch('View');
    }, [analytics]);

    return (
        <SnackbarStoreProvider value={snackbarStore}>
            <ConfirmationPopupMessageStoreProvider value={confirmationPopupMessageStore}>
                <AppDataStoreProvider value={appDataStore}>
                    <CreateAppKeyStoreProvider value={createAppKeyStore}>
                        <MainPageView />
                    </CreateAppKeyStoreProvider>
                </AppDataStoreProvider>
            </ConfirmationPopupMessageStoreProvider>
        </SnackbarStoreProvider>
    );
};

export default observer(MainPage);
