import React, { FunctionComponent } from "react";
import { styled, css } from "../../../../common-styles";
import { requireResource } from "../../../../utils";
import { StyledInput } from '../../base/Input';
const arrowIcon = requireResource("arrow-down.png");

const Container = styled.div`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 4px;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 16px 0 0;
  background-color: #fff;
`;

const Text = styled.span`
  flex: 1;
  font-size: 1.6rem;
  font-family: "Apercu Mono Pro";
  padding-right: 16px;
  padding-left: 16px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ArrowIconContainer = styled.div<{ shouldFlip: boolean }>`
  display: flex;
  transform: rotate(0deg);
  transform-origin: center;
  margin-left: 14px;

  ${({ shouldFlip }) =>
    shouldFlip &&
    css`
      transform: rotate(180deg);
    `}
`;

const SearchInput = styled(StyledInput)`
  &:focus {
    outline: 1px dashed ${({ theme }) => theme.colors.purple};
    outline-offset: -5px;
  }
`;

type SelectBoxProps = {
  searchTerm?: string;
  onSearchTermChange?: (x: string) => void;
  currentValue?: string;
  isDropdownOpen: boolean;
  onClick: VoidFunction;
  placeholder?: string;
  className?: string;
};

const SelectBox: FunctionComponent<SelectBoxProps> = ({
  searchTerm,
  onSearchTermChange,
  currentValue,
  isDropdownOpen,
  onClick,
  placeholder = "Select...",
  className
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchTermChange?.(event.target.value);
  };

  const onClickSearchBox = () => {
    onSearchTermChange?.('');
    onClick();
  };

  return (
    <Container onClick={onClickSearchBox} data-cy="select-box" className={className}>
      {isDropdownOpen ? (
        <SearchInput
          autoFocus
          value={searchTerm}
          onChange={handleChange}
        />
      ) : (
        <Text>{currentValue || placeholder}</Text>
      )}
      <ArrowIconContainer shouldFlip={isDropdownOpen}>
        <img src={arrowIcon} alt="arrow" />
      </ArrowIconContainer>
    </Container>
  );
};

export default SelectBox;
