import { useMemo } from 'react';

const useMarkedText = (originalText: string, matchTo: string, matchStartOfText: boolean = false): string => {
    const matchRegEx = useMemo(() => {
        if (matchStartOfText) {
            return new RegExp(`^${matchTo}`, "gi");
        }

        return new RegExp(matchTo, "gi");
    }, [matchTo, matchStartOfText]);

    const markedText = useMemo(() => {
        const text =
            originalText
                .replace(matchRegEx, (match) => match ? `<mark>${match}</mark>` : '')
                .replace(/\s/g, '&nbsp;');

        return text;
    }, [originalText, matchRegEx]);

    return markedText;
}

export default useMarkedText;