import React, { useRef, useMemo, useState, useCallback } from 'react';
import { styled } from '../../../common-styles';
import AppKeyDetails from './AppKeyDetails';
import Scroller, { OverlayColors, ScrollerRefHandler } from '../Scroller';
import AddAnotherAppKey from './AddAnotherAppKey';
import AddedAppKeys from './AddedAppKeys';
import { observer } from 'mobx-react-lite';
import useGroupManagementStore from '../GroupManagementStore/useGroupManagementStore';
import NoAppKeys from './NoAppKeys';
import AppKeyBlock from './AppKeyBlock';
import AppKeysDropdown from './AppKeysDropdown';

const Container = styled.div`
    width: 420px;
    margin-left: 50px;
`;

const AppKeysHeader = styled.div`
    font-weight: bold;
    margin-bottom: 20px;
`;

const AppKeyListContainer = styled.div`
    border-radius: 4px;
    background-color: rgba(51, 51, 51, 0.04);
`;

const AppKeysSection: React.FunctionComponent = () => {
    const { groupAppKeys, availableAppKeys, formData: { addedAppKeys } } = useGroupManagementStore();
    const scrollerRef = useRef<ScrollerRefHandler>(null);
    const [shouldShowDropdown, setShouldShowDropdown] = useState(false);
    const isAddingAppKeyDisabled = useMemo(() => shouldShowDropdown || availableAppKeys.length === 0, [availableAppKeys, shouldShowDropdown]);
    const appKeysDisplayed = useMemo(() =>
        groupAppKeys.length > 0 || addedAppKeys.value.length > 0, [groupAppKeys, addedAppKeys.value]);

    const handleAddAppKeyClick = useCallback(() => {
        if (shouldShowDropdown || isAddingAppKeyDisabled) return;

        setShouldShowDropdown(true);
        scrollerRef.current?.scrollToBottom();
    }, [setShouldShowDropdown, shouldShowDropdown, isAddingAppKeyDisabled, scrollerRef]);

    const handleAppKeySelectComplete = useCallback(() => {
        setShouldShowDropdown(false);
        scrollerRef.current?.scrollToBottom();
    }, [setShouldShowDropdown, scrollerRef]);

    return (
        <Container>
            <AppKeysHeader>AppKeys</AppKeysHeader>

            {!appKeysDisplayed && !shouldShowDropdown && <NoAppKeys />}
            <Scroller
                ref={scrollerRef}
                overlayColor={OverlayColors.GrayOverlay}
                overlay={{ height: '77px', width: '97%' }}
            >
                <AppKeyListContainer>
                    <div>
                        {groupAppKeys.map(({ id, appKey, displayName, environment, hostType }) => (
                            <AppKeyDetails
                                appKey={{
                                    id,
                                    appKey,
                                    environment,
                                    hostType,
                                    friendlyName: displayName
                                }}
                                key={id}
                            />
                        ))}
                    </div>
                    <AddedAppKeys />
                </AppKeyListContainer>
            </Scroller>
            {shouldShowDropdown && (
                <AppKeyBlock showTopBorder={appKeysDisplayed}>
                    <AppKeysDropdown onComplete={handleAppKeySelectComplete} />
                </AppKeyBlock>
            )}
            <AddAnotherAppKey isDisabled={isAddingAppKeyDisabled} onClick={handleAddAppKeyClick} />
        </Container >
    );
}

export default observer(AppKeysSection);
