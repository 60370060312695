import React, { FunctionComponent, useMemo } from 'react';
import { styled } from '../common-styles';

const TOP_PADDING_IN_PX = 20;
const APP_KEY_ITEM_HEIGHT_IN_PX = 80;

const StyledFloatingLine = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, ${TOP_PADDING_IN_PX}px, 0);
    transition: transform .4s;

    width: 4px;
    height: 42px;
    background-color: ${({ theme }) => theme.colors.purple};
    border-radius: 4px;
`;

type FloatingLineProps = {
    currentIndex: number;
}

const calculateNextVerticalPositionInPixels = (index: number): number => {
    if (!index) return TOP_PADDING_IN_PX;

    return (APP_KEY_ITEM_HEIGHT_IN_PX * index) + TOP_PADDING_IN_PX;
}

const FloatingLine: FunctionComponent<FloatingLineProps> = ({ currentIndex }) => {
    const verticalPosition = useMemo(() => calculateNextVerticalPositionInPixels(currentIndex), [currentIndex]);

    return (
        <StyledFloatingLine
            style={{
                transform: `translate3d(0, ${verticalPosition}px, 0)`
            }}
        />
    );
}

export default FloatingLine;