import React, {
    FunctionComponent,
    useState,
    useCallback,
    useMemo
} from 'react';
import { styled, css } from '../../../common-styles';
import { AddedMember } from '../../../types';
import { requireResource, delay } from '../../../utils';
import GroupFilterMarkedText from '../GroupFilterMarkedText';
import useTextMapping from '../../../hooks/useTextMapping';
const removeUser = requireResource('remove-button.svg');
import { observer } from 'mobx-react-lite';

interface Props {
    addedMember: AddedMember;
    getEmailForUserId:(userId: number)=> string;
    onDelete: (addedMember: AddedMember)=>void;
}

const Container = styled.div`
    padding: 0 16px;
    height: 50px;
    align-items: center;
    position: relative;
    display: flex;
    &:hover {
        background-color: rgba(51, 51, 51, 0.04);
    }
`;

const ContentContainer = styled.div<{ isDeleted: boolean }>`
    display: flex;
    align-items: center;
    flex: 1;
    opacity: ${props => (props.isDeleted ? 0.5 : 1)};
    transition: opacity 300ms;
    height: 20px;
`;

const DeleteUserButton = styled.button`
    height: 24px;
    width: 24px;
    display: flex;
    cursor: pointer;
`;

const RoleText = styled.span`
    padding-left: 16px;
    font-family: 'Apercu Mono Pro';
    color: ${({ theme }) => theme.colors.gray};
    font-size: 1.4rem;
`;

const FirstColumnWrapper = styled.div`
    margin-right: 16px;
    width: 24px;
    display: flex;
`;

const SecondColumnWrapper = styled.div`
    margin-right: 16px;
    flex: 1;
`;

const ThirdColumnWrapper = styled.div<{ isDeleted: boolean }>`
   width: 150px;
`;

const MarkDeleted = styled.div<{ isDeleted: boolean }>`
    position: absolute;
    top: 0;
    left: 39px;
    bottom: 0;
    margin: auto 0;
    background-color: ${({ theme }) => theme.colors.purple};
    height: 1px;
    width: 0;
    transition: width 200ms;

    ${({ isDeleted }) => isDeleted && css`
        width: calc(100% - 127px);
    `}
`;


const AddedGroupMember: FunctionComponent<Props> = ({ addedMember, getEmailForUserId, onDelete}) => {
    const [isNewUserDeleted, setIsNewUserDeleted] = useState(false);

    const groupRoleText = useTextMapping('userGroupRole', addedMember.groupRole);
    
    const addedMemberEmail = useMemo(() => {
        return (addedMember.type === 'new') ? addedMember.email! : getEmailForUserId(addedMember.id!);
    },[addedMember, getEmailForUserId])

    const deleteClicked = useCallback(async () => {
        setIsNewUserDeleted(true);
        await delay(400);
        setIsNewUserDeleted(false);
        onDelete(addedMember);
    },[addedMember, setIsNewUserDeleted, onDelete]);
  
    return (
        <Container>
            <MarkDeleted isDeleted={isNewUserDeleted} />
            <ContentContainer isDeleted={isNewUserDeleted}>
                <FirstColumnWrapper>
                    {!isNewUserDeleted && (
                        <DeleteUserButton onClick={() => deleteClicked()}>
                            <img src={removeUser} />
                        </DeleteUserButton>
                    )}
                </FirstColumnWrapper>
                <SecondColumnWrapper data-cy="added-group-member">
                    <GroupFilterMarkedText
                        text={addedMemberEmail}
                        matchFromStart={true}
                    />
                </SecondColumnWrapper>
                <ThirdColumnWrapper isDeleted={isNewUserDeleted}>
                    <RoleText>{groupRoleText}</RoleText>
                </ThirdColumnWrapper>
            </ContentContainer>
        </Container>
    );
};

export default observer(AddedGroupMember);
