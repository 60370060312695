import React, { FunctionComponent } from 'react';
import { SvgIconProps } from '.';

const MobileIndicatorIcon: FunctionComponent<SvgIconProps> = ({ fillColor }) => (
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="7" height="12" rx="1.23954" stroke={fillColor} strokeWidth="1.23954" />
        <path d="M4 11H5" stroke={fillColor} strokeLinecap="round" />
    </svg>
)

export default MobileIndicatorIcon;