export enum LogLevel {
    Debug = 'debug',
    Info = 'info',
    Warning = 'warning',
    Error = 'error'
}

export type Logger = {
    [P in LogLevel]: (message: string, extraData?: object) => Promise<void>
};

export type LoggingApiRequestBody = {
    Data: object;
    Timestamp: string;
    ApplicationName: string;
    Content: string;
}