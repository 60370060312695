import React, { useCallback, useState, useEffect } from 'react';
import { styled } from '../../common-styles';
import useUserManagementStore from '../../hooks/useUserManagementStore';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'use-debounce';

const StyledSearchBar = styled.input`
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    
    width: 100%;
    height: 36px;
    border-radius: 4px;
    font-family: 'Apercu Mono Pro';
    font-size: 1.6rem;
    padding: 9px 20px;
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
`;

const AdminSearchBar: React.FunctionComponent = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm] = useDebounce(searchTerm, 100);
    const { setGroupsSearchTerm } = useUserManagementStore();

    const handleSearchTermChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }, [setSearchTerm]);

    useEffect(() => {
        setGroupsSearchTerm(debouncedSearchTerm.trim());
    }, [setGroupsSearchTerm, debouncedSearchTerm]);

    return (
        <StyledSearchBar
            data-cy='admin-search-bar'
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchTermChange}
        />
    );
};

export default observer(AdminSearchBar);
