import { LogLevel, Logger, LoggingApiRequestBody } from "./types";
import axios from 'axios';
import { LOGGING_API_URL } from '../../consts';

const BASE_URL = `${LOGGING_API_URL}/api/v1/log`;
const APPLICATION_NAME = 'AeSdkBackoffice';
const REQUEST_HEADERS = {
    'Content-Type': 'application/json'
}

const postToLoggingApi = async (level: LogLevel, body: LoggingApiRequestBody): Promise<void> => {
    await axios.post(`${BASE_URL}/${level}`, body, {
        headers: REQUEST_HEADERS
    })
}

const log = async (level: LogLevel, message: string, extraData?: object): Promise<void> => {
    const requestBody: LoggingApiRequestBody = {
        ApplicationName: APPLICATION_NAME,
        Timestamp: new Date().toISOString(),
        Content: message,
        Data: {
            ...extraData
        }
    }

    if (globals.BO_LOG_TO_CONSOLE_ONLY) {
        console.log(`[logger.${level}]\t`, requestBody);
        return;
    }

    try {
        await postToLoggingApi(level, requestBody);
    } catch (error) {
        console.warn(APPLICATION_NAME, 'error sending log', error)
    }
}

const logger: Logger = {
    info: (message: string, extraData?: object) => log(LogLevel.Info, message, extraData),
    warning: (message: string, extraData?: object) => log(LogLevel.Warning, message, extraData),
    error: (message: string, extraData?: object) => log(LogLevel.Error, message, extraData),
    debug: (message: string, extraData?: object) => log(LogLevel.Debug, message, extraData)
}

export default logger;