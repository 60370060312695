import { CognitoUser } from 'amazon-cognito-identity-js';
import { BackofficeIdentity } from '../BackofficeIdentity';

const getCognitoUserAttributes = async (cognitoUser: CognitoUser, attributeNames: string[]): Promise<object> => {
    return new Promise((resolve, reject) => {
        cognitoUser.getUserAttributes((error, attributes) => {
            if (error) {
                reject(error);
                return;
            }

            let result = {}
            attributeNames.forEach(attributeName => {
                const attribute = attributes?.find(attribute => attribute.getName() === attributeName)
                Object.assign(result, attribute ? { [attribute.getName()]: attribute.getValue() } : { [attributeName]: '' })
            })

            resolve(result);
        })
    })
}

const toIdentityUser = async (cognitoUser: CognitoUser): Promise<BackofficeIdentity> => {
    const userAttributes = await getCognitoUserAttributes(cognitoUser, ["name", "family_name", "email"]);

    return {
        token: cognitoUser.getSignInUserSession()?.getIdToken().getJwtToken(),
        firstName: userAttributes["name"] || '',
        lastName: userAttributes["family_name"] || '',
        email: userAttributes["email"] || '',
        id: cognitoUser.getUsername()
    }
};

export const cognitoIdentity = {
    "toIdentityUser": toIdentityUser
}
