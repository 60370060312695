import React, { FunctionComponent, useState, useCallback } from 'react';
import { styled } from '../../../common-styles';
import { requireResource } from '../../../utils';
import AppKeysDropdown from './AppKeysDropdown';
const plusSign = requireResource('plus.png');

const ItemContainer = styled.div`
    display: flex;
    align-items: center;

    height: 80px;
    background-color: rgba(51, 51, 51, 0.04);
    color: rgba(51, 63, 72, 0.3);
    padding: 0 20px;
    border-radius: 4px;
`;

const ContentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 12px;
`;

const NoAppKeys: FunctionComponent = () => (
    <ItemContainer>
        <ContentContainer>
            <div>
                <span>No AppKeys connected to this group yet</span>
            </div>
        </ContentContainer>
    </ItemContainer>
);

export default NoAppKeys;