import { styled, css } from '../../common-styles';

const grayOutStyle = css`
    opacity: .4;
    filter: grayscale(100%);
`;

export const GridWrapper = styled.div<{ fromStart?: boolean; fromRelativeStart?: boolean; noSpacing?: boolean; grayOut?: boolean; horizontalCenter?: boolean; }>`
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    ${props => props.fromStart && css`
        align-items: start;
    `}

    ${props => props.fromRelativeStart && css`
        align-items: start;
        padding-top: 8px;
    `}

    ${props => props.noSpacing && css`
        margin-bottom: 0;
    `}

    ${props => props.grayOut && grayOutStyle}

    ${props => props.horizontalCenter && css`
        justify-content: center;
    `}

`;

export const GridColumnOne = styled(GridWrapper)`
    grid-column-start: 1;
    grid-column-end: 2;
`;

export const GridColumnTwo = styled(GridWrapper)`
    grid-column-start: 2;
    grid-column-end: 3;
`;

export const GridColumnThree = styled(GridWrapper)`
    grid-column-start: 3;
    grid-column-end: 3;
`;

export const GridColumn = styled(GridWrapper) <{ start: number; end: number }>`
  grid-column-start: ${props => props.start};
  grid-column-end: ${props => props.end};
`;

export const PropertyGrid = styled.div`
    box-sizing: border-box;
    max-width: 78rem;
    display: grid;
    grid-template-columns: 23% 25%;
    grid-template-rows: auto;
    min-height: 0;
    min-width: 0;
`;

export const AppKeyValue = styled.span`
  margin-left: 0.625rem;
`;

export const CopyToClipboardIcon = styled.img`
  cursor: pointer;
`;

export const AppKeyBox = styled.div`
  border: 1px solid #a6aaaf;
  background-color: ${({ theme }) => theme.colors.lightgrayEditArea}; 
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const PropertyTitle = styled.h3`
    font-size: 1.6rem;
    font-weight: 700;
`;

export const PropertySubtitle = styled.h4<{ grayOut?: boolean; }>`
    font-size: 1.6rem;
    ${props => props.grayOut && grayOutStyle}
`;

export const ValueText = styled(PropertySubtitle)`
      font-family: 'Apercu Mono Pro';
`

export const TypeValueText = styled.span`
    margin-left: 16px;
    font-weight: normal;
`;