import React, { FunctionComponent } from 'react';
import BreakPoints from './media-queries';
import assets from './assets';
import * as variables from './variables';
import { ThemeProps, Theme } from '../theme';
import * as styledComponents from 'styled-components';
import { ThemedStyledComponentsModule, ThemeProvider } from 'styled-components';
const { default: styled, css, createGlobalStyle, keyframes, withTheme } = styledComponents as ThemedStyledComponentsModule<Theme>;

const AppThemeProvider: FunctionComponent<ThemeProps> = ({ theme, children }) => (
    <ThemeProvider theme={theme}>
        <>{children}</>
    </ThemeProvider>
);

export {
    styled,
    css,
    createGlobalStyle,
    keyframes,
    withTheme,
    AppThemeProvider as ThemeProvider,
    BreakPoints,
    variables,
    assets,
};
