import React, { FunctionComponent, useMemo } from 'react';
import { PropertyTitle, GridColumn, GridColumnOne, GridColumnTwo, GridColumnThree, PropertyGrid } from './common-styles';
import useAppDataStore from '../../hooks/useAppDataStore';

import { Checkbox, TextInput, RadioGroup } from '../AppInputs';
import { observer } from 'mobx-react-lite';
import { styled } from '../../common-styles';
import UnderConstruction from '../UnderConstruction';
import { BlankOverlay } from '../BasicElements';

const DISABLE_SECTION = true;

const LeftPaddedContainer = styled.div`
    padding-left: 37px;
`;

const SectionContentContainer = styled(PropertyGrid)`
    grid-column-start: 1;
    grid-column-end: 4;
    position: relative;
`;

const FeaturesSection: FunctionComponent = () => {
    const { appKeyDetails } = useAppDataStore();

    if (!appKeyDetails) return null;

    const { welcomeAnimationEnabled, welcomeAnimationBubbleText, isFrequentQuestionsEnabled, isSmartQuestionsEnabled, isVoipEnabled } = appKeyDetails;

    const selectedQuestionExperience = useMemo(() =>
        isSmartQuestionsEnabled.value ? 'smart_suggestions' :
            isFrequentQuestionsEnabled.value ? 'frequent' : 'none',
        [isSmartQuestionsEnabled.value, isFrequentQuestionsEnabled.value])

    return (
        <>
            <SectionContentContainer>
                {DISABLE_SECTION && <BlankOverlay />}
                <GridColumnOne grayOut={DISABLE_SECTION}>
                    <PropertyTitle>Features</PropertyTitle>
                </GridColumnOne>
                <GridColumnTwo grayOut={DISABLE_SECTION}>
                    <Checkbox checked={welcomeAnimationEnabled.value} text="Welcome Animation" />
                </GridColumnTwo>
                <GridColumnTwo grayOut={DISABLE_SECTION}>
                    <LeftPaddedContainer>Text on bubble</LeftPaddedContainer>
                </GridColumnTwo>
                <GridColumnThree grayOut={DISABLE_SECTION}>
                    <TextInput value={welcomeAnimationBubbleText.value} />
                </GridColumnThree>
                <GridColumnTwo />
                <GridColumnTwo grayOut={DISABLE_SECTION}>
                    <Checkbox checked={isFrequentQuestionsEnabled.value || isSmartQuestionsEnabled.value} text="Suggested Questions" />
                </GridColumnTwo>
                <GridColumn start={2} end={4} grayOut={DISABLE_SECTION}>
                    <LeftPaddedContainer>
                        <RadioGroup
                            selectedValue={selectedQuestionExperience}
                            options={[
                                {
                                    value: 'frequent_questions',
                                    displayName: 'Frequent Questions'
                                },
                                {
                                    value: 'smart_suggestions',
                                    displayName: 'Model-based smart questions suggestions'
                                }
                            ]} />
                    </LeftPaddedContainer>
                </GridColumn>
                <GridColumnTwo grayOut={DISABLE_SECTION}>
                    <Checkbox checked={isVoipEnabled.value} text="Dialer" />
                </GridColumnTwo>
            </SectionContentContainer>
            <GridColumn start={2} end={4}>
                <UnderConstruction />
            </GridColumn>
        </>
    )
}

export default observer(FeaturesSection);