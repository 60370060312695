import React, { FunctionComponent } from 'react';
import { InputProps } from '../types';
import Input from '../base/Input';

type MultilineInputProps = Omit<InputProps, 'disabled'>;

const MultilineInput: FunctionComponent<MultilineInputProps> = (props) => (
    <Input {...props} multiline={true} />
)

export default MultilineInput;