import React, { FunctionComponent, useCallback, useState } from 'react';
import { styled, css, keyframes } from '../../../../common-styles';
import { requireResource } from '../../../../utils';
const resetIcon = requireResource('reset.svg');

const spinOutAnimation = keyframes`
    0% {
        transform: translate3d(0, 0, 0) scale(1) rotate(0deg);
    }

    80%, 100% {
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0) scale(0.1) rotate(420deg);
    }
`;

const fadeInAndOut = keyframes`
    0% {
        opacity: 0;
    }
    
    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
`;

const pathAnimation = keyframes`
    0% {
        stroke-dasharray: 11;
        stroke-dashoffset: 11;
    }

    100% {
        stroke-dashoffset: 22;
    }
`;

const absoluteCenterStyle = css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
`;

const Container = styled.div<{ fadeOut?: boolean }>`
    position: relative;
    width: 14px;
    height: 14px;
    transition: opacity 4s, visibility 4s;

    ${props => props.fadeOut && css`
        opacity: 0;
        visibility: hidden;
    `}
`;

const ResetButtonContainer = styled.div`
    will-change: transform;
    animation-name: ${spinOutAnimation};
    animation-fill-mode: forwards;
    animation-duration: 1s;

    ${absoluteCenterStyle};
`;

const DotContainer = styled.div`
    will-change: transform;
    animation-name: ${fadeInAndOut};
    animation-duration: .4s;
    animation-delay: .4s;

    opacity: 0;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.purple};

    ${absoluteCenterStyle};
`;

const StyledSvg = styled.svg`
    transform: translate3d(1px, -4px, 0);
    
    ${absoluteCenterStyle}

    .small-line, .long-line {
        animation-name: ${pathAnimation};
        animation-fill-mode: forwards;
        animation-duration: .4s;
        animation-delay: .4s;
        
        stroke-dasharray: 11;
        stroke-dashoffset: 11;
    }
`;

const ResetButtonSaveAnimation: FunctionComponent = () => {
    const [shouldFadeOut, setShouldFadeOut] = useState(false);
    const onSvgAnimationEnded = useCallback(() => {
        setShouldFadeOut(true);
    }, [setShouldFadeOut])

    return (
        <Container fadeOut={shouldFadeOut}>
            <ResetButtonContainer>
                <img src={resetIcon} draggable={false} />
            </ResetButtonContainer>
            <DotContainer />
            <StyledSvg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg" onAnimationEnd={onSvgAnimationEnded}>
                <path className="small-line" d="M1 6L4 10" stroke="#8223D2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path className="long-line" d="M11 1L4 10" stroke="#8223D2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </StyledSvg>
        </Container>
    )
}

export default ResetButtonSaveAnimation;