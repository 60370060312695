import React, { FunctionComponent, useCallback, useState, useRef, useMemo, useEffect, Dispatch, SetStateAction } from 'react';
import { SelectItemProps } from './SelectItem';
import SelectBox from './SelectBox';
import { styled } from '../../../../common-styles';
import SelectList from './SelectList';
import useClickOutsideElement from '../../../../hooks/useClickOutsideElement';
import CustomSelection from './CustomSelection';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SelectListContainer = styled.div`
    position: absolute;
    top: 40px;
    left: 0;
    min-width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
    border-radius: 4px;
    background-color: #fff;
    z-index: 1;
    box-shadow: 0px 2px 24px rgba(51, 63, 72, 0.08);
`;

type SelectProps = {
    items: SelectItemProps[];
    value: any;
    onChange?: (value: any, isCustomValue?: boolean) => void;
    placeholder?: string;
    customValueSettings?: {
        addText: string;
        submitText: string;
    }
    customValueOn?: boolean;
    setIsDropDownOpen?: (dropDownOpen: boolean) => void;
}

const Select: FunctionComponent<SelectProps> = ({ items, value, onChange, placeholder, customValueOn, customValueSettings, setIsDropDownOpen: setIsOpen}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        setIsOpen && setIsOpen(isDropdownOpen);
    },[isDropdownOpen]);
  
    const filteredItems = useMemo(() => {     
        return (searchTerm && searchTerm.length > 0) ? items.filter(item => {
            let name = item.hasOwnProperty('displayName') ? item.displayName : item.value;
            return name.toLowerCase().includes(searchTerm.toLowerCase()) 
        }) : items;      
    },[searchTerm, items]);

    const currentItem = useMemo(() => items.find(item => item.value === value), [items, value]);

    const handleItemClick = useCallback((value: string) => {
        onChange?.(value);
        setIsDropdownOpen(false);
    }, [onChange, setIsDropdownOpen]);

    const handleCustomValueSelection = useCallback((value: string) => {
        onChange?.(value, true);
        setIsDropdownOpen(false);
    }, [onChange, setIsDropdownOpen]);

    const handleSelectBoxClick = useCallback(() => {
        setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen, setIsDropdownOpen]);

    const handleOutsideClick = useCallback(() => {
        setIsDropdownOpen(false);
    }, [setIsDropdownOpen]);

    const handleSearchTermChange =  useCallback((value: string) => {
        setSearchTerm(value);
    }, [setSearchTerm]);

    useClickOutsideElement(containerRef, handleOutsideClick);

    return (
        <Container ref={containerRef} data-cy='select'>
            <SelectBox
                searchTerm={searchTerm}
                onSearchTermChange={handleSearchTermChange}
                currentValue={currentItem?.displayName || value}
                isDropdownOpen={isDropdownOpen}
                onClick={handleSelectBoxClick}
                placeholder={placeholder}
            />
            {isDropdownOpen && (
                <SelectListContainer>
                    <SelectList onItemClick={handleItemClick} selectedValue={value} items={filteredItems} />
                    {customValueOn && (
                        <CustomSelection
                            customValueAddText={customValueSettings?.addText || 'Create custom value'}
                            customValueSubmitText={customValueSettings?.submitText || 'Create'}
                            onCustomValueSelection={handleCustomValueSelection}
                        />
                    )}
                </SelectListContainer>
            )}
        </Container>
    )
}

export default Select;