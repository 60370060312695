import React, { FunctionComponent } from 'react';
import { styled, css } from '../../../../common-styles';
import { requireResource } from '../../../../utils';
import ResetButtonSaveAnimation from './ResetButtonSaveAnimation';
const resetIcon = requireResource('reset.svg');

const StyledButton = styled.button<{ isHidden?: boolean }>`
    display: flex;
    cursor: pointer;
    position: relative;
    width: 13px;
    height: 14px;

    &:after {
        background-color: ${({ theme }) => theme.colors.purple};
        opacity: .1;
        width: 30px;
        height: 30px;
        border-radius:50%;
        transform: translate3d(-50%, 0, 0);

        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;
        right: 0;
        margin: auto;
    }

    &:hover {
        &:after {
            content: '';
        }
    }

    ${props => props.isHidden && css`
        visibility: hidden;
    `}
`;


type ResetButtonProps = {
    onClick?: VoidFunction;
    hide?: boolean;
    finalize?: boolean;
}

const ResetButton: FunctionComponent<ResetButtonProps> = ({ onClick, hide, finalize }) => {
    if (finalize && !hide) return <ResetButtonSaveAnimation />

    return (
        <StyledButton onClick={onClick} isHidden={hide} data-tip="Revert this change" data-for="reset-button" data-cy="revert-button">
            <img src={resetIcon} />
        </StyledButton>
    );
}

export default ResetButton;