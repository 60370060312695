import React, { useState, useEffect, useCallback, useRef } from 'react';
import { styled, css } from '../../../common-styles';
import { AddedMember } from '../../../types';
import GroupMember from './GroupMember';
import Scroller, { OverlayColors, ScrollerRefHandler } from '../Scroller';
import { requireResource } from '../../../utils';
import UsersDropdown from './UsersDropdown';
import useGroupManagementStore from '../GroupManagementStore/useGroupManagementStore';
import { observer } from 'mobx-react-lite';
import AddedGroupMember from './AddedGroupMember';
import useUserManagementStore from '../../../hooks/useUserManagementStore';
const plusSign = requireResource('plus.png');

const UsersHeader = styled.div`
  font-weight: bold;
  margin: 0 0 20px 16px;
`;

const Container = styled.div`
  width: 650px;
`;

const Separator = styled.div`
  margin-top: 12px;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #d5d6da;
`;

const AddNewUser = styled.button<{ isDisabled: boolean }>`
  height: 80px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: default;
      opacity: 0.3;
    `}
`;

const StyledPlusSign = styled.img`
  margin: 0 16px 0 20px;
`;

const UsersSection: React.FunctionComponent = () => {
  const { groupUsers, adminId, setAddMemberErrorMessage, formData: { addedMembers, removedUsers }
} = useGroupManagementStore();
  const { users } = useUserManagementStore();
  const scrollerRef = useRef<ScrollerRefHandler>(null);
  const [usersDropdownIsHidden, setUsersDropdownIsHidden] = useState<boolean>(
    true
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [isValidUser, setIsValidUser] = useState<boolean>(true);
  const [newUserEmail, setNewUserEmail] = useState<string | undefined>(
    undefined
  );

  const isUserDeleted = useCallback(
    userId => removedUsers.value.includes(userId),
    [removedUsers.value]
  );

  useEffect(() => {
    setIsDisabled(isDropDownOpen || !isValidUser);
  }, [isDropDownOpen, isValidUser]);

  const addUserToList = (user: AddedMember) => addedMembers.add(user);

  const usersDropdownOnComplete = (
    result: boolean,
    user?: AddedMember
  ) => {
    setIsValidUser(result);
    if (result && user) {
      addUserToList(user);
      setUsersDropdownIsHidden(true);
      scrollerRef.current?.scrollToBottom();
    }
  };

  const resetDropDown = () => {
    setIsValidUser(true);
    setUsersDropdownIsHidden(true);
    setAddMemberErrorMessage('');
    setNewUserEmail(undefined);
  }

  const handleAddNewUser = () => {
    setIsDropDownOpen(true);
    setUsersDropdownIsHidden(false);
  };

  const onAddedMemberDeleteClick = (addedMember: AddedMember) => {
    (addedMember.type === 'new') ?
       addedMembers.removeByProp('email', addedMember.email!) :
       addedMembers.removeByProp('id', addedMember.id!);
       resetDropDown();
  }

  const onUserStatusChanged = useCallback(
    (isDeleted, userId) => {
      isDeleted ? removedUsers.add(userId) : removedUsers.remove(userId);
      resetDropDown();
    },
    [removedUsers]
  );

    const getEmailForUserId = useCallback(
      (userId: number): string => {
        return users.find(user => user.id == userId)?.email || '';
      },
      [users]
    );

  return (
    <Container data-cy="users-section">
      <UsersHeader>Users</UsersHeader>
      <Scroller
        ref={scrollerRef}
        overlayColor={OverlayColors.WhiteOverlay}
        overlay={{ height: '55px', width: '98%' }}
      >
        {groupUsers.map((member, key) => (
          <GroupMember
            member={member}
            key={key}
            onUserStatusChanged={onUserStatusChanged}
            isDeleted={isUserDeleted(member.id)}
            isAdmin={member.id == adminId}
          />
        ))}
        {addedMembers.value.map((addedMember, key) => (
          <AddedGroupMember
            addedMember={addedMember}
            key={key}
            getEmailForUserId={getEmailForUserId}
            onDelete={onAddedMemberDeleteClick}
          />
        ))}
      </Scroller>
      <UsersDropdown
        isHidden={usersDropdownIsHidden}
        onComplete={usersDropdownOnComplete}
        setIsDropDownOpen={setIsDropDownOpen}
        groupMembers={groupUsers}
        getEmailForUserId={getEmailForUserId}
        newUserEmail={newUserEmail}
        setNewUserEmail={setNewUserEmail}
      />
      <Separator />
      <AddNewUser
        isDisabled={isDisabled}
        onClick={!isDisabled ? handleAddNewUser : () => { }}
        data-cy="add-user-to-group-button"
      >
        <StyledPlusSign src={plusSign} /> Add another user to this group
      </AddNewUser>
    </Container>
  );
};

export default observer(UsersSection);
